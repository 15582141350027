<!-- <button (click)="generatePdfInvoice()">Quototion</button> -->
<div id="quototion" class="quoto" style="display: none; background: white; padding: 20px">
    <img
      src="../../../../assets/images/invoice/final-petals-logo-2-01.jpg"
      style="width: 18%; height: 18%; margin-left: 80%"
      alt=""
    />
    <div class="mt-5">
      <div style="display: flex; justify-content: space-around">
          <div>
              <h3 style="font-style: bold;"><b>To</b></h3><br>
              <!-- <h3><b>{{ formValue?.to_address }}</b></h3> -->
              <h3><b>AUTOBONICS PVT.LTD</b></h3><br>
              <h3><b>2nd floor, 46-Venus Plaza,</b></h3><br>
              <h3><b>2381-A, Chakkaraparambu,</b></h3><br>
              <h3><b>Vennala, Errnakulam, Kakkanad,</b></h3><br>
              <h3><b>Kerala-682 028.</b></h3>
          </div>
          <div>
              <!-- <h3><b>QUOTATION: {{ formValue?.quotation }}</b></h3><br>
              <h3><b>ISSUE DATE: {{ formValue?.issue_date }}</b></h3> -->
              <h3><b>QUOTATION: 123</b></h3><br>
              <h3><b>ISSUE DATE: 123</b></h3>
            </div>
      </div><br><br>
      <div style="margin-left: 12.5%; margin-top: 5%; width: 80%;">
          <h3><b>Dear Sir,</b></h3><br>
          <p style="font-size: 28px;">Greetings From Petals Automation!</p><br>
          <p style="font-size: 28px;">Thank you for your interest in our product. We are pleased to quote as follows. We request
              you to let us know if any clarification regarding this.</p>
          <div>
              <table style="width: 95%">
                  <tr>
                    <th style="text-align: center; padding: 1%;"><h3><b>S.no</b></h3></th>
                    <th style="text-align: center; padding: 1%;"><h3><b>Product Description</b></h3></th> 
                    <th style="text-align: center; padding: 1%;"><h3><b>Quantity</b></h3></th>
                    <th style="text-align: center; padding: 1%;"><h3><b>Price</b></h3></th>
                  </tr>
                  <tr>
                    <td style="text-align: center; padding: 1%;"><h3>1.</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>Petal Mars Rover Educational Robotic Kit</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>1</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>&#8377; 5,999 +GST</h3></td>
                  </tr>
                  <tr>
                      <td style="text-align: center; padding: 1%;"><h3>2.</h3></td>
                      <td style="text-align: center; padding: 1%;"><h3>Petal IoT Educational Kit</h3></td>
                      <td style="text-align: center; padding: 1%;"><h3>1</h3></td>
                      <td style="text-align: center; padding: 1%;"><h3>&#8377; 6,499 +GST</h3></td>
                    </tr>
                    <tr>
                      <td style="text-align: center; padding: 1%;"><h3>3.</h3></td>
                      <td style="text-align: center; padding: 1%;"><h3>Petal Smart Home Educational Robotic Kit</h3></td>
                      <td style="text-align: center; padding: 1%;"><h3>1</h3></td>
                      <td style="text-align: center; padding: 1%;"><h3>&#8377; 6,999 +GST</h3></td>
                    </tr>
                    <tr>
                      <td style="text-align: center; padding: 1%;"><h3>4.</h3></td>
                      <td style="text-align: center; padding: 1%;"><h3>Petal 3 in 1 Bot Kit</h3></td>
                      <td style="text-align: center; padding: 1%;"><h3>1</h3></td>
                      <td style="text-align: center; padding: 1%;"><h3>&#8377; 7,999 +GST</h3></td>
                    </tr>
                    <tr>
                      <td style="text-align: center; padding: 1%;"><h3>5.</h3></td>
                      <td style="text-align: center; padding: 1%;"><h3>Petal Smart Home Educational Robotic Pro Kit</h3></td>
                      <td style="text-align: center; padding: 1%;"><h3>1</h3></td>
                      <td style="text-align: center; padding: 1%;"><h3>&#8377; 9,999 +GST</h3></td>
                    </tr>
                    <tr>
                      <td style="text-align: center; padding: 1%;"><h3>6.</h3></td>
                      <td style="text-align: center; padding: 1%;"><h3>Petal AI & ML Educational Robotic Kit</h3></td>
                      <td style="text-align: center; padding: 1%;"><h3>1</h3></td>
                      <td style="text-align: center; padding: 1%;"><h3>&#8377; 13,999 +GST</h3></td>
                    </tr>
                </table>
          </div><br><br>
          <div>
              <h3><b>**** Included Petal Software and Petal Curriculum, Manual [ Soft Copy].</b></h3><br>
              <h3><b>Terms and Conditions</b></h3><br>
              <ul>
                  <h3><li> This quotation is valid for 10 days from the date of issue, after which prices andavailability may change.</li></h3>
                  <h3><li> Payment for the robotic kit is required in 100% full and in advance.</li></h3>
                  <h3><li> Estimated delivery time: 20-25 working days from order confirmation and initial
                      payment receipt. Notification will be provided for any delays.
                  </li></h3>
                  <h3><li> Shipping costs are not included in the quotation and will be added to the final invoice.</li></h3>
                  <h3><li> Technical support available via email and phone during business hours (MondaytoSaturday, 9:30 AM - 4:30PM).</li></h3>
                  <h3><li> All intellectual property, including but not limited to the curriculum, training
                      materials and robotic kits, remains the exclusive property of Petals Automation.</li></h3>
                  <h3><li> All clerical errors, whether in mathematical computation or otherwise, in quotations, acknowledgement and invoices shall be subjected to correction as soon as the error isnoted.</li></h3>
                  <h3><li> By placing an order, the client acknowledges and agrees to these terms and conditions.</li></h3>
              </ul>  
          </div><br><br>
          <div>
              <h3 style="text-align: center;"> Petals Automation, LIG Colony 155, K.K. Nagar, Madurai - 625020,</h3>
          </div>
      </div>
    </div>
  </div>
<div class="modal-header">
  <h4 class="modal-title">Quotation Popup</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    style="position: absolute; left: 460px; top: 8px"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="quotationFormGroup">
    <div class="form-group">
      <label for="quotationDate">Date <span class="redColor">*</span></label>
      <input
        type="text"
        id="quotationDate"
        name="quotationDate"
        formControlName="issue_date"
        class="form-control"
        placeholder="Select a Date"
        autocomplete="off"
      />
      <!-- <div *ngIf="controls.issue_date?.invalid && (controls.issue_date?.dirty || controls.issue_date?.touched)" class="invalid-feedback">
          <div *ngIf="controls.issue_date.errors?.['required']">
            Please enter the Quotation Date
          </div>
        </div> -->
    </div>

    <br />
    <div class="form-group">
      <label for="quotation_No">Quotation</label>
      <input
        type="text"
        name="quotation_No"
        class="form-control"
        formControlName="quotation"
        placeholder="Quotation"
      />
    </div>
    <br />
    <div class="form-group">
      <label for="quotation_address"
        >Address <span class="redColor">*</span></label
      >
      <textarea
        class="form-control"
        name="quotation_address"
        id="quotation_address"
        placeholder="Address....."
        formControlName="to_address"
      ></textarea>
      <!-- <div *ngIf="controls.address?.invalid && (controls.address?.dirty || controls.address?.touched)" class="invalid-feedback">
        <div *ngIf="controls.address.errors?.['required']">
            Please enter the Address
        </div> -->
      <!-- </div> -->
    </div>
    <br />
    <div class="form-group">
      <label for="productName"
        >Product Name <span class="redColor">*</span></label
      >
      <ng-multiselect-dropdown
        [ngClass]="'multiSelect'"
        [placeholder]="'Product Name'"
        [settings]="dropdownSettings"
        [data]="dropdownList"
        id="productName"
        name="productName"
        (click)="onProductName()"
        (onSelect)="onItemSelect($event)"
        (onSelectAll)="onSelectAll($event)"
        formControlName="product"
        [class.is-invalid]="
          controls.product?.invalid &&
          (controls.product?.dirty || controls.product?.touched)
        "
      >
      </ng-multiselect-dropdown>
      <!-- <div *ngIf="controls.product?.invalid && (controls.product?.dirty || controls.product?.touched)" class="invalid-feedback">
        <div *ngIf="controls.product.errors?.['required']">
            Please select the Product
        </div>
      </div> -->
    </div>
    <br />
    <button class="btn btn-primary" (click)="onSave()">Save</button>
  </form>
</div>
