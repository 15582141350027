<!-- Container-fluid starts-->




<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Manage Order({{ totalCount }})</h5>
        </div>
        <div class="card-body">
          <div class="custom-datatable">
            <!-- <form> -->
              <!-- (input)="orderSearch()" -->
              <!-- (click)="clearSearch()" -->
            <div class="mb-3">
              <input
                type="text"
                class="filter-ngx form-control"
              
                placeholder="Search..."
                [(ngModel)]="searchText"
                autocomplete="off"
                autocomplete="off"
              />
              <div class="input-group-append1">
                <button
                  type="button"
                  class="btn-close"
                  aria-label="Close"
                  
                ></button>
              </div>
            </div>
            <div style="display: flex; justify-content: end">

              <button type="button" class="btn btn-primary mb-5" data-toggle="orderModal" data-original-title="test"
              data-target="#orderModal" (click)="orderOpen()">Add Order</button>
            </div>
            <!-- </form> -->
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col" style="text-align: center;">Order Id</th>
                  <th scope="col" style="text-align: center;">Invoice No</th>
                  <th scope="col" style="text-align: center;">Product Name</th>
                  <th scope="col" style="text-align: center;">Quantity</th>
                  <!-- <th scope="col">Payment Status</th>
                  <th scope="col">Payment Mehod</th>
                  <th scope="col">Order Status</th> -->
                  <!-- <th scope="col">Date</th> -->
                  <th scope="col" style="text-align: center;">Total</th>
                  <th scope="col" colspan="2" style="text-align: center;">Actions</th>
                  <th scope="col" style="text-align: center;">Invoice</th>
                  <!-- <th scope="col" style="text-align: center;" *ngIf="this.decodeToken.user_type === 1">Approved</th>
                  <th scope="col" style="text-align: center;" *ngIf="this.decodeToken.user_type === 1">{{ approveData == 1 ? 'Invoice' : 'Approve' }}</th> -->
                  <!-- <th scope="col" style="text-align: center;">
                    <span *ngFor="let order of orderDetails">
                    
                    </span>
                  </th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let order of orderDetails">
                  <th scope="row" style="text-align: center;">
                    #{{ order?.OrderId === null ? "-" : order?.OrderId }}
                  </th>
                  <th scope="row" style="text-align: center;">
                    {{ order?.invoice_no === null ? "-" : order?.invoice_no }}
                  </th>
                  <td style="text-align: center;">
                    <ng-container *ngFor="let product of order?.OrderDetails">
                      {{ getFormattedProductNames(product.ProductName) }}
                      <br>
                    </ng-container>
                    
                  </td>
                  <td style="text-align: center;">
                    <ng-container *ngFor="let product of order?.OrderDetails">
                      {{ product.Quantity.split(',') }}<br>
                    </ng-container>
                  </td>
                  <td style="text-align: center;">
                    <ng-container *ngFor="let product of order?.OrderDetails">
                      {{ product.TotalAmount.split(',') }}<br>
                    </ng-container>
                  </td>
                    <!-- <ng-container *ngIf="order?.OrderDetails">
                      {{ order.ProductName | json }}
                      <ng-container
                        *ngFor="let product of order.ProductName.split(',')"
                      >
                        {{ product.trim() }}<br />
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        !order?.ProductName || order.ProductName.trim() === ''
                      "
                    >
                      -
                    </ng-container> -->
                  
                  <!-- <td>
                    <ng-container *ngIf="order?.Quantity">
                      <ng-container
                        *ngFor="let product of order.Quantity.split(',')"
                      >
                        {{ product.trim() }}<br />
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="!order?.Quantity || order?.Quantity.trim() === ''"
                    >
                      -
                    </ng-container>
                  </td> -->
                  <!-- </td>
                    {{
                      order?.OrderDate === null
                        ? "-"
                        : (order?.OrderDate | date : "yyyy-MM-dd")
                    }}
                  </td> -->
                  <!-- <td>
                    {{
                      order?.totalPrice === null
                        ? "-"
                        : (order?.totalPrice.split(",")[0] | number : "1.2-2")
                    }}<br />
                    {{
                      order?.totalPrice === null
                        ? "-"
                        : (order?.totalPrice.split(",")[1] | number : "1.2-2")
                    }}
                  </td> -->
                  <td style="text-align: center;">
                    <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                    data-target="#exampleModal" (click)="open(order?.OrderId)">View</button>
                  </td>
                  <td style="text-align: center;">
                    <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                    data-target="#exampleModalEdit" (click)="openEdit(order?.OrderId)">Edit</button>
                  </td>
                  <!-- <td style="text-align: center;"  *ngIf="this.decodeToken.user_type === 1">
                    <p [ngClass]="order.approve === 1 ? 'blue' : 'red'">{{ order.approve === 1  ? 'Approved' :  'Waiting for approvel......' }} </p>
                  </td> -->
                  <!-- <td *ngIf="!order.approve && this.decodeToken.user_type === 1" style="text-align: center;">
                    <div>
                      <input type="radio" name="approvel" [(ngModel)]="order.approve" [value]="true" (click)="onApprovel(order?.OrderId)" id="approvel">
                      <p>Approved</p>
                    </div>
                  </td> -->
                  <td style="text-align: center;">
                    <div>
                      <button type="button" class="btn btn-primary"
                    (click)="openInvoice(order?.OrderId)">Invoice Generate</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style="text-align: center" *ngIf="orderDetails?.length === 0">
              <b>No Data Found!....</b>
            </div>
            <div class="d-flex justify-content-between p-2">
              <!-- <ngb-pagination
                [collectionSize]="totalCount"
                [(page)]="currentPage"
                [pageSize]="limit"
                (pageChange)="getOrderData()"
              >
              </ngb-pagination> -->

              <!-- <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
                <option [value]="10" [selected] ="true" [ngValue]="2">2 items per page</option>
                <option [ngValue]="4">4 items per page</option>
                <option [ngValue]="6">6 items per page</option>
              </select> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-invoiceOrder #invoiceOrderComponent1></app-invoiceOrder>
