import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { jwtDecode } from 'jwt-decode';
import { catchError, Observable } from 'rxjs';

export class authInterceptorInterceptor implements  HttpInterceptor {
  private decodeToken: any;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("Token Ecommerce") || localStorage.getItem("Token Ecommerce Not 1");
    if(token) {
      this.decodeToken = jwtDecode(token);
      console.log("decodeToken ", this.decodeToken);
      if(this.decodeToken.user_type === 1) {
        localStorage.removeItem("Token Ecommerce Not 1");
        localStorage.setItem("Token Ecommerce", token);
        const authReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
        return next.handle(authReq);
      } else {
        localStorage.setItem("Token Ecommerce Not 1", token);
        localStorage.removeItem("Token Ecommerce");
        const authReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
        return next.handle(authReq);
      }
      
    }
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
          errorMessage = `Error: ${error.error.message}`;
          console.log('client-side error ', errorMessage);
        } else {
          errorMessage = `Error Status: ${error.status} Message ${error.message}`;
          console.log("Server-side error ", errorMessage);
          if(error.status === 401) {
            if(this.decodeToken.user_type === 1) {
              localStorage.removeItem("Token Ecommerce Not 1");
            } else {
              localStorage.removeItem("Token Ecommerce");
            }
          }
          if(error.status === 404) {
            console.log("Server not responding -> Error Code: 404");
          }
          if(error.status === 500) {
            console.log("Internal Server Error -> Error Code: 500");
          }
        }
        return next.handle(req);
      })
    )
  }
}
