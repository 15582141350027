import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActivtyRoutingModule } from "./activity-routing.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ActivitylogComponent } from "./activitylog.component";
import { SharedModule } from "src/app/shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
    declarations: [ActivitylogComponent],
    imports: [
      CommonModule,
      ActivtyRoutingModule,
      NgbModule,
      FormsModule,
      ReactiveFormsModule
    ],
  })
  export class ActivityModule { }