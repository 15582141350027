import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InvoiceRoutingModule } from './invoice-routing.module';
import { InvoiceComponent } from './invoice.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { QuototionPopupComponent } from './quototionPopup/quototionPopup.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { EditQuototionPopupComponent } from './editQuototionPopup/editQuototionPopup.component';

@NgModule({
  declarations: [InvoiceComponent, QuototionPopupComponent, EditQuototionPopupComponent],
  imports: [
    CommonModule,
    InvoiceRoutingModule,
    SharedModule,
    NgbModule,
    NgbModalModule,
    ReactiveFormsModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports: [InvoiceComponent]
})
export class InvoiceModule { }
