import { Component } from '@angular/core';

@Component({
  selector: 'app-create-purchase',
  templateUrl: './create-purchase.component.html',
  styleUrl: './create-purchase.component.scss'
})
export class CreatePurchaseComponent {

}
