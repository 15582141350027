import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ProductService } from 'src/app/shared/service/product.service';
import Swal from "sweetalert2";
@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {
  public productForm!: FormGroup;
  public Editor = ClassicEditor;
  public counter: number = 1;
  uploadImage: any; 
  selectedFiles: File[] = [];
  blob: any;
  fileNames: any;
  imgLength: any;
  firstImage: boolean = false;
  secondImage: boolean = false;
  oneImageBlob: any = [];
  readUrlFirstBlob: any;
  firstFileName: any;
  thridImage: boolean = false;
  fourthImage: boolean = false;
  fivethImage: boolean = false;
  fileNamesTwo: any;
  twoBlob: any;
  file: any;
  fileNamesThree: any;
  fileNamesFour: any;
  fileNamesFive: any;
  threeBlob: any;
  fourBlob: any;
  fiveBlob: any;
  threeFile: any;
  fourFile: any;
  fiveFile: any;
  fileNamesOne: any;
  oneBlob: any;
  oneFile: any;
  filesArray: File[] = [];
  public url = [{
    img: "assets/images/user.png",
  },
  {
    img: "assets/images/user.png",
  },
  {
    img: "assets/images/user.png",
  },
  {
    img: "assets/images/user.png",
  },
  {
    img: "assets/images/user.png",
  }
  ]


  constructor(private fb: FormBuilder, private productservice: ProductService) {
    this.productForm = this.fb.group({
      title: ['', [Validators.required]],
      type: ['', [Validators.required]],
      link: [''],
      discount: ['', [Validators.required]],
      productName: ['', [Validators.required]],
      price: ['', [Validators.required]],
      code: ['', [Validators.required]],
      stock: ['', [Validators.required]],
      description: ['', [Validators.required]],
      categoryId: ['', [Validators.required]],
      subcategoryId: ['', [Validators.required]],
      productTypeId: ['', [Validators.required]],
      length: [''],
      height: [''],
      width: [''],
      weight: ['', [Validators.required]],
      image: [''],
      image1: [''],
      image2: [''],
      image3: [''],
      image4: ['']
      // size: ['', Validators.required],
    })
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  ngOnInit() {

  }

  readUrlTwo(event: any) {
    if (event && event.target.files && event.target.files.length > 0) {
      this.fileNamesTwo = [];
      this.firstImage = false;
      this.secondImage = true;
      this.thridImage = false;
      this.fourthImage = false;
      this.fivethImage = false;
      const files: File[] = Array.from(event.target.files);
      for (let i = 0; i < files.length; i++) {
        this.file = files[i];
        this.oneImageBlob?.push(this.file.name);
        console.log(this.oneImageBlob);
        const reader = new FileReader();
        reader.onload = (event) => {
          const arrayBuffer = reader.result as ArrayBuffer;
          if (arrayBuffer) {
            console.log(arrayBuffer);
            this.twoBlob = new Blob([arrayBuffer], { type: this.file.type });
            console.log(this.twoBlob);
            const url = URL.createObjectURL(this.twoBlob);
            this.fileNamesTwo.push(url);
          }
        };
        reader.readAsArrayBuffer(this.file);
      }
    }
  }
  readUrlThree(event: any) {
    if (event && event.target.files && event.target.files.length > 0) {
      this.fileNamesThree = [];
      this.firstImage = false;
      this.secondImage = false;
      this.thridImage = true;
      const files: File[] = Array.from(event.target.files);
      for (let i = 0; i < files.length; i++) {
        this.threeFile = files[i];
        const reader = new FileReader();
        reader.onload = (event) => {
          const arrayBuffer = reader.result as ArrayBuffer;
          if (arrayBuffer) {
            console.log(arrayBuffer);
            this.threeBlob = new Blob([arrayBuffer], { type: this.threeFile.type });
            const url = URL.createObjectURL(this.threeBlob);
            this.fileNamesThree.push(url);
          }
        };
        reader.readAsArrayBuffer(this.threeFile);
      }
    }
  }
  readUrlFour(event: any) {
    if (event && event.target.files && event.target.files.length > 0) {
      this.fileNamesFour = [];
      this.firstImage = false;
      this.secondImage = false;
      this.thridImage = false;
      this.fourthImage = true;
      const files: File[] = Array.from(event.target.files);
      for (let i = 0; i < files.length; i++) {
        this.fourFile = files[i];
        const reader = new FileReader();
        reader.onload = (event) => {
          const arrayBuffer = reader.result as ArrayBuffer;
          if (arrayBuffer) {
            console.log(arrayBuffer);
            this.fourBlob = new Blob([arrayBuffer], { type: this.fourFile.type });
            const url = URL.createObjectURL(this.fourBlob);
            this.fileNamesFour.push(url);
          }
        };
        reader.readAsArrayBuffer(this.fourFile);
      }
    }
  }
  readUrlFive(event: any) {
    if (event && event.target.files && event.target.files.length > 0) {
      this.fileNamesFive = [];
      this.firstImage = false;
      this.secondImage = false;
      this.thridImage = false;
      this.fourthImage = false;
      this.fivethImage = true;
      const files: File[] = Array.from(event.target.files);
      for (let i = 0; i < files.length; i++) {
        this.fiveFile = files[i];
        const reader = new FileReader();
        reader.onload = (event) => {
          const arrayBuffer = reader.result as ArrayBuffer;
          if (arrayBuffer) {
            console.log(arrayBuffer);
            this.fiveBlob = new Blob([arrayBuffer], { type: this.fiveFile.type });
            const url = URL.createObjectURL(this.fiveBlob);
            this.fileNamesFive.push(url);
          }
        };
        reader.readAsArrayBuffer(this.fiveFile);
      }
    }
  }
  readUrl(event: any) {
    if (event && event.target.files && event.target.files.length > 0)
      this.fileNamesOne = [];
      this.firstImage = true;
      this.secondImage = false;
      this.thridImage = false;
      this.fourthImage = false;
      this.fivethImage = false;
      const files: File[] = Array.from(event.target.files);
      for (let i = 0; i < files.length; i++) {
        this.oneFile = files[i];
        this.firstFileName = this.oneFile.name;
        this.readUrlFirstBlob?.push(this.oneFile.name);
        console.log(this.readUrlFirstBlob);
        const reader = new FileReader();
        reader.onload = (event) => {
          const arrayBuffer = reader.result as ArrayBuffer;
          if (arrayBuffer) {
            console.log(arrayBuffer);
            this.oneBlob = new Blob([arrayBuffer], { type: this.oneFile.type });
            console.log(this.oneBlob);
            const url = URL.createObjectURL(this.oneBlob);
            this.fileNamesOne.push(url);
          }
        };
        reader.readAsArrayBuffer(this.oneFile);
      }
  }

  imgUpload(imageUrl: string) {
    console.log("imgUpload method called with imageUrl:", imageUrl);
    // this.uploadImage = imageUrl;
  }

  onFileSelected(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.selectedFiles.push(files.item(i));
    }
  }
  

  imgArray: [];
  addProduct() {
    var form = this.productForm?.value;
    let formData = new FormData();
    formData.append("productName", form?.productName);
    formData.append("title", form?.title);
    formData.append("description", form?.description);
    formData.append("link", form?.link);
    formData.append("type", form?.type);
    formData.append("price", form?.price);
    formData.append("discount", form?.discount);
    formData.append("productCode", form?.code);
    formData.append("stock", form?.stock)
    formData.append("length", form?.length);
    formData.append("width", form?.width);
    formData.append("weight", form?.weight);
    formData.append("height", form?.height);
    formData.append("categoryId", form?.categoryId);
    formData.append("subcategoryId", form?.subcategoryId);
    formData.append("productTypeId", form?.productTypeId);
    const fileData = this.file;
    const oneFileData = this.oneFile;
    const threeFileData = this.threeFile;
    const fourFileData = this.fourFile;
    const fiveFileData = this.fiveFile;
    this.filesArray.push(fileData);
    this.filesArray.push(oneFileData);
    this.filesArray.push(threeFileData);
    this.filesArray.push(fourFileData);
    this.filesArray.push(fiveFileData);
    this.filesArray.forEach(file => {
      formData.append('images', file);
    });
    // this.productservice.addProduct(formData).subscribe((data) => {
    //   console.log(data);
    //   if(data.statusCode === 200) {
    //     this.productForm?.reset();
    //     formData = new FormData();
    //     this.filesArray = [];
    //     this.fileNamesOne = [];
    //     this.fileNamesTwo = []; 
    //     this.fileNamesThree = [];
    //     this.fileNamesFour = []; 
    //     this.fileNamesFive = [];
    //     Swal.fire({
    //       title: "Product",
    //       text: "Product Added Sucessfully!",
    //       icon: "success",
    //     });
    //   } else {
    //     Swal.fire({
    //       title: "Check Required",
    //       text: "Fill Mandatory Fields",
    //       icon: "warning",
    //     });
    //   }
    // })
  }
}
