import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as $ from 'jquery';
import 'bootstrap-datepicker';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { ProductService } from "src/app/shared/service/product.service";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { OrdersComponent } from "../../sales/orders/orders.component";
import { InvoiceComponent } from "../invoice.component";

@Component({
    selector: 'app-quototionPopup',
    templateUrl: './quototionPopup.component.html',
    styleUrls: ['./quototionPopup.component.scss']
})
export class QuototionPopupComponent implements OnInit, AfterViewInit {
    @Input() invoiceComponent: InvoiceComponent;
    // @Output() quotationEdited = new EventEmitter<void>();
    selectedDate: Date;
    quotationFormGroup: FormGroup;
    dropdownSettings: IDropdownSettings = {};
    dropdownList = [];
    submitted: boolean = false;
    tableArray = [];
    formattedDate: any;
    dateFormate: any;
    lashFormattedDate: any;
    formValue: any;
    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private productService: ProductService) { }
    ngOnInit(): void {
        this.form();
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'productID',
            textField: 'productName',
            numberField: 'item_quantity',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 2,
            allowSearchFilter: true
        };
        // if (this.invoiceComponent) {
        //     this.quotationList();
        // } else {
        //     console.error('OrdersComponent instance is not passed!');
        // }
        // this.quotationFormGroup.get("quotation").disable();
    }
    form() {
        this.quotationFormGroup = this.fb.group({
            issue_date: new FormControl("", Validators.required),
            quotation: new FormControl(""),
            product: new FormControl("", Validators.required),
            to_address: new FormControl("", Validators.required)
        })
    }
    quotationList() {
        if (this.invoiceComponent) {
            this.invoiceComponent.getQuototionData();
        }
    }
    onProductName() {
        this.productService.getQuotoionProductName().subscribe((data) => {
            console.log("Dta ", data.data);
            this.dropdownList = data.data;
        });
    }
    onItemSelect(item: any) {
        if (!this.tableArray.some(data => data.productID === item.productID)) {
            const selectItem = this.dropdownList.find(data => data.productID === item.productID);
            if (selectItem) {
                this.tableArray.push(selectItem);
            }
            console.log("this.tableArray ", this.tableArray);
            this.quotationFormGroup.get('product').patchValue(this.tableArray);
        }
    }
    onSelectAll(items: any) {
        this.tableArray = items.map((item) => {
            return this.dropdownList.find(data => data.productID === item.productID) || null;
        }).filter(item => item !== null);
        console.log("this.tableArray ", this.tableArray);
        if (this.quotationFormGroup.get('product')) {
            this.quotationFormGroup.get('product').patchValue(this.tableArray);
        }
    }
    ngAfterViewInit() {
        if ($.fn.datepicker) {
            const today = new Date();
            ($('#quotationDate') as any).datepicker({
                format: 'yyyy/mm/dd',
                autoclose: true,
                todayHighlight: true,
            });
            ($('#quotationDate') as any).datepicker('setDate', today);
            ($('#quotationDate') as any).val('');
            this.dateFormate = new Intl.DateTimeFormat('en-US').format(today);
            console.log("dateFormate ", this.dateFormate);

            // this.quotationFormGroup.get('issue_date').patchValue( this.dateFormate);
            ($('#quotationDate') as any).on('changeDate', (event: any) => {
                this.selectedDate = event.date;
                console.log("this.selectedDate ", this.selectedDate);

                const year = this.selectedDate.getFullYear();
                const month = (this.selectedDate.getMonth() + 1).toString().padStart(2, '0');
                const date = (this.selectedDate.getDate()).toString().padStart(2, '0');
                this.lashFormattedDate = `${year}/${month}/${date}`;
                this.formattedDate = `${year}${month}${date}`;
                console.log("this.formattedDate ", this.formattedDate);
                
                this.quotationFormGroup.get("quotation").patchValue("#PP" + this.formattedDate);
                // this.quotationFormGroup.get("quotation").disable();
            });
        } else {
            console.error('Bootstrap Datepicker is not available.');
        }
    }
    generatePdfInvoice() {
        const invoiceElement = document.getElementById('quototion');
        if (!invoiceElement) {
            console.error('Invoice element not found!');
            return;
        }
        setTimeout(() => {
            invoiceElement.style.display = 'none';
        }, 1000)

        setTimeout(() => {
            invoiceElement.style.display = 'block';
            html2canvas(invoiceElement, { logging: true, useCORS: true, scale: 2 })
                .then(canvas => {
                    const pdf = new jsPDF({
                        orientation: 'portrait',
                        unit: 'mm',
                        format: 'a4'
                    });
                    const imgData = canvas.toDataURL('image/jpeg');
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const imgProps = pdf.getImageProperties(imgData);
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                    console.log('Image Properties:', imgProps);
                    console.log('PDF Width:', pdfWidth);
                    console.log('Calculated PDF Height:', pdfHeight);

                    pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
                    pdf.save('QUOTOTION.pdf');
                    invoiceElement.style.display = 'none';
                })
                .catch(error => {
                    console.error('Error generating PDF:', error);

                    // Ensure the display style is reverted back to none in case of error
                    invoiceElement.style.display = 'none';
                });
        }, 500); // Adjust the delay as necessary
    }

    get controls() {
        return this.quotationFormGroup.controls;
    }
    onSave() {
        // this.submitted = true;
        // if (this.quotationFormGroup.valid) {
        this.quotationFormGroup.get("quotation").patchValue("#PP" + this.formattedDate);
        this.quotationFormGroup.get('issue_date').patchValue(this.lashFormattedDate);
        this.quotationFormGroup.get('product').patchValue(this.tableArray);
        // this.quotationFormGroup.get("quotation").patchValue("#PP" + this.formattedDate);
        this.formValue = this.quotationFormGroup.value;
        console.log("formValue ", this.formValue);
        this.productService.createQuototionData(this.formValue).subscribe((data) => {
            console.log("dta ", data);
            if (data) {
                Swal.fire({
                    title: 'Invoice Bill!',
                    text: `${data.message}`,
                    icon: 'success',
                    confirmButtonText: 'OK',
                    preConfirm: () => {
                        this.quotationFormGroup?.reset();
                        this.activeModal?.close();
                        this.quotationList();
                    }
                });
               
                // this.generatePdfInvoice();
            }
        });
        // }
        // else {
        //     Swal.fire({
        //         title: 'Required Field!',
        //         text: 'Please Check the Mandatory Fields',
        //         icon: 'warning',
        //         confirmButtonText: 'OK'
        //     });
        // }
        // this.quotationFormGroup.markAllAsTouched();
    }
}