<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Add Product</h5>
        </div>
        <div class="card-body">
          <div class="row product-adding">
            <div class="col-xl-5">
              <div class="add-product">
                <div class="row">
                  <h1>Image Preview</h1>
                  <div class="col-xl-9 xl-50 col-sm-6 col-9" style="background-color: #f1f4fb; width: 400px; height: 400px;">
                    <span *ngFor="let fileName of fileNamesOne">
                      <img *ngIf="fileName && firstImage" [src]="fileName" alt="" style="width: 400px;height: 400px;" class="img-fluid image_zoom_1">
                    </span>
                    <span *ngFor="let twoFileName of fileNamesTwo">
                      <img *ngIf="twoFileName && secondImage" [src]="twoFileName" alt=""  style="width: 400px;height: 400px;" class="img-fluid image_zoom_1">
                    </span>
                    <span *ngFor="let threeFileName of fileNamesThree">
                      <img *ngIf="threeFileName && thridImage" [src]="threeFileName" alt=""  style="width: 400px;height: 400px;" class="img-fluid image_zoom_1">
                    </span>
                    <span *ngFor="let fourFileName of fileNamesFour">
                      <img *ngIf="fourFileName && fourthImage" [src]="fourFileName" alt=""  style="width: 400px;height: 400px;" class="img-fluid image_zoom_1">
                    </span>
                    <span *ngFor="let fiveFileName of fileNamesFive">
                      <img *ngIf="fiveFileName && fivethImage" [src]="fiveFileName" alt=""  style="width: 400px;height: 400px;" class="img-fluid image_zoom_1">
                    </span>
                  </div>
                  
                  <div class="col-xl-3 xl-50 col-sm-6 col-3">
                    <span>Image Upload </span>
                    <ul class="file-upload-product">
                      <li>
                        <div class="box-input-file">
                          <input class="upload" type="file" [formControl]="productForm.controls['image']" (change)="readUrl($event)">
                          <span *ngFor="let fileName of fileNamesOne">
                            <img *ngIf="fileName" class="imgUpload" alt="" [src]="fileName">  
                          </span>
                        </div>
                      </li>
                      <li>
                        <div class="box-input-file">
                          <input class="upload" type="file" [formControl]="productForm.controls['image1']" (change)="readUrlTwo($event)">
                          <span *ngFor="let twoFileName of fileNamesTwo">
                            <img *ngIf="twoFileName" class="imgUpload" alt="" [src]="twoFileName">
                          </span>
                        </div>
                      </li>
                      <li>
                        <div class="box-input-file">
                          <input class="upload" type="file" [formControl]="productForm.controls['image2']" (change)="readUrlThree($event)">
                          <span *ngFor="let threeFileName of fileNamesThree">
                            <img *ngIf="threeFileName" class="imgUpload" alt="" [src]="threeFileName">
                          </span>
                        </div>
                      </li>
                      <li>
                        <div class="box-input-file">
                          <input class="upload" type="file" [formControl]="productForm.controls['image3']" (change)="readUrlFour($event)">
                          <span *ngFor="let fourFileName of fileNamesFour">
                            <img *ngIf="fourFileName" class="imgUpload" alt="" [src]="fourFileName">
                          </span>
                        </div>
                      </li>
                      <li>
                        <div class="box-input-file">
                          <input class="upload" type="file" [formControl]="productForm.controls['image4']" (change)="readUrlFive($event)">
                          <span *ngFor="let fiveFileName of fileNamesFive">
                            <img *ngIf="fiveFileName" class="imgUpload" alt="" [src]="fiveFileName">
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-7">
              <form class="needs-validation" [formGroup]="productForm" novalidate>
                <div class="add-product-form">
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4 ">
                      <label for="validationCustom01" class="mb-0">Title
                        :</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input class="form-control" id="validationCustom01" type="text"
                        [formControl]="productForm.controls['title']" required>
                      <div *ngIf="productForm.controls.title.touched && productForm.controls.title.errors?.required"
                        class="text text-danger">
                        Title Name is required.
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4 ">
                      <label for="validationCustom01" class="mb-0">Type
                        :</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input class="form-control" id="validationCustom01" type="text"
                        [formControl]="productForm.controls['type']" required>
                      <div *ngIf="productForm.controls.type.touched && productForm.controls.type.errors?.required"
                        class="text text-danger">
                        Type is required.
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4 ">
                      <label for="validationCustom01" class="mb-0">Link
                        :</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input class="form-control" id="validationCustom01" type="text"
                        [formControl]="productForm.controls['link']" required>
                      <!-- <div *ngIf="productForm.controls.link.touched && productForm.controls.link.errors?.required"
                        class="text text-danger">
                        Link is required.
                      </div> -->
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4 ">
                      <label for="validationCustom01" class="mb-0">Product Name
                        :</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input class="form-control" id="validationCustom01" type="text"
                        [formControl]="productForm.controls['productName']" required>
                      <div *ngIf="productForm.controls.productName.touched && productForm.controls.productName.errors?.required"
                        class="text text-danger">
                        Product Name is required.
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4 ">
                      <label for="validationCustom01" class="mb-0">Discount
                        :</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input class="form-control" id="validationCustom01" type="number"
                        [formControl]="productForm.controls['discount']">
                      <!-- <div *ngIf="productForm.controls.discount.touched && productForm.controls.discount.errors?.required"
                        class="text text-danger">
                        Discount is required.
                      </div> -->
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4 ">
                      <label for="validationCustom01" class="mb-0">Price
                        :</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input class="form-control" id="validationCustom01" type="number"
                        [formControl]="productForm.controls['price']">
                      <div *ngIf="productForm.controls.price.touched && productForm.controls.price.errors?.required"
                        class="text text-danger">
                        Price is required.
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4 ">
                      <label for="validationCustom01" class="mb-0">Product Code
                        :</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input class="form-control" id="validationCustom01" type="text"
                        [formControl]="productForm.controls['code']">
                      <div *ngIf="productForm.controls.code.touched && productForm.controls.code.errors?.required"
                        class="text text-danger">
                        Product Code is required.
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4 ">
                      <label for="validationCustom01" class="mb-0">Stock
                        :</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input class="form-control" id="validationCustom01" type="number"
                        [formControl]="productForm.controls['stock']">
                      <div *ngIf="productForm.controls.stock.touched && productForm.controls.stock.errors?.required"
                        class="text text-danger">
                        Stock is required.
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom01" class="mb-0">Length
                        :</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input class="form-control" id="validationCustom01" type="number"
                        [formControl]="productForm.controls['length']">
                    </div>
                    <!-- <div *ngIf="productForm.controls.length.touched && productForm.controls.length.errors?.required"
                      class="text text-danger">
                      Stock is required.
                    </div> -->
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom01" class="mb-0">Height
                        :</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input class="form-control" id="validationCustom01" type="number"
                        [formControl]="productForm.controls['width']">
                    </div>
                    <!-- <div *ngIf="productForm.controls.length.touched && productForm.controls.length.errors?.required"
                      class="text text-danger">
                      Stock is required.
                    </div> -->
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom01" class="mb-0">Width
                        :</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input class="form-control" id="validationCustom01" type="number"
                        [formControl]="productForm.controls['height']">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom01" class="mb-0">Weight
                        :</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input class="form-control" id="validationCustom01" type="number"
                        [formControl]="productForm.controls['weight']">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4 ">
                      <label for="validationCustom01" class="mb-0">Category Id
                        :</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input class="form-control" id="validationCustom01" type="number"
                        [formControl]="productForm.controls['categoryId']">
                      <div *ngIf="productForm.controls.categoryId.touched && productForm.controls.categoryId.errors?.required"
                        class="text text-danger">
                        Category Id is required.
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4 ">
                      <label for="validationCustom01" class="mb-0">Product Type Id
                        :</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input class="form-control" id="validationCustom01" type="number"
                        [formControl]="productForm.controls['productTypeId']">
                      <div *ngIf="productForm.controls.productTypeId.touched && productForm.controls.productTypeId.errors?.required"
                        class="text text-danger">
                        Product Type Id is required.
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4 ">
                      <label for="validationCustom01" class="mb-0">Sub Category Id
                        :</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <input class="form-control" id="validationCustom01" type="number"
                        [formControl]="productForm.controls['subcategoryId']">
                      <div *ngIf="productForm.controls.subcategoryId.touched && productForm.controls.subcategoryId.errors?.required"
                        class="text text-danger">
                        Sub Category Id is required.
                      </div>
                    </div>
                  </div>
                  <!-- <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="exampleFormControlSelect1" class="mb-0">Select
                        Size
                        :</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <select class="form-control digits" id="exampleFormControlSelect1">
                        <option>Small</option>
                        <option>Medium</option>
                        <option>Large</option>
                        <option>Extra Large</option>
                      </select>
                    </div>
                  </div> -->
                  <!-- <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label class="mb-0">Total Products :</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <div class="qty-box1">
                        <div class="input-group">
                          <i class="fa fa-minus btnGtr1" (click)=decrement()></i>
                          <input class="touchspin1 text-center" name="counter" [value]="counter" type="text">
                          <i class="fa fa-plus btnLess1" (click)=increment()></i>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="form-group row">
                    <div class="col-xl-3 col-sm-4"><label>Add Description :</label></div>
                    <div class="col-xl-8 col-sm-7">
                      <div class="ps-0 description-sm">
                        <ckeditor [editor]="Editor" data="<p>Hello, world!</p>"></ckeditor>
                      </div>
                    </div>
                  </div> -->
                  <div class="form-group row">
                    <div class="col-xl-3 col-sm-4">
                      <label for="validationCustom01" class="mb-0">Description:</label>
                    </div>
                    <div class="col-xl-8 col-sm-7">
                      <textarea class="form-control" id="validationCustom01" [formControl]="productForm.controls['description']"></textarea>
                      <div *ngIf="productForm.controls.description.touched && productForm.controls.description.errors?.required" class="text text-danger">
                        Description is required.
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="form-group row mb-0">
                  <div class="col-sm-8 offset-xl-3 offset-sm-4">
                    <div class="product-buttons">
                        <button type="button" (click)="addProduct()" class="btn btn-primary me-1">Add</button>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
