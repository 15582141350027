import { Component, LOCALE_ID, OnInit, ViewEncapsulation } from "@angular/core";
import { ProductService } from "src/app/shared/service/product.service";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { OrdersComponent } from "../orders/orders.component";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DecimalPipe } from "@angular/common";
import { toWords } from 'number-to-words';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
    selector: 'app-invoiceOrder',
    templateUrl: './invoiceOrder.component.html',
    styleUrls: ['./invoiceOrder.component.scss'],
    providers: [DecimalPipe,
        { provide: LOCALE_ID, useValue: 'en-US' },
    ],
    encapsulation: ViewEncapsulation.None
})



export class InvoiceOrderComponent implements OnInit {
    storeBase64Img: string;
    orderId: any;
    invoiceData: any;
    discount: any;
    numberToWord: any;
    cgstValue: any;
    sgstValue: any;
    igstValue: any;
    shippingAmount: any;
    shippingAmountBoolean: any;
    igstBoolean: boolean;
    totalAmount: any;
    differentShipping: any;
    differentShippingBoolean: boolean;
    discountBoolean: boolean;
    cgstBoolean: boolean
    discountRate: any;
    overAllAmount: any;
    subTotalAmount: any;
    constructor(private productService: ProductService, private ordersComponent: OrdersComponent) { }
    ngOnInit(): void {
        this.orderId = localStorage.getItem('orderId');
        if (this.orderId) {
            console.log("Retrieved order ID: ", this.orderId);
        }
        if (this.ordersComponent) {
            console.log("ordersComponent ", this.ordersComponent);

        }
    }
    invoiceDownload() {
        this.productService.viewOrder(this.orderId).subscribe((data) => {
            if (data.keyword) {
                console.log("DTa ", data.data);
                this.invoiceData = data.data;
                console.log("this.invoiceData ", this.invoiceData.use_different_shipping);
                this.differentShipping = this.invoiceData.use_different_shipping;
                console.log(" this.differentShipping ", this.differentShipping);
                if (this.differentShipping === 'no' || this.differentShipping === null) {
                    console.log("Null");
                    this.differentShippingBoolean = false;
                } else {
                    console.log("Not Null");
                    this.differentShippingBoolean = true;
                }
                if(this.invoiceData.discount === null || this.invoiceData.discount == '0.00') {
                    this.discountBoolean = false;
                } else {
                    this.discountBoolean = true;
                    this.discountRate = this.invoiceData.discountRate;
                    this.discount = this.invoiceData.discount;
                }
                console.log("this.invoiceData.cgst ", this.invoiceData.cgst)
                if((this.invoiceData.cgst === null || this.invoiceData.cgst == '0.00') && (this.invoiceData.sgst === null || this.invoiceData.sgst == '0.00')) {
                    this.cgstValue = this.invoiceData.cgst;
                    console.log("GST Enered2!");
                    this.cgstBoolean = false;
                } else {
                    console.log("GST Enered1!");
                    this.cgstBoolean = true;
                }
                console.log("this.igstValue ", this.igstValue);
                console.log("this.invoiceData.igst ", this.invoiceData.igst);
                if(this.invoiceData.igst === null || this.invoiceData.igst == '0.00') {
                    this.igstValue = this.invoiceData.igst;
                    this.igstBoolean = false;
                } else {
                    this.igstBoolean = true;
                }
                if (this.invoiceData.shippingAmount === null || this.invoiceData.shippingAmount == '0.00') {
                    this.shippingAmountBoolean = false;
                } else {
                    this.shippingAmountBoolean = true;
                    this.shippingAmount = this.invoiceData.shippingAmount;
                }
                if(this.invoiceData.product.over_all_amount) {
                    this.overAllAmount = this.invoiceData.product.over_all_amount;
                    console.log("this.invoiceData.product.over_all_amount ", this.invoiceData.product.over_all_amount);
                    this.numberToWord = toWords(this.overAllAmount);
                }
                if(this.invoiceData.product.sub_total_amount) {
                    this.subTotalAmount = this.invoiceData.product.sub_total_amount;
                }
            }
        });
        this.generatePdfInvoice();
    }

    generatePdfInvoice() {
        const invoiceElement = document.getElementById('invoicePdf');
        if (!invoiceElement) {
            console.error('Invoice element not found!');
            return;
        }
        setTimeout(() => {
            invoiceElement.style.display = 'none';
        }, 1000)
        setTimeout(() => {
            invoiceElement.style.display = 'block';
            html2canvas(invoiceElement, { logging: true, useCORS: true, scale: 2 })
                .then(canvas => {
                    const pdf = new jsPDF({
                        orientation: 'portrait',
                        unit: 'mm',
                        format: 'a4'
                    });
                    const imgData = canvas.toDataURL('image/jpeg');
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const imgProps = pdf.getImageProperties(imgData);
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                    console.log('Image Properties:', imgProps);
                    console.log('PDF Width:', pdfWidth);
                    console.log('Calculated PDF Height:', pdfHeight);

                    pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
                    pdf.save(`INVOICE - ${ this.invoiceData?.invoice_no }.pdf`);
                    invoiceElement.style.display = 'none';
                })
                .catch(error => {
                    console.error('Error generating PDF:', error);

                    // Ensure the display style is reverted back to none in case of error
                    invoiceElement.style.display = 'none';
                });
        }, 500);
    }
}