import { ChangeDetectorRef, Component, Input, LOCALE_ID, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ProductService } from "src/app/shared/service/product.service";
import { OrdersComponent } from "../orders/orders.component";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Router } from "@angular/router";
import { DecimalPipe } from "@angular/common";

@Component({
    selector: 'app-orderModelPopup',
    templateUrl: './orderModelPopup.component.html',
    styleUrls: ['./orderModelPopup.component.scss'],
    providers: [DecimalPipe,
      { provide: LOCALE_ID, useValue: 'en-US' },
  ],
  encapsulation: ViewEncapsulation.None
  })
  
  export class OrderModelPopupComponent implements OnInit  {
    countries = [
        { id: '1', country: 'India' },
        { id: '2', country: 'USA' },
        { id: '3', country: 'Canada' },
    ];
    @Input() ordersComponent: OrdersComponent;
    @Input() orderid;
    createCustomerFormGroup: FormGroup;
    allProducts: any;
    cgstValue: any;
    discount: any;
    sgstValue: any;
    igstValue: any;
    dropdownList = [];
    selectedItems = [];
    dropdownSettings: IDropdownSettings = {};
    tableArray: any = [];
    productDis: boolean = true;
    differentShipping: boolean;
    discountRate: any;
    checkedDiscount: boolean = false;
    totalAmount: any;
    subTotalAmount: any;
    shippingAmount: any;
    gstType: any;
    submitted: boolean = false;
    selectItems: any;
    overallAmount: any
    constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private cdn: ChangeDetectorRef,
        private productService: ProductService, private decimalPipe: DecimalPipe, private router: Router) { }
    ngOnInit(): void {
        this.form();
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'productID',
            textField: 'productName',
            numberField: 'item_quantity',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 2,
            allowSearchFilter: true
        };
        console.log("this.orderid ", this.orderid);
        
        this.viewData();
    }

    viewData() {
        this.productService.viewOrder(this.orderid).subscribe((data) => {
            if(data.keyword) {
                this.patchValue(data.data);
            }
        })
    }

    patchValue(datas: any) {
        console.log("Dtas ", datas.product.products);
        this.createCustomerFormGroup.get('customer_firstName').patchValue(datas.customer_firstName);
        this.createCustomerFormGroup.get('customer_lastName').patchValue(datas.customer_lastName);
        this.createCustomerFormGroup.get('customer_mobile').patchValue(datas.customer_mobile);
        this.createCustomerFormGroup.get('customer_email').patchValue(datas.customer_email);
        // this.createCustomerFormGroup.get('customer_address').patchValue(datas.customer_address);
        this.createCustomerFormGroup.get('customer_countryID').patchValue(datas.customer_countryID);
        this.createCustomerFormGroup.get('customer_city').patchValue(datas.customer_city);
        this.createCustomerFormGroup.get('customer_state').patchValue(datas.customer_state);
        this.createCustomerFormGroup.get('customer_zipCode').patchValue(datas.customer_zipCode);
        this.createCustomerFormGroup.get('recipent_first_name').patchValue(datas.recipent_first_name);
        this.createCustomerFormGroup.get('recipent_last_name').patchValue(datas.recipent_last_name);
        this.createCustomerFormGroup.get('recipent_mobile').patchValue(datas.recipent_mobile);
        this.createCustomerFormGroup.get('recipent_email').patchValue(datas.recipent_email);
        // this.createCustomerFormGroup.get('recipent_address').patchValue(datas.recipent_address);
        this.createCustomerFormGroup.get('recipent_city').patchValue(datas.recipent_city);
        this.createCustomerFormGroup.get('recipent_state').patchValue(datas.recipent_state);
        this.createCustomerFormGroup.get('recipent_countryID').patchValue(datas.recipent_countryID);
        this.createCustomerFormGroup.get('recipent_zipcode').patchValue(datas.recipent_zipcode);
        if(datas.customer_type === 1) {
            this.createCustomerFormGroup.get('customer_type').patchValue('DEALER');
        } else if(datas.customer_type === 2) {
            this.createCustomerFormGroup.get('customer_type').patchValue('SCHOOL');
        } else if(datas.customer_type === 3) {
            this.createCustomerFormGroup.get('customer_type').patchValue('AMAZON');
        } else {
            this.createCustomerFormGroup.get('customer_type').patchValue('CUSTOMER');
        }
        if(datas.gstType === 1) {
            this.createCustomerFormGroup.get('gstType').patchValue('GST');
        } else if(datas.gstType === 2) {
            this.createCustomerFormGroup.get('gstType').patchValue('IGST');
        } else {
            this.createCustomerFormGroup.get('gstType').patchValue('NGST');
        }
        if(datas.cgst) {
            this.cgstValue = datas.cgst;
            console.log("this.cgstValue ", this.cgstValue);
        }
        if(datas.sgst) {
           this.sgstValue = datas.sgst;
           console.log("this.sgstValue ", this.sgstValue);
        }
        if(datas.igst) {
            this.igstValue = datas.igst;
            console.log("this.igstValue ", this.igstValue);
        }
        if (datas.discountRate != undefined || datas.discountRate != 'undefined' ) {
            this.discountRate = datas.discountRate;
        }
        if(datas.use_different_shipping == 'yes') {
            this.differentShipping = true;
        } else {
            this.differentShipping = false;
        }
        this.createCustomerFormGroup.get('igst').patchValue(datas.igst);
        this.createCustomerFormGroup.get('order_date').patchValue(datas.order_date);
        this.createCustomerFormGroup.get('invoice_no').patchValue(datas.invoice_no);
        this.createCustomerFormGroup.get('billing_area').patchValue(datas.billing_area);
        this.createCustomerFormGroup.get('door_no').patchValue(datas.door_no);
        this.createCustomerFormGroup.get('billing_street').patchValue(datas.billing_street);
        this.createCustomerFormGroup.get('billing_landMark').patchValue(datas.billing_landMark);
        this.createCustomerFormGroup.get('customer_companyName').patchValue(datas.company_name);
        this.createCustomerFormGroup.get('recipent_companyName').patchValue(datas.recipent_companyName);
        this.createCustomerFormGroup.get('discountRate').patchValue(datas.discountRate);
        this.createCustomerFormGroup.get('discount').patchValue(datas.discount);
        this.createCustomerFormGroup.get('use_different_shipping').patchValue(datas.use_different_shipping);
        this.createCustomerFormGroup.get('shippingAmount').patchValue(datas.shippingAmount);
        if(datas.shippingAmount) {
            this.shippingAmount = datas.shippingAmount;
        }
        if (datas.discount != undefined || datas.discount != 'undefined') {
            this.discount = datas.discount;
            this.checkedDiscount = true;
        }
        if(datas.use_different_shipping) {
            const differentShipping = datas.use_different_shipping;
            console.log("differentShipping ", differentShipping);
            
        }
        if(datas.discount) {
            this.discount = datas.discount;
        }
        if(datas.product.products) {
            this.allProducts = datas.product.products;
        }
        this.overallAmount = datas.product.over_all_amount;
        console.log("this.overallAmount ", this.overallAmount);
        this.subTotalAmount = datas.product.sub_total_amount;
        
        this.createCustomerFormGroup.get('product').patchValue(datas.product.products);
        this.createCustomerFormGroup.disable();
    }
    ngAfterViewInit(): void {
    }
    form() {
        this.createCustomerFormGroup = this.fb.group({
            customer_firstName: new FormControl(""),
            customer_lastName: new FormControl(""),
            customer_mobile: new FormControl(""),
            customer_email: new FormControl(""),
            // customer_address: new FormControl(""),
            customer_countryID: new FormControl(""),
            customer_city: new FormControl(""),
            customer_state: new FormControl(""),
            customer_zipCode: new FormControl(""),
            recipent_first_name: new FormControl(""),
            recipent_last_name: new FormControl(""),
            recipent_mobile: new FormControl(""),
            recipent_email: new FormControl(""),
            // recipent_address: new FormControl(""),
            recipent_countryID: new FormControl(""),
            recipent_city: new FormControl(""),
            recipent_state: new FormControl(""),
            recipent_zipcode: new FormControl(""),
            customer_type: new FormControl(""),
            product: new FormControl(""),
            gstType: new FormControl(""),
            cgst: new FormControl(""),
            sgst: new FormControl(""),
            igst: new FormControl(""),
            order_date: new FormControl(""),
            invoice_no: new FormControl(""),
            door_no: new FormControl(""),
            billing_area: new FormControl(""),
            billing_street: new FormControl(""),
            billing_landMark: new FormControl(""),
            customer_companyName: new FormControl(""),
            recipent_companyName: new FormControl(""),
            discount: new FormControl(""),
            discountRate: new FormControl(""),
            shippingAmount: new FormControl(""),
            use_different_shipping: new FormControl("")
        });
    }
    closeModel() {
        this.activeModal.close();
    }
  }