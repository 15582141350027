<div class="input-group-append">
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModel()"
  ></button>
</div>
<!-- <div>
  <button class="btn btn-primary" (click)="generateInvoice()">Invoice Generate</button>
</div> -->
<div #screen>
  <div id="invoice" style="display: none; background: white; padding: 20px;">
    <h1 class="text-center"><b>PROFORMA INVOICE</b></h1>
      <table style="width:100%">
        <tr>
          <th style="width: 43%; padding: 1%; border-top: none; border-right: none;">
            <h5 style="color: black;"><b>PETAL 123AUTOMATIONS</b></h5>
            <h5 style="color: black;">No: 155, LIG Colony,</h5>
            <h5 style="color: black;">K K Nagar, Madurai,</h5>
            <h5 style="color: black;">Tamil Nadu - 625020.</h5>
            <h5 style="color: black;">Contact No: +91 9360132690</h5>
            <h5 style="color: black;">GST No: 33BLHPC2236H1ZL</h5>
          </th>
          <td style="text-align: center; border-top: none; border-right: none;">
            <img src="../../../../assets/images/invoice/maroon-logo-01.jpg" style="width: 40%; height: 40%; text-align: center;" alt="">
          </td>
        </tr>
        <tr>
          <th style="width: 43%; padding: 1%; border-top: none; border-right: none; border-bottom: none;">
            <h5 style="color: black; position: static;"><b>BILL TO:</b> </h5>
            <h5 style="color: black;" *ngIf="invoiceData?.customer_firstName || invoiceData?.customer_lastName"><b>{{ invoiceData?.customer_firstName }} {{ invoiceData?.customer_lastName }}<span *ngIf="invoiceData?.customer_lastName">,</span></b></h5>
            <h5 style="color: black;" *ngIf="invoiceData?.customer_companyName">{{ invoiceData?.customer_companyName  }}<span *ngIf="invoiceData?.customer_companyName">,</span></h5>
            <h5 style="color: black" *ngIf="invoiceData?.door_no || invoiceData?.billing_area">{{ invoiceData?.door_no }}<span *ngIf="invoiceData?.door_no">,</span> {{ invoiceData?.billing_area }}<span *ngIf="invoiceData?.billing_area">,</span></h5>
            <h5 style="color: black;" *ngIf="invoiceData?.billing_street || invoiceData?.billing_landMark">{{ invoiceData?.billing_street }}<span *ngIf="invoiceData?.billing_street">,</span> {{ invoiceData?.billing_landMark }}<span *ngIf="invoiceData?.billing_landMark">,</span></h5>
            <h5 style="color: black;" *ngIf="invoiceData?.customer_city || invoiceData?.customer_state || invoiceData?.customer_zipCode"> {{ invoiceData?.customer_city }}<span *ngIf="invoiceData?.customer_city">,</span>
              {{ invoiceData?.customer_state }} -
              {{ invoiceData?.customer_zipCode }}.</h5>
            <h5 style="color: black;" *ngIf="invoiceData?.customer_mobile">Contact No: {{ invoiceData?.customer_mobile }}</h5>
          </th>
          <td style="border-top: none; border-right: none; border-bottom: none; padding: 1%;">
            <div style="display: flex; justify-content: space-around; width: 100%;">
              <div *ngIf="sameAddress" style="width: 50%;">
                <h5 style="color: black; position: static;"><b>SHIP TO:</b> </h5>
                <h5 style="color: black;" *ngIf="invoiceData?.recipent_first_name || invoiceData?.recipent_last_name"><b>{{ invoiceData?.recipent_first_name }} {{ invoiceData?.recipent_last_name }}<span *ngIf="invoiceData?.recipent_last_name">,</span></b></h5>
                <h5 style="color: black;" *ngIf="invoiceData?.recipent_companyName">{{ invoiceData?.recipent_companyName  }}<span *ngIf="invoiceData?.recipent_companyName">,</span></h5>
                <h5 style="color: black" *ngIf="invoiceData?.recipent_door_no || invoiceData?.recipent_billing_area">{{ invoiceData?.recipent_door_no }}<span *ngIf="invoiceData?.recipent_door_no">,</span> {{ invoiceData?.recipent_billing_area }}<span *ngIf="invoiceData?.recipent_billing_area">,</span></h5>
                <h5 style="color: black;" *ngIf="invoiceData?.recipent_billing_street || invoiceData?.recipent_billing_landMark">{{ invoiceData?.recipent_billing_street }}<span *ngIf="invoiceData?.recipent_billing_street">,</span> {{ invoiceData?.recipent_billing_landMark }}<span *ngIf="invoiceData?.recipent_billing_landMark">,</span></h5>
                <h5 style="color: black;" *ngIf="invoiceData?.recipent_city || invoiceData?.recipent_state || invoiceData?.recipent_zipcode">{{ invoiceData?.recipent_city }}<span *ngIf="invoiceData?.recipent_city">,</span> {{ invoiceData?.recipent_state }} - {{ invoiceData?.recipent_zipcode }}.</h5>
                <h5 style="color: black;" *ngIf="invoiceData?.recipent_mobile">Contact No: {{ invoiceData?.recipent_mobile }}</h5>
              </div>
              <div *ngIf="sameAddress === false" style="width: 50%;">
                <h5 style="color: black; position: static;"><b>SHIP TO:</b> </h5>
                <h5 style="color: black;" *ngIf="invoiceData?.customer_firstName || invoiceData?.customer_lastName"><b>{{ invoiceData?.customer_firstName }} {{ invoiceData?.customer_lastName }}<span *ngIf="invoiceData?.customer_lastName">,</span></b></h5>
                <h5 style="color: black;" *ngIf="invoiceData?.customer_companyName">{{ invoiceData?.customer_companyName  }}<span *ngIf="invoiceData?.customer_companyName">,</span></h5>
                <h5 style="color: black" *ngIf="invoiceData?.door_no || invoiceData?.billing_area">{{ invoiceData?.door_no }}<span *ngIf="invoiceData?.door_no">,</span> {{ invoiceData?.billing_area }}<span *ngIf="invoiceData?.billing_area">,</span></h5>
                <h5 style="color: black;" *ngIf="invoiceData?.billing_street || invoiceData?.billing_landMark">{{ invoiceData?.billing_street }}<span *ngIf="invoiceData?.billing_street">,</span> {{ invoiceData?.billing_landMark }}<span *ngIf="invoiceData?.billing_landMark">,</span></h5>
                <h5 style="color: black;" *ngIf="invoiceData?.customer_city || invoiceData?.customer_state || invoiceData?.customer_zipCode"> {{ invoiceData?.customer_city }}<span *ngIf="invoiceData?.customer_city">,</span>
                  {{ invoiceData?.customer_state }} -
                  {{ invoiceData?.customer_zipCode }}.</h5>
                <h5 style="color: black;" *ngIf="invoiceData?.customer_mobile">Contact No: {{ invoiceData?.customer_mobile }}</h5>
              </div>
              <div style="width: 50%;">
                <h5 style="text-align: end; margin-right: 20px; color: black;">DATE: {{invoiceData?.order_date | date : "dd-MM-yyyy"}}</h5>
                <h5 style="text-align: end; margin-right: 0%; color: black;">Invoice No: {{ invoiceData?.invoice_no }}</h5>
              </div>
            </div>
          </td>
        </tr>
      </table>
      <table style="width:100%; font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;">
        <tr>
          <th style="width: 7%; text-align: center; background-color: #2c83ec; padding: 1%; color: white; border-top: none; border-right: none;"><h5>SNO.</h5></th>
          <th style="width: 36%; text-align: center; background-color: #2c83ec; color: white; border-top: none; border-right: none;"><h5>DESCRIPTION</h5></th>
          <th style="width: 10%; text-align: center; background-color: #2c83ec; color: white; border-top: none; border-right: none;"><h5>HSN/SAC</h5></th>
          <th style="width: 15%; text-align: center; background-color: #2c83ec; color: white; border-top: none; border-right: none;"><h5>QUANTITY</h5></th>
          <th style="width: 15%; text-align: center; background-color: #2c83ec; color: white; border-top: none; border-right: none;"><h5>UNITPRICE</h5></th>
          <th style="width: 20%; text-align: center; background-color: #2c83ec; color: white; border-top: none; border-right: none;"><h5>TOTAL</h5></th>
        </tr>
        <tr *ngFor="let data of invoiceData?.product?.products; let i = index">
          <td style="text-align: center; padding: 1%; color: black; border-top: none; border-right: none;"><h5>{{ i + 1  }}</h5></td>
          <td style="text-align: start; color: black; border-top: none; border-right: none; padding: 8px;"><h5>{{ data.productName }}</h5></td>
          <td style="text-align: center; color: black; border-top: none; border-right: none;"><h5>95030090</h5></td>
          <td style="text-align: center; color: black; border-top: none; border-right: none;"><h5>{{ data.product_quantity }}</h5></td>
          <td style="text-align: center; color: black; border-top: none; border-right: none;"><h5>{{ data.productPrice }}</h5></td>
          <td style="text-align: center; color: black; border-top: none; border-right: none;"><h5>{{ data.total_amount | number:'1.2-2' }}</h5></td>
        </tr>
        <tr *ngIf="checkedDiscount" style="border: none;">
          <td colspan="4" style="border: none;"></td>
          <th style="text-align: center; background-color: #2c83ec; padding: 1%; color: white; border-top: none; border-right: none;"><h5><b>DISCOUNT({{ discountRate }}%)</b></h5></th>
          <td style="text-align: center; color: black; border-top: none; border-right: none;"><h5>-{{ discountCalculation(totalSum) | number: '1.2-2' }}</h5></td>
        </tr>
        <tr style="border: none;">
          <td colspan="4" style="border: none;"></td>
          <th style="text-align: center; background-color: #2c83ec; padding: 1%; color: white; border-top: none; border-right: none;"><h5><b>SUB TOTAL</b></h5></th>
          <td style="text-align: center; color: black; border-top: none; border-right: none;"><h5>{{  subTotalAmount(totalSum) | number: '1.2-2' }}</h5></td>
        </tr>
        <tr *ngIf="gstRadioBtn">
          <td colspan="4" style="border: none;"></td>
          <th style="text-align: center; background-color: #2c83ec; padding: 1%; color: white; border-top: none; border-right: none;"><h5><b>CGST 9%</b></h5></th>
          <td style="text-align: center; color: black; border-top: none; border-right: none;"><h5>{{ gstCalculation(totalSum) | number: '1.2-2' }}</h5></td>
        </tr>
        <tr *ngIf="gstRadioBtn">
          <td colspan="4" style="border: none;"></td>
          <th style="text-align: center; background-color: #2c83ec; padding: 1%; color: white; border-top: none; border-right: none;"><h5><b>SGST 9%</b></h5></th>
          <td style="text-align: center; color: black; border-top: none; border-right: none;"><h5>{{ gstCalculation(totalSum) | number: '1.2-2' }}</h5></td>
        </tr>
        <tr *ngIf="igstRadioBtn">
          <td colspan="4" style="border: none;"></td>
          <th style="text-align: center; background-color: #2c83ec; padding: 1%; color: white; border-top: none; border-right: none;"><h5><b>IGST 18%</b></h5></th>
          <td style="text-align: center; color: black; border-top: none; border-right: none;"><h5>{{ igstCalculation(totalSum) | number: '1.2-2' }}</h5></td>
        </tr>
        <tr *ngIf="shippingAmountBoolean">
          <td colspan="4" style="border: none"></td>
          <th style="text-align: center; background-color: #2c83ec; padding: 1%; color: white; border-top: none; border-right: none;"><span><b>SHIPPING CHARGE</b></span></th>
          <td style="text-align: center; color: black; border-top: none; border-right: none;"><h5>{{ shippingAmount | number: '1.2-2' }}</h5></td>
        </tr>
        <tr *ngIf="igstRadioBtn">
          <td colspan="4" style="border: none;"></td>
          <th style="text-align: center; background-color: #2c83ec; padding: 1%; color: white; border-top: none; border-right: none;"><h5><b>TOTAL</b></h5></th>
          <td style="text-align: center; color: black; border-top: none; border-right: none;"><h5>{{ totalSumAndIGst(totalSum) | number:'1.2-2' }}</h5></td>
        </tr>
        <tr *ngIf="gstRadioBtn">
          <td colspan="4" style="border: none;"></td>
          <th style="text-align: center; background-color: #2c83ec; padding: 1%; color: white; border-top: none; border-right: none; border-bottom: none;"><h5><b>TOTAL</b></h5></th>
          <td style="text-align: center; color: black; border-top: none; border-right: none; border-bottom: none;"><h5>{{ totalSumAndGst(totalSum) | number:'1.2-2' }}</h5></td>
        </tr>
        <tr *ngIf="gstRadioBtn === false && igstRadioBtn === false">
          <td colspan="4" style="border: none;"></td>
          <th style="text-align: center; background-color: #2c83ec; padding: 1%; color: white; border-top: none; border-right: none; border-bottom: none;"><h5><b>TOTAL</b></h5></th>
          <td style="text-align: center; color: black; border-top: none; border-right: none; border-bottom: none;"><h5>{{ totalSumandNgst(totalSum) | number:'1.2-2' }}</h5></td>
        </tr>
      </table>
      <div class="amountWords" style="border-top: none;">
        <div style="display: flex; align-items: center;">
          <h5 style="padding: 1%; width: 22%; background-color: #2c83ec; color: white; font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;"><b>AMOUNT IN WORDS</b>
          </h5>
          <h5 style="margin-left: 1%; color: black; font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;">{{ numberToWord | titlecase }} Only.</h5>
        </div>
        <hr class="hrStyle" style="border: 3px solid black;">
        <div class="amountWordsFooter">
          <div class="bankDetails">
            <h5><b>Company Bank Details</b></h5>
            <h5><b>Bank Name: ICICI</b></h5>
            <h5><b>Branch: K K Nagar.</b></h5>
            <h5><b>Account No: 601605016474</b></h5>
            <h5><b>IFSC Code: ICIC0006016</b></h5>
          </div>
          <div class="signatureSection">
            <img src="../../../../assets/images/invoice/SealAndSign.png"  alt="Seal and Sign">
            <h5><b>Authorized Signature</b></h5>
          </div>
        </div>
      </div>
      <h5 style="text-align: center; color: black; font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;" class="mt-3">THANK YOU FOR YOUR BUSINESS</h5>
  </div>
</div>


<form style="padding: 15px" [formGroup]="createCustomerFormGroup">
  <h4 style="text-align: center"><b>Create Order</b></h4>
  <br /><br />
  <hr />
  <h4 style="text-align: center">Product Details</h4>
  <div class="col-6" style="margin-top: 25px; margin-left: 26%;"> 
    <p>Please Select the DEALER / SCHOOL / AMAZON / CUSTOMER</p>
    <input type="radio" id="dealer" name="customer_type" value="DEALER" (click)="onRadio('DEALER')"   [class.is-invalid]="controls.customer_type.invalid && (controls.customer_type.dirty || controls.customer_type.touched)" formControlName="customer_type">&nbsp;&nbsp;
    <label for="dealer">DEALER</label>&nbsp;&nbsp;
    <input type="radio" id="school" name="customer_type" value="SCHOOL" (click)="onRadio('SCHOOL')" [class.is-invalid]="controls.customer_type.invalid && (controls.customer_type.dirty || controls.customer_type.touched)" formControlName="customer_type">&nbsp;&nbsp;
    <label for="school">SCHOOL</label>&nbsp;&nbsp;
    <input type="radio" id="amazon" name="customer_type" value="AMAZON" (click)="onRadio('AMAZON')" [class.is-invalid]="controls.customer_type.invalid && (controls.customer_type.dirty || controls.customer_type.touched)" formControlName="customer_type">&nbsp;&nbsp;
    <label for="amazon">AMAZON</label>&nbsp;&nbsp;
    <input type="radio" id="customer" name="customer_type" value="CUSTOMER" (click)="onRadio('CUSTOMER')" [class.is-invalid]="controls.customer_type.invalid && (controls.customer_type.dirty || controls.customer_type.touched)" formControlName="customer_type">&nbsp;&nbsp;
    <label for="customer">CUSTOMER</label>
    <div *ngIf="controls.customer_type.invalid && (controls.customer_type.dirty || controls.customer_type.touched)" class="invalid-feedback">
      <div *ngIf="controls.customer_type.errors?.['required']">Please select an option.</div>
    </div>
  </div>
  <div class="col-6" style="margin-top: 25px; margin-left: 26%;"> 
    <p>Please Select the GST Type</p>
    <input type="radio" id="gst" name="gstType" value="GST" (click)="onGstRadio('GST')"   [class.is-invalid]="controls.gstType.invalid && (controls.gstType.dirty || controls.gstType.touched)" formControlName="gstType">&nbsp;&nbsp;
    <label for="gst">GST</label>&nbsp;&nbsp;
    <input type="radio" id="igst" name="gstType" value="IGST" (click)="onGstRadio('IGST')" [class.is-invalid]="controls.gstType.invalid && (controls.gstType.dirty || controls.gstType.touched)" formControlName="gstType">&nbsp;&nbsp;
    <label for="igst">IGST</label>&nbsp;&nbsp;
    <!-- <div *ngIf="controls.gstType.invalid && (controls.gstType.dirty || controls.gstType.touched)" class="invalid-feedback">
      <div *ngIf="controls.gstType.errors?.['required']">Please select an GST option.</div>
    </div> -->
  </div>
  <div class="col-6" style="margin-top: 25px; margin-left: 26%;">
    <p for="discount">Discount Percentage</p>
    <input type="checkbox" name="discount" value="discount" (click)="onCheckbox($event)">
  </div>
  <div class="col-6 mb-2" style="margin-top: 25px; margin-left: 26%;" *ngIf="checkedDiscount">
    <input type="text" name="discountField" (input)="onDiscountAmount($event)" class="form-control" formControlName="discount" [class.is-invalid]="controls.discount.invalid && (controls.discount.dirty || controls.discount.touched)" placeholder="Please Enter the Discount Percentage">
    <div *ngIf="controls.discount.invalid && (controls.discount.dirty || controls.discount.touched)" class="invalid-feedback">
      <div *ngIf="controls.discount.errors?.['required']">Please Enter the Discount Amount.</div>
    </div>
  </div>
  <div class="col-6 mb-2" style="margin-top: 25px; margin-left: 26%;">
    <p for="shippingAmount">Shipping Amount</p>
    <input type="text" name="shippingAmount" formControlName="shippingAmount" (input)="onShippingAmount($event)" class="form-control" placeholder="Please Enter the Shipping Amount">
  </div>
  <div class="col-6" style="margin-left: 26%">
    <label for="productName">Product Name</label>
    <ng-multiselect-dropdown
      [ngClass]="'multiSelect'"
      [placeholder]="'Product Name'"
      [settings]="dropdownSettings"
      [data]="dropdownList"
      [disabled]= productDis
      (click)="onDisable()"
      [(ngModel)]="selectedItems"
      id="productName"
      name="productName"
      required
      formControlName="product"
      [class.is-invalid]="submitted && controls.product.invalid && (controls.product.dirty || controls.product.touched)"
      (onSelect)="onItemSelect($event)"
      (onSelectAll)="onSelectAll($event)"
      (onDeSelect)="onItemDeSelect($event)"
      (onDeSelectAll)="onDeSelectAll($event)"
    >
    </ng-multiselect-dropdown>
    <div *ngIf="submitted && controls.product.invalid && (controls.product.dirty || controls.product.touched)" class="invalid-feedback">
      <div *ngIf="controls.product.errors?.['required']">
       Please Select the Product Name.
      </div>
    </div>
  </div>
  <table class="table mt-2">
    <thead>
      <tr>
        <th scope="col" style="text-align: center;">#</th>
        <th scope="col" style="text-align: center;">Product Name</th>
        <th scope="col" style="text-align: center;">Quantity</th>
        <th scope="col" style="text-align: center;">Price</th>
        <th scope="col" style="text-align: center;">Total</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of tableArray; let i = index">
        <th scope="row">{{ i + 1 }}</th>
        <td>{{ data.productName }}</td>
        <td style="text-align: center;">
          <button class="material-icons" style="height: 50px; border: none" [disabled]="data.product_quantity <= 1" (click)="onDecreament(data)">
            chevron_left
          </button>
          <button class="btn btn-primary" style="height: 50px; width: 80px; margin-top: -17px; font-size: 18px; border: none;"><b style=" margin-left: -10px;">{{ data.product_quantity }}</b></button>
          <button class="material-icons" style="height: 50px; border: none;"  (click)="onIncreaement(data)">
            chevron_right
          </button>
        </td>
        
        <td style="text-align: center;"><b>{{ data.productPrice }}</b></td>
        <td style="text-align: center;"><b>{{ data.total_amount | number:'1.2-2'  }}</b></td>
      </tr>
      <h3  class="mt-5 " *ngIf="tableArray.length == 0" style="text-align: center; margin-left: 42%; width: 150%; margin-bottom: 5%;">
        <b> No Data Found!.</b>
       </h3>
       <tr *ngIf="checkedDiscount">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>DISCOUNT({{ discountRate }}%)</b></th>
        <td style="text-align: center; color: black;"><b>-{{ discountCalculation(totalSum) | number: '1.2-2' }}</b></td>
      </tr>
       <tr *ngIf="tableArray?.length > 0">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>SUB TOTAL</b></th>
        <td style="text-align: center; color: black;"><b>{{ subTotalAmount(totalSum) | number: '1.2-2' }}</b></td>
      </tr>
      <tr *ngIf="gstRadioBtn && tableArray?.length > 0">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>CGST 9%</b></th>
        <td style="text-align: center; color: black;"><b>{{ gstCalculation(totalSum) | number: '1.2-2' }}</b></td>
      </tr>
      <tr *ngIf="gstRadioBtn && tableArray?.length > 0">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>SGST 9%</b></th>
        <td style="text-align: center; color: black;"><b>{{ gstCalculation(totalSum) | number: '1.2-2' }}</b></td>
      </tr>
      <tr *ngIf="igstRadioBtn && tableArray?.length > 0">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>IGST 18%</b></th>
        <td style="text-align: center; color: black;"><b>{{ igstCalculation(totalSum) | number: '1.2-2' }}</b></td>
      </tr>
      <tr *ngIf="shippingAmountBoolean && tableArray?.length > 0">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>SHIPPING CHARGE</b></th>
        <td style="text-align: center; color: black;"><b>{{ shippingAmount | number: '1.2-2' }}</b></td>
      </tr>
      <tr *ngIf="igstRadioBtn && tableArray?.length > 0">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>TOTAL AMOUNT</b></th>
        <td style="text-align: center; color: black;"><b>{{ totalSumAndIGst(totalSum) | number:'1.2-2' }}</b></td>
      </tr>
      <!-- <tr *ngIf="gstRadioBtn && tableArray?.length > 0">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>Total Amount</b></th>
        <td style="text-align: center; color: black;"><b>{{ totalSumAndGst(totalSum) | number:'1.2-2' }}</b></td>
      </tr> -->
      <tr *ngIf="gstRadioBtn && tableArray?.length > 0">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>TOTAL AMOUNT</b></th>
        <td style="text-align: center; color: black;"><b>{{ totalSumAndGst(totalSum) | number:'1.2-2' }}</b></td>
      </tr>
      <tr *ngIf="gstRadioBtn === false && igstRadioBtn === false && tableArray?.length > 0">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>TOTAL AMOUNT</b></th>
        <td style="text-align: center; color: black;"><b>{{ totalSumandNgst(totalSum) | number:'1.2-2' }}</b></td>
      </tr>
      <!-- <tr *ngIf="igstRadioBtn">
        <td colspan="4" style="border: none;"></td>
        <th style="text-align: center; background-color: mistyrose; padding: 1%; color: black; border-top: none; border-right: none;"><h5><b>TOTAL</b></h5></th>
        <td style="text-align: center; color: black; border-top: none; border-right: none;"><h5><b>{{ totalSumAndIGst(totalSum) | number:'1.2-2' }}</b></h5></td>
      </tr>
      <tr *ngIf="gstRadioBtn">
        <td colspan="4" style="border: none;"></td>
        <th style="text-align: center; background-color: mistyrose; padding: 1%; color: black; border-top: none; border-right: none; border-bottom: none;"><h5><b>TOTAL</b></h5></th>
        <td style="text-align: center; color: black; border-top: none; border-right: none; border-bottom: none;"><h5><b>{{ totalSumAndIGst(totalSum) | number:'1.2-2' }}</b></h5></td>
      </tr>
      <tr>
        <td colspan="4" style="border: none;"></td>
        <th style="text-align: center; background-color: mistyrose; padding: 1%; color: black; border-top: none; border-right: none; border-bottom: none;"><h5><b>TOTAL</b></h5></th>
        <td style="text-align: center; color: black; border-top: none; border-right: none; border-bottom: none;"><h5><b>{{ totalSum | number:'1.2-2' }}</b></h5></td>
      </tr> -->
    </tbody>
  </table>
  <!-- <div class="col-6" style="margin-top: 25px; margin-left: 26%;">
    <label for="quantity">Quantity</label>
    <input type="text" placeholder="Quantity" id="quantity" class="form-control">
  </div>
  <div class="col-6" style="margin-top: 25px; margin-left: 26%;">
    <label for="price">Price</label>
    <input type="text" placeholder="Price" id="price" class="form-control">
  </div>
  <div class="col-6" style="margin-top: 25px; margin-left: 26%;">
    <label for="total">Total</label>
    <input type="text" placeholder="Total" id="total" class="form-control">
  </div> -->
  <hr />
  <h4 style="text-align: center"><b>Billing Details</b></h4>
  <div style="display: flex; justify-content: center; margin-left: 15px">
    <div class="col-lg-6 col-md-6 col-sm-6 d-flex" style="margin-top: 25px">
      <div
        class="form-group col-lg-6 col-md-6 col-sm-6 mb-2"
        style="margin-right: 20px"
      >
        <label for="date">Date</label>
        <input
          type="date"
          class="form-control"
          id="date"
          name="date"
          [class.is-invalid]="controls.order_date.invalid && (controls.order_date.touched || controls.order_date.dirty)"
          placeholder="Enter the Date"
          formControlName="order_date"
        />
        <div *ngIf="controls.order_date.invalid && (controls.order_date.touched || controls.order_date)" class="invalid-feedback">
          <div *ngIf="controls.order_date.errors?.['required']">
           Date is required.
          </div>
        </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
        <label for="invoiceNo">Invoice No</label>
        <input
          type="text"
          class="form-control"
          id="invoiceNo"
          name="invoiceNo"
          placeholder="Enter the Invoice No"
          formControlName="invoice_no"
          [class.is-invalid]="controls.invoice_no.invalid && (controls.invoice_no.touched || controls.invoice_no.dirty)"
        />
        <div *ngIf="controls.invoice_no.invalid && (controls.invoice_no.touched || controls.invoice_no.dirty)" class="invalid-feedback">
          <div *ngIf="controls.invoice_no.errors?.['required']">
            Billing Invoice Number is required.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: center; margin-left: 15px">
    <div class="col-lg-6 col-md-6 col-sm-6 d-flex" style="margin-top: 25px">
      <div
        class="form-group col-lg-6 col-md-6 col-sm-6 mb-2"
        style="margin-right: 20px"
      >
        <label for="customerFirstName">Billing FirstName</label>
        <input
          type="text"
          class="form-control"
          id="customerFirstName"
          name="customerFirstName"
          [class.is-invalid]="controls.customer_firstName.invalid && (controls.customer_firstName.touched || controls.customer_firstName.dirty)"
          placeholder="Enter the Billing FirstName"
          formControlName="customer_firstName"
        />
        <div *ngIf="controls.customer_firstName.invalid && (controls.customer_firstName.touched || controls.customer_firstName.dirty)" class="invalid-feedback">
          <div *ngIf="controls.customer_firstName.errors?.['required']">
            Billing First Name is required.
          </div>
        </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
        <label for="customerLastName">Billing LastName</label>
        <input
          type="text"
          class="form-control"
          id="customerLastName"
          name="customerLastName"
          placeholder="Enter the Billing LastName"
          formControlName="customer_lastName"
          [class.is-invalid]="controls.customer_lastName.invalid && (controls.customer_lastName.touched || controls.customer_lastName.dirty)"
        />
        <div *ngIf="controls.customer_lastName.invalid && (controls.customer_lastName.touched || controls.customer_lastName.dirty)" class="invalid-feedback">
          <div *ngIf="controls.customer_lastName.errors?.['required']">
            Billing Last Name is required.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="" style="display: flex; justify-content: center">
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div class="form-group mb-2">
        <label for="customerMobile" style="margin-left: 10px; margin-top: 8px"
          >Billing Phone Number</label
        >
        <input
          type="text"
          class="form-control"
          placeholder="Enter the Billing Phone Number"
          id="customerMobile"
          name="customerMobile"
          style="margin-left: 9px; width: 103%"
          formControlName="customer_mobile"
          [class.is-invalid]="controls.customer_mobile.invalid && (controls.customer_mobile.dirty || controls.customer_mobile.touched)"
        />
        <div *ngIf="controls.customer_mobile.invalid && (controls.customer_mobile.dirty || controls.customer_mobile.touched)" class="invalid-feedback mx-2">
          <div *ngIf="controls.customer_mobile.errors?.['required']"> 
            Billing Phone Number is required.
          </div>
          <!-- <div *ngIf="controls.customer_mobile.errors?.['pattern']"> 
            Please give a 10-digit number only, not a string.
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="" style="display: flex; justify-content: center">
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div class="form-group mb-2">
        <label for="customerEmail" style="margin-left: 10px; margin-top: 8px"
          >Billing Email Address</label
        >
        <input
          type="text"
          class="form-control"
          placeholder="Enter the Email Address"
          id="customerEmail"
          name="customerEmail"
          style="margin-left: 9px; width: 103%"
          formControlName="customer_email"
          [class.is-invalid]="controls.customer_email.invalid && (controls.customer_email.dirty || controls.customer_email.touched)"
        />
        <div *ngIf="controls.customer_email.invalid && (controls.customer_email.dirty || controls.customer_email.touched)" class="invalid-feedback mx-2">
          <div *ngIf="controls.customer_email.errors?.['required']">
            Billing Email is required.
          </div>
          <div *ngIf="controls.customer_email.errors?.['email']">
            Email must be a valid email address
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: center; margin-left: 15px">
    <div class="col-lg-6 col-md-6 col-sm-6 d-flex">
      <div
        class="form-group col-lg-6 col-md-6 col-sm-6 mb-2"
        style="margin-right: 20px"
      >
        <label for="customerCity">Billing Door No</label>
        <input
          type="text"
          class="form-control"
          id="doorNo"
          name="doorNo"
          placeholder="Enter the Door No"
          formControlName="door_no"
          [class.is-invalid]="controls.door_no.invalid && (controls.door_no.dirty || controls.door_no.touched)"
        />
        <div *ngIf="controls.door_no.invalid && (controls.door_no.dirty || controls.door_no.touched)" class="invalid-feedback">
          <div *ngIf="controls.door_no.errors?.['required']">
           Door No is required.
          </div>
        </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
        <label for="area">Billing Area</label>
        <input
          type="text"
          class="form-control"
          id="area"
          placeholder="Enter the Area"
          formControlName="billing_area"
          [class.is-invalid]="controls.billing_area.invalid && (controls.billing_area.dirty || controls.billing_area.touched)"
        />
        <div *ngIf="controls.billing_area.invalid && (controls.billing_area.dirty || controls.billing_area.touched)" class="invalid-feedback">
          <div *ngIf="controls.billing_area.errors?.['required']">
           Billing Area is required.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: center; margin-left: 15px">
    <div class="col-lg-6 col-md-6 col-sm-6 d-flex">
      <div
        class="form-group col-lg-6 col-md-6 col-sm-6 mb-2"
        style="margin-right: 20px"
      >
        <label for="street">Billing Street</label>
        <input
          type="text"
          class="form-control"
          id="street"
          name="street"
          placeholder="Enter the Street"
          formControlName="billing_street"
          [class.is-invalid]="controls.billing_street.invalid && (controls.billing_street.dirty || controls.billing_street.touched)"
        />
        <div *ngIf="controls.billing_street.invalid && (controls.billing_street.dirty || controls.billing_street.touched)" class="invalid-feedback">
          <div *ngIf="controls.billing_street.errors?.['required']">
           Street is required.
          </div>
        </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
        <label for="landMark">Billing Land Mark</label>
        <input
          type="text"
          class="form-control"
          id="landMark"
          placeholder="Enter the Land Mark"
          formControlName="billing_landMark"
          [class.is-invalid]="controls.billing_landMark.invalid && (controls.billing_landMark.dirty || controls.billing_landMark.touched)"
        />
        <div *ngIf="controls.billing_landMark.invalid && (controls.billing_landMark.dirty || controls.billing_landMark.touched)" class="invalid-feedback">
          <div *ngIf="controls.billing_landMark.errors?.['required']">
           Land Mark is required.
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="" style="display: flex; justify-content: center">
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div class="form-group mb-2">
        <label for="customerAddress" style="margin-left: 10px; margin-top: 8px"
          >Billing Address</label
        >
        <input
          type="text"
          class="form-control"
          placeholder="Enter the Billing Address"
          id="customerAddress"
          name="customerAddress"
          style="margin-left: 9px; width: 103%"
          formControlName="customer_address"
          [class.is-invalid]="controls.customer_address.invalid && (controls.customer_address.dirty || controls.customer_address.touched)"
        />
        <div *ngIf="controls.customer_address.invalid && (controls.customer_address.dirty || controls.customer_address.touched)" class="invalid-feedback mx-2">
          <div *ngIf="controls.customer_address.errors?.['required']">
            Billing Address is required.
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="" style="display: flex; justify-content: center">
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div class="form-group mb-2">
        <label for="companyName" style="margin-left: 10px; margin-top: 8px"
          >Company Name</label
        >
        <input
          type="text"
          class="form-control"
          placeholder="Enter the Billing Company Name"
          id="companyName"
          name="companyName"
          style="margin-left: 9px; width: 103%"
          formControlName="customer_companyName"
          [class.is-invalid]="controls.customer_companyName.invalid && (controls.customer_companyName.dirty || controls.customer_companyName.touched)"
        />
        <div *ngIf="controls.customer_companyName.invalid && (controls.customer_companyName.dirty || controls.customer_companyName.touched)" class="invalid-feedback mx-2">
          <div *ngIf="controls.customer_companyName.errors?.['required']">
            Billing Company Name is required.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="" style="display: flex; justify-content: center">
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div class="form-group mb-2">
        <label for="customerCountryID" style="margin-left: 10px; margin-top: 8px"
          >Billing Country</label
        >
        <select
          class="form-control"
          id="customerCountryID"
          name="customerCountryID"
          formControlName="customer_countryID"
          style="margin-left: 9px; width: 103%"
          [class.is-invalid]="controls.customer_countryID.invalid && (controls.customer_countryID.dirty || controls.customer_countryID.touched)"
          placeholder="Please Select the Country"
        >
          <option value="" disabled selected>Select the Country</option>
          <option *ngFor="let data of countries" [value]="data.id" (click)="onCountry(data.id)">{{ data.country}}</option>
        </select>
        <div *ngIf="controls.customer_countryID.invalid && (controls.customer_countryID.dirty || controls.customer_countryID.touched)" class="invalid-feedback mx-2">
          <div *ngIf="controls.customer_countryID.errors?.['required']">
            Billing Country is required.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: center; margin-left: 15px">
    <div class="col-lg-6 col-md-6 col-sm-6 d-flex">
      <div
        class="form-group col-lg-6 col-md-6 col-sm-6 mb-2"
        style="margin-right: 20px"
      >
        <label for="customerCity">Billing City</label>
        <input
          type="text"
          class="form-control"
          id="customerCity"
          name="customerCity"
          placeholder="Enter the Billing City"
          formControlName="customer_city"
          [class.is-invalid]="controls.customer_city.invalid && (controls.customer_city.dirty || controls.customer_city.touched)"
        />
        <div *ngIf="controls.customer_city.invalid && (controls.customer_city.dirty || controls.customer_city.touched)" class="invalid-feedback">
          <div *ngIf="controls.customer_city.errors?.['required']">
            Billing City is required.
          </div>
        </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
        <label for="customerState">Billing State</label>
        <input
          type="text"
          class="form-control"
          id="customerState"
          placeholder="Enter the Billing State"
          formControlName="customer_state"
          [class.is-invalid]="controls.customer_state.invalid && (controls.customer_state.dirty || controls.customer_state.touched)"
        />
        <div *ngIf="controls.customer_state.invalid && (controls.customer_state.dirty || controls.customer_state.touched)" class="invalid-feedback">
          <div *ngIf="controls.customer_state.errors?.['required']">
            Billing State is required.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="" style="display: flex; justify-content: center">
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div class="form-group mb-2">
        <label for="customerZipCode" style="margin-left: 10px; margin-top: 8px"
          >Billing Post Code</label
        >
        
        <!-- maxlength="6" -->
        <input
          type="text"
          class="form-control"
          placeholder="Enter the Billing Post Code"
          id="customerZipCode"
          name="customerZipCode"
         
          style="margin-left: 9px; width: 103%"
          formControlName="customer_zipCode"
          [class.is-invalid]="controls.customer_zipCode.invalid && (controls.customer_zipCode.dirty || controls.customer_zipCode.touched)"
        />
        <div *ngIf="controls.customer_zipCode.invalid && (controls.customer_zipCode.dirty || controls.customer_zipCode.touched)" class="invalid-feedback mx-2">
          <div *ngIf="controls.customer_zipCode.errors?.['required']">
            Billing Post Code is required.
          </div>
          <!-- <div *ngIf="controls.customer_zipCode.errors?.['pattern']">
            Billing Post Code must be 6 digit only allowed, not a string.
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="col-6" style="margin-left: 26%; display: flex; align-items: center;">
    <input type="checkbox" name="sameAddress" (change)="onSameAddress($event)" formControlName="use_different_shipping">  
    <p style="margin-top: 19px; margin-left: 12px;"><b style="font-size: 14px;">Ship to a different address ?</b></p>
  </div>
  <hr />
  <div *ngIf="sameAddress">
    <h4 style="text-align: center"><b>Shipping Details</b></h4>
    <div style="display: flex; justify-content: center; margin-left: 15px">
      <div class="col-lg-6 col-md-6 col-sm-6 d-flex" style="margin-top: 25px">
        <div
          class="form-group col-lg-6 col-md-6 col-sm-6 mb-2"
          style="margin-right: 20px"
        >
          <label for="shippingFirstName">Shipping FirstName</label>
          <input
            type="text"
            class="form-control"
            id="shippingFirstName"
            placeholder="Enter the Shipping FirstName"
            formControlName="recipent_first_name"
          />
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
          <label for="shippingLastName">Shipping LastName</label>
          <input
            type="text"
            class="form-control"
            id="shippingLastName"
            placeholder="Enter the Shipping LastName"
            formControlName="recipent_last_name"
          />
        </div>
      </div>
    </div>
    <div class="" style="display: flex; justify-content: center">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group mb-2">
          <label for="shippingPhone" style="margin-left: 10px; margin-top: 8px"
            >Shipping Phone Number</label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Enter the Shipping Phone Number"
            id="shippingPhone"
            style="margin-left: 9px; width: 103%"
            formControlName="recipent_mobile"
          />
        </div>
      </div>
    </div>
    <div class="" style="display: flex; justify-content: center">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group mb-2">
          <label for="shippingEmail" style="margin-left: 10px; margin-top: 8px"
            >Shipping Email Address</label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Enter the Shipping Email Address"
            id="shippingEmail"
            style="margin-left: 9px; width: 103%"
            formControlName="recipent_email"
          />
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center; margin-left: 15px">
      <div class="col-lg-6 col-md-6 col-sm-6 d-flex">
        <div
          class="form-group col-lg-6 col-md-6 col-sm-6 mb-2"
          style="margin-right: 20px"
        >
          <label for="shippingDoorNo">Shipping Door No</label>
          <input
            type="text"
            class="form-control"
            id="shippingDoorNo"
            name="shippingDoorNo"
            placeholder="Enter the Door No"
            formControlName="recipent_door_no"
            [class.is-invalid]="controls.recipent_door_no.invalid && (controls.recipent_door_no.dirty || controls.recipent_door_no.touched)"
          />
          <div *ngIf="controls.recipent_door_no.invalid && (controls.recipent_door_no.dirty || controls.recipent_door_no.touched)" class="invalid-feedback">
            <div *ngIf="controls.recipent_door_no.errors?.['required']">
             Door No is required.
            </div>
          </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
          <label for="shippingArea">Shipping Area</label>
          <input
            type="text"
            class="form-control"
            id="shippingArea"
            placeholder="Enter the Area"
            formControlName="recipent_billing_area"
            [class.is-invalid]="controls.recipent_billing_area.invalid && (controls.recipent_billing_area.dirty || controls.recipent_billing_area.touched)"
          />
          <div *ngIf="controls.recipent_billing_area.invalid && (controls.recipent_billing_area.dirty || controls.recipent_billing_area.touched)" class="invalid-feedback">
            <div *ngIf="controls.recipent_billing_area.errors?.['required']">
             Shipping Area is required.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center; margin-left: 15px">
      <div class="col-lg-6 col-md-6 col-sm-6 d-flex">
        <div
          class="form-group col-lg-6 col-md-6 col-sm-6 mb-2"
          style="margin-right: 20px"
        >
          <label for="shippingStreet">Shipping Street</label>
          <input
            type="text"
            class="form-control"
            id="shippingStreet"
            name="shippingStreet"
            placeholder="Enter the Street"
            formControlName="recipent_billing_street"
            [class.is-invalid]="controls.recipent_billing_street.invalid && (controls.recipent_billing_street.dirty || controls.recipent_billing_street.touched)"
          />
          <div *ngIf="controls.recipent_billing_street.invalid && (controls.recipent_billing_street.dirty || controls.recipent_billing_street.touched)" class="invalid-feedback">
            <div *ngIf="controls.recipent_billing_street.errors?.['required']">
             Street is required.
            </div>
          </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
          <label for="shippingLandMark">Shipping Land Mark</label>
          <input
            type="text"
            class="form-control"
            id="shippingLandMark"
            placeholder="Enter the Land Mark"
            formControlName="recipent_billing_landMark"
            [class.is-invalid]="controls.recipent_billing_landMark.invalid && (controls.recipent_billing_landMark.dirty || controls.recipent_billing_landMark.touched)"
          />
          <div *ngIf="controls.recipent_billing_landMark.invalid && (controls.recipent_billing_landMark.dirty || controls.recipent_billing_landMark.touched)" class="invalid-feedback">
            <div *ngIf="controls.recipent_billing_landMark.errors?.['required']">
             Land Mark is required.
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="" style="display: flex; justify-content: center">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group mb-2">
          <label for="shippingAddress" style="margin-left: 10px; margin-top: 8px"
            >Shipping Address</label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Enter the Shipping Address"
            id="shippingAddress"
            style="margin-left: 9px; width: 103%"
            formControlName="recipent_address"
          />
        </div>
      </div>
    </div> -->
    <div class="" style="display: flex; justify-content: center">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group mb-2">
          <label for="shippingCompanyName" style="margin-left: 10px; margin-top: 8px"
            >Shipping Company Name</label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Enter the Shipping Company Name"
            id="shippingCompanyName"
            name="shippingCompanyName"
            style="margin-left: 9px; width: 103%"
            formControlName="recipent_companyName"
            [class.is-invalid]="controls.recipent_companyName.invalid && (controls.recipent_companyName.dirty || controls.recipent_companyName.touched)"
          />
          <div *ngIf="controls.recipent_companyName.invalid && (controls.recipent_companyName.dirty || controls.recipent_companyName.touched)" class="invalid-feedback mx-2">
            <div *ngIf="controls.recipent_companyName.errors?.['required']">
             Shipping Company Name is required.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="" style="display: flex; justify-content: center">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group mb-2">
          <label for="shippingCountry" style="margin-left: 10px; margin-top: 8px"
            >Shipping Country</label
          >
          <select
            class="form-control"
            id="shippingCountry"
            formControlName="recipent_countryID"
            style="margin-left: 9px; width: 103%"
            placeholder="Please Select the Shipping Country"
          >
          <option value="" disabled selected>Select the Country</option>
          <option *ngFor="let data of countries" [value]="data.id" (click)="onCountry(data.id)">{{ data.country }}</option>
          </select>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center; margin-left: 15px">
      <div class="col-lg-6 col-md-6 col-sm-6 d-flex">
        <div
          class="form-group col-lg-6 col-md-6 col-sm-6 mb-2"
          style="margin-right: 20px"
        >
          <label for="shippingCity">Shipping City</label>
          <input
            type="text"
            class="form-control"
            id="shippingCity"
            placeholder="Enter the Shipping City"
            formControlName="recipent_city"
          />
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
          <label for="shippingState">Shipping State</label>
          <input
            type="text"
            class="form-control"
            id="shippingState"
            placeholder="Enter the Shipping State"
            formControlName="recipent_state"
          />
        </div>
      </div>
    </div>
    <div class="" style="display: flex; justify-content: center">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group mb-2">
          <label for="code" style="margin-left: 10px; margin-top: 8px"
            >Shipping Post Code</label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Enter the Shipping Post Code"
            id="code"
            style="margin-left: 9px; width: 103%"
            formControlName="recipent_zipcode"
          />
        </div>
      </div>
    </div>
  </div>

  <button type="button" class="btn btn-success" (click)="saveData()">
    Save
  </button>
</form>
