<div class="modal-header">
    <h4 class="modal-title">Quotation Popup</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      style="position: absolute; left: 460px; top: 8px"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="quotationFormGroup">
      <div class="form-group">
        <label for="quotationDate">Date <span class="redColor">*</span></label>
        <input
          type="text"
          id="quotationDate"
          name="quotationDate"
          formControlName="issue_date"
          class="form-control"
          placeholder="Select a Date"
          autocomplete="off"
        />
        <!-- <div *ngIf="controls.issue_date?.invalid && (controls.issue_date?.dirty || controls.issue_date?.touched)" class="invalid-feedback">
            <div *ngIf="controls.issue_date.errors?.['required']">
              Please enter the Quotation Date
            </div>
          </div> -->
      </div>
  
      <br />
      <div class="form-group">
        <label for="quotation_No">Quotation</label>
        <input
          type="text"
          name="quotation_No"
          class="form-control"
          formControlName="quotation"
          placeholder="Quotation"
        />
      </div>
      <br />
      <div class="form-group">
        <label for="quotation_address"
          >Address <span class="redColor">*</span></label
        >
        <textarea
          class="form-control"
          name="quotation_address"
          id="quotation_address"
          placeholder="Address....."
          formControlName="to_address"
        ></textarea>
        <!-- <div *ngIf="controls.address?.invalid && (controls.address?.dirty || controls.address?.touched)" class="invalid-feedback">
          <div *ngIf="controls.address.errors?.['required']">
              Please enter the Address
          </div> -->
        <!-- </div> -->
      </div>
      <br />
      <div class="form-group">
        <label for="productName"
          >Product Name <span class="redColor">*</span></label
        >
        <ng-multiselect-dropdown
          [ngClass]="'multiSelect'"
          [placeholder]="'Product Name'"
          [settings]="dropdownSettings"
          [data]="dropdownList"
          id="productName"
          name="productName"
          (click)="onProductName()"
          (onSelect)="onItemSelect($event)"
          (onSelectAll)="onSelectAll($event)"
          (onDeSelect)="onItemDeSelect($event)"
          (onDeSelectAll)="onDeSelectAll($event)"
          formControlName="product"
          [class.is-invalid]="
            controls.product?.invalid &&
            (controls.product?.dirty || controls.product?.touched)
          "
        >
        </ng-multiselect-dropdown>
        <!-- <div *ngIf="controls.product?.invalid && (controls.product?.dirty || controls.product?.touched)" class="invalid-feedback">
          <div *ngIf="controls.product.errors?.['required']">
              Please select the Product
          </div>
        </div> -->
      </div>
      <br />
      <button class="btn btn-primary" (click)="updateQuotoion()">Update</button>
    </form>
  </div>