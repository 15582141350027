import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ToggleFullscreenDirective } from "./directives/fullscreen.directive";
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { NavService } from './service/nav.service';
import { WINDOW_PROVIDERS } from './service/windows.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { TableService } from './service/table.service';
import { NgbdSortableHeader } from './directives/NgbdSortableHeader';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { loaderService } from './service/loader.service';
import { LoaderComponent } from './components/loader/loader.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { authInterceptorInterceptor } from './auth-interceptor.interceptor';

@NgModule({
  declarations: [
    ToggleFullscreenDirective,
    FeatherIconsComponent,
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    SidebarComponent,
    ContentLayoutComponent,
    BreadcrumbComponent,
    RightSidebarComponent,
    NgbdSortableHeader,
  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  // MatProgressSpinnerModule
  // loaderService
  providers: [NavService, TableService,  WINDOW_PROVIDERS, { provide: HTTP_INTERCEPTORS, useClass: authInterceptorInterceptor, multi: true }],
  exports: [FeatherIconsComponent, ToggleFullscreenDirective,NgbdSortableHeader],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
