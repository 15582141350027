<div #screen>
  <div
    id="invoicePdf"
    style="
      display: none;
      background: white;
      padding: 25px;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
        'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    "
  >
    <h1 class="text-center"><b>INVOICE</b></h1>
    <table style="width: 100%">
      <tr>
        <th
          style="width: 43%; padding: 2%; border-top: none; border-right: none"
        >
          <h3>
            <b>PETAL AUTOMATIONS</b>
          </h3>
          <h3>No: 155, LIG Colony,</h3>
          <h3>K K Nagar, Madurai,</h3>
          <h3>Tamil Nadu - 625020.</h3>
          <h3>
            Contact No: +91 9360132690
          </h3>
          <h3>GST No: 33BLHPC2236H1ZL</h3>
        </th>
        <td style="text-align: center; border-top: none; border-right: none">
          <!-- src="../../../../assets/images/invoice/final-petals-logo-2-01.jpg" -->
          <!-- src="../../../../assets/images/invoice/color-logo.png" -->
          <img
            src="../../../../assets/images/invoice/maroon-logo-01.jpg"
            style="width: 40%; height: 40%; text-align: center"
            alt=""
          />
        </td>
      </tr>
      <tr>
        <th
          style="
            width: 43%;
            padding: 2%;
            border-top: none;
            border-right: none;
            border-bottom: none;
          "
        >
          <!-- <h5 style="color: black; font-size: 25px;"><b>BILL TO:</b> </h5><br>
              <h5 style="color: black; font-size: 25px;"><b>{{ invoiceData?.customer_firstName }} {{ invoiceData?.customer_lastName }},</b></h5><br>
              <h5 style="color: black; font-size: 25px;">{{ invoiceData?.company_name  }},</h5><br>
              <h5 style="color: black; font-size: 25px;">{{ invoiceData?.door_no }},</h5><br>
              <h5 style="color: black; font-size: 25px;">{{ invoiceData?. billing_landMark}},</h5><br>
              <h5 style="color: black; font-size: 25px;">{{ invoiceData?. billing_street}},</h5><br>
              <h5 style="color: black; font-size: 25px;">{{ invoiceData?.billing_area }},</h5><br>
              <h5 style="color: black; font-size: 25px;">{{ invoiceData?.customer_city }} - {{ invoiceData?.customer_zipCode }}.</h5><br>
              <h5 style="color: black; font-size: 25px;">Contact No: {{ invoiceData?.customer_mobile }}</h5><br> -->

          <h3 style="position: static;"><b>BILL TO:</b></h3>
          <h3 *ngIf="invoiceData?.customer_firstName || invoiceData?.customer_lastName">
            <b
              >{{ invoiceData?.customer_firstName }}
              {{ invoiceData?.customer_lastName }}<span *ngIf="invoiceData?.customer_lastName">,</span></b
            >
          </h3>
          <h3 *ngIf="invoiceData?.company_name">
            {{ invoiceData?.company_name }}<span *ngIf="invoiceData?.company_name">,</span>
          </h3>
          <h3 *ngIf="invoiceData?.door_no || invoiceData?.billing_area">
            {{ invoiceData?.door_no }}<span *ngIf="invoiceData?.door_no">,</span> {{ invoiceData?.billing_area }}<span *ngIf="invoiceData?.billing_area">,</span>   
          </h3>
          <h3 *ngIf="invoiceData?.billing_street || invoiceData?.billing_landMark">
            {{ invoiceData?.billing_street }}<span *ngIf="invoiceData?.billing_street">,</span> {{ invoiceData?.billing_landMark }}<span *ngIf="invoiceData?.billing_landMark">,</span>
          </h3>
          <h3 *ngIf="invoiceData?.customer_city || invoiceData?.customer_state || invoiceData?.customer_zipCode">
            {{ invoiceData?.customer_city }}<span *ngIf="invoiceData?.customer_city">,</span>
            {{ invoiceData?.customer_state }} -
            {{ invoiceData?.customer_zipCode }}.
          </h3>
          <h3 *ngIf="invoiceData?.customer_mobile">
            Contact No: {{ invoiceData?.customer_mobile }}
          </h3>
        </th>
        <td style="border-top: none; border-right: none; border-bottom: none; padding: 1%;">
          <!-- <h5 style="text-align: end; margin-top: -27%; margin-right: 10%; color: black; font-size: 25px;">DATE: {{invoiceData?.order_date | date : "dd-MM-yyyy"}}</h5><br>
             <h5 style="text-align: end; margin-right: 5%; color: black; font-size: 25px;">Invoice No: {{ invoiceData?.invoice_no }}</h5> -->
          <div style="display: flex; justify-content: space-around; width: 100%;">
            <div *ngIf="differentShippingBoolean" style="width: 50%;">
              <h3 style="position: static;"><b>SHIP TO:</b></h3>
              <h3 *ngIf="invoiceData?.recipent_first_name || invoiceData?.recipent_last_name">
                <b
                  >{{ invoiceData?.recipent_first_name }}
                  {{ invoiceData?.recipent_last_name }}<span *ngIf="invoiceData?.recipent_last_name">,</span></b
                >
              </h3>
              <h3 *ngIf="invoiceData?.recipent_companyName">
                {{ invoiceData?.recipent_companyName }}<span *ngIf="invoiceData?.recipent_companyName">,</span>
              </h3>
              <h3 *ngIf="invoiceData?.recipent_door_no || invoiceData?.recipent_zone">
                {{ invoiceData?.recipent_door_no }}<span *ngIf="invoiceData?.door_no">,</span> {{ invoiceData?.recipent_zone }}<span *ngIf="invoiceData?.recipent_zone">,</span>  
              </h3>
              <h3 *ngIf="invoiceData?.recipent_Avenue || invoiceData?.recipent_land_mark">
                {{ invoiceData?.recipent_Avenue }}<span *ngIf="invoiceData?.recipent_Avenue">,</span> {{ invoiceData?.recipent_land_mark }}<span *ngIf="invoiceData?.recipent_land_mark">,</span>
              </h3>
              <h3 *ngIf="invoiceData?.recipent_city || invoiceData?.recipent_state || invoiceData?.recipent_zipcode">
                {{ invoiceData?.recipent_city }}<span *ngIf="invoiceData?.recipent_city">,</span>
                {{ invoiceData?.recipent_state }} -
                {{ invoiceData?.recipent_zipcode }}.
              </h3>
              <h3 *ngIf="invoiceData?.recipent_mobile">
                Contact No: {{ invoiceData?.recipent_mobile }}
              </h3>
            </div>
            <div *ngIf="!differentShippingBoolean" style="width: 50%;">
              <h3 style="position: static;"><b>SHIP TO:</b></h3>
              <h3 *ngIf="invoiceData?.customer_firstName || invoiceData?.customer_lastName">
                <b
                  >{{ invoiceData?.customer_firstName }}
                  {{ invoiceData?.customer_lastName }}<span *ngIf="invoiceData?.customer_lastName">,</span></b
                >
              </h3>
              <h3 *ngIf="invoiceData?.company_name">
                {{ invoiceData?.company_name }}<span *ngIf="invoiceData?.company_name">,</span>
              </h3>
              <h3 *ngIf="invoiceData?.door_no || invoiceData?.billing_area">
                {{ invoiceData?.door_no }}<span *ngIf="invoiceData?.door_no">,</span> {{ invoiceData?.billing_area }}<span *ngIf="invoiceData?.billing_area">,</span>   
              </h3>
              <h3 *ngIf="invoiceData?.billing_street || invoiceData?.billing_landMark">
                {{ invoiceData?.billing_street }}<span *ngIf="invoiceData?.billing_street">,</span> {{ invoiceData?.billing_landMark }}<span *ngIf="invoiceData?.billing_landMark">,</span>
              </h3>
              <h3 *ngIf="invoiceData?.customer_city || invoiceData?.customer_state || invoiceData?.customer_zipCode">
                {{ invoiceData?.customer_city }}<span *ngIf="invoiceData?.customer_city">,</span>
                {{ invoiceData?.customer_state }} -
                {{ invoiceData?.customer_zipCode }}.
              </h3>
              <h3 *ngIf="invoiceData?.customer_mobile">
                Contact No: {{ invoiceData?.customer_mobile }}
              </h3>
            </div>
            <div style="width: 50%;">
              <div style="text-align: right;">
                <h5 style="margin-right: 30px; font-size: 20px;"><b>DATE:</b> {{ invoiceData?.order_date | date: 'dd-MM-yyyy' }}</h5>
              </div>
              <div style="text-align: right;">
                <h5 style="margin: 0; font-size: 20px;"><b>INVOICE NO:</b> {{ invoiceData?.invoice_no }}</h5>
              </div>
            </div>
            <!-- <div style="width: 50%;">
              <h5
                style="
                  text-align: end;
                  margin-top: 0% !important;
                  margin-right: 33px !important;
                  color: black;
                  font-size: 25px;
                "
              >
                DATE: {{ invoiceData?.order_date | date : "dd-MM-yyyy" }}
              </h5>
              <h5
                style="
                  text-align: end;
                  margin-right: 0% !important;
                  color: black;
                  font-size: 25px;
                "
              >
                INVOICE NO: {{ invoiceData?.invoice_no }}
              </h5>
            </div> -->
          </div>
        </td>
      </tr>
    </table>
    <table
      style="
        width: 100%;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
          'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      "
    >
      <tr>
        <th
          style="
            width: 7%;
            text-align: center;
            background-color: 	#2c83ec;
            padding: 1%;
            color: white;
            border-top: none;
            border-right: none;
          "
        >
          <h3>SNO.</h3>
        </th>
        <th
          style="
            width: 36%;
            text-align: center;
            background-color: 	#2c83ec;
            color: white;
            border-top: none;
            border-right: none;
          "
        >
          <h3>DESCRIPTION</h3>
        </th>
        <th
          style="
            width: 10%;
            text-align: center;
            background-color: 	#2c83ec;
            color: white;
            border-top: none;
            border-right: none;
          "
        >
          <h3>HSN/SAC</h3>
        </th>
        <th
          style="
            width: 15%;
            text-align: center;
            background-color: 	#2c83ec;
            color: white;
            border-top: none;
            border-right: none;
          "
        >
          <h3>QUANTITY</h3>
        </th>
        <th
          style="
            width: 15%;
            text-align: center;
            background-color: 	#2c83ec;
            color: white;
            border-top: none;
            border-right: none;
          "
        >
          <h3>UNITPRICE</h3>
        </th>
        <th
          style="
            width: 20%;
            text-align: center;
            background-color: 	#2c83ec;
            color: white;
            border-top: none;
            border-right: none;
          "
        >
          <h3>TOTAL</h3>
        </th>
      </tr>
      <tr *ngFor="let data of invoiceData?.product?.products; let i = index">
        <td
          style="
            text-align: center;
            padding: 1%;
            color: black;
            border-top: none;
            border-right: none;
          "
        >
          <h3>{{ i + 1 }}</h3>
        </td>
        <td
          style="
            text-align: start;
            color: black;
            border-top: none;
            border-right: none;
            padding: 8px;
          "
        >
          <h3>
            {{ data.productName }}
          </h3>
        </td>
        <td
          style="
            text-align: center;
            color: black;
            border-top: none;
            border-right: none;
          "
        >
          <h3>95030090</h3>
        </td>
        <td
          style="
            text-align: center;
            color: black;
            border-top: none;
            border-right: none;
          "
        >
          <h3>
            {{ data.product_quantity }}
          </h3>
        </td>
        <td
          style="
            text-align: center;
            color: black;
            border-top: none;
            border-right: none;
          "
        >
          <h3>
            {{ data.productPrice }}
          </h3>
        </td>
        <td
          style="
            text-align: center;
            color: black;
            border-top: none;
            border-right: none;
          "
        >
          <h3>
            {{ data.total_amount | number:'1.2-2' }}
          </h3>
        </td>
      </tr>
      <tr style="border: none" *ngIf="discount != '0.00' && discountBoolean">
        <td colspan="4" style="border: none"></td>
        <th
          style="
            text-align: center;
            background-color: 	#2c83ec;
            padding: 1%;
            font-size: 20px;
            color: white;
            border-top: none;
            border-right: none;
          "
        >
          <b>DISCOUNT({{ discountRate }}%)</b>
        </th>
        <td
          style="
            text-align: center;
            color: black;
            border-top: none;
            font-size: 20px;
            border-right: none;
          "
        >
          
            -{{ discount | number:'1.2-2' }}
          
        </td>
      </tr>
      <tr style="border: none">
        <td colspan="4" style="border: none"></td>
        <th
          style="
            text-align: center;
            background-color: 	#2c83ec;
            padding: 1%;
            font-size: 20px;
            color: white;
            border-top: none;
            border-right: none;
          "
        >
          <b>SUB TOTAL</b>
        </th>
        <td
          style="
            text-align: center;
            color: black;
            font-size: 20px;
            border-top: none;
            border-right: none;
          "
        >
            {{ subTotalAmount | number:'1.2-2' }}
        </td>
      </tr>
      <tr *ngIf="cgstValue != '0.00' && cgstBoolean === true">
        <td colspan="4" style="border: none"></td>
        <th
          style="
            text-align: center;
            background-color: 	#2c83ec;
            padding: 1%;
            color: white;
            font-size: 20px;
            border-top: none;
            border-right: none;
          "
        >
          <b>CGST 9%</b>
        </th>
        <td
          style="
            text-align: center;
            color: black;
            border-top: none;
            border-right: none;
            font-size: 20px;
          "
        >
            {{ invoiceData?.cgst | number:'1.2-2' }}
        </td>
      </tr>
      <tr *ngIf="cgstValue != '0.00' && cgstBoolean === true">
        <td colspan="4" style="border: none"></td>
        <th
          style="
            text-align: center;
            background-color: 	#2c83ec;
            padding: 1%;
            font-size: 20px;
            color: white;
            border-top: none;
            border-right: none;
          "
        >
          <b>SGST 9%</b>
        </th>
        <td
          style="
            text-align: center;
            color: black;
            border-top: none;
            border-right: none;
            font-size: 20px;
          "
        >
            {{ invoiceData?.sgst | number:'1.2-2' }}
        </td>
      </tr>
      <tr *ngIf="igstValue != '0.00' && igstBoolean === true">
        <td colspan="4" style="border: none"></td>
        <th
          style="
            text-align: center;
            background-color: 	#2c83ec;
            padding: 1%;
            color: white;
            font-size: 20px;
            border-top: none;
            border-right: none;
          "
        >
          <b>IGST 18%</b>
        </th>
        <td
          style="
            text-align: center;
            color: black;
            border-top: none;
            border-right: none;
            font-size: 20px;
          "
        >
            {{ invoiceData?.igst | number:'1.2-2' }}
        </td>
      </tr>
      <tr *ngIf="shippingAmountBoolean">
        <td colspan="4" style="border: none"></td>
        <th
          style="
            text-align: center;
            background-color: 	#2c83ec;
            padding: 1%;
            color: white;
            font-size: 20px;
            border-top: none;
            border-right: none;
          "
        >
          <b>SHIPPING CHARGE</b>
        </th>
        <td
          style="
            text-align: center;
            color: black;
            border-top: none;
            border-right: none;
            font-size: 20px;
          "
        >
          
            {{ shippingAmount | number : "1.2-2" }}
          
        </td>
      </tr>
      <tr>
        <td colspan="4" style="border: none"></td>
        <th
          style="
            text-align: center;
            background-color: 	#2c83ec;
            padding: 1%;
            color: white;
            font-size: 20px;
            border-top: none;
            border-right: none;
          "
        >
        <b>TOTAL</b>
        </th>
        <td
          style="
            text-align: center;
            color: black;
            border-top: none;
            border-right: none;
            font-size: 20px;
          "
        >
            {{ overAllAmount | number:'1.2-2' }}
        </td>
        
      </tr>
      <!-- <tr *ngIf="sgstValue != 0.0">
        <td colspan="4" style="border: none"></td>
        <th
          style="
            text-align: center;
            background-color: mistyrose;
            padding: 1%;
            color: black;
            border-top: none;
            border-right: none;
            border-bottom: none;
          "
        >
          <h3><b>TOTAL</b></h3>
        </th>
        <td
          style="
            text-align: center;
            color: black;
            border-top: none;
            border-right: none;
            border-bottom: none;
          "
        >
          <h3>
            <b>{{ totalAmount | number : "1.2-2" }}</b>
          </h3>
        </td>
      </tr> -->
    </table>
    <div class="amountWords" style="border-top: none">
      <div style="display: flex; align-items: center">
        <h3
          style="
            padding: 1%;
            width: 22%;
            background-color: 	#2c83ec;
            color: white;
            font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
              'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
          "
        >
          <b>AMOUNT IN WORDS</b>
        </h3>
        <h5
          style="
            margin-left: 1%;
            color: black;
            font-size: 25px;
            font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
              'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
          "
        >
          {{ numberToWord | titlecase }} Only.
        </h5>
      </div>
      <hr class="hrStyle" style="border: 3px solid black" />
      <div class="amountWordsFooter">
        <div class="bankDetails">
          <h3><b>Company Bank Details</b></h3>
          <h3><b>Bank Name: ICICI</b></h3>
          <h3><b>Branch: K K Nagar.</b></h3>
          <h3><b>Account No: 601605016474</b></h3>
          <h3><b>IFSC Code: ICIC0006016</b></h3>
        </div>
        <!-- display: flex;
        flex-direction: column;
        justify-content: flex-end; -->
        <div class="signatureSection">
          <img src="../../../../assets/images/invoice/SealAndSign.png" alt="">
          <h3>Authorized Signature</h3>
        </div>
      </div>
    </div>
    <h3
      style="
        text-align: center;
        color: black;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
          'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      "
      class="mt-3"
    >
      THANK YOU FOR YOUR BUSINESS
    </h3>
  </div>
</div>
