import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/shared/service/product.service';
import Swal from 'sweetalert2';
import { jwtDecode } from "jwt-decode";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  submitted: boolean = false;
  public loginForm: FormGroup;
  public registerForm: UntypedFormGroup;
  public active = 1;

  constructor(private formBuilder: UntypedFormBuilder, private productService: ProductService, private router: Router) {
    this.createLoginForm();
    this.createRegisterForm();
  }

  // owlcarousel = [
  //   {
  //     title: "Welcome to Petal Automations",
  //     desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  //   },
  //   {
  //     title: "Welcome to Petal Automations",
  //     desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  //   },
  //   {
  //     title: "Welcome to Petal Automations",
  //     desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  //   }
  // ]
  // owlcarouselOptions = {
  //   loop: true,
  //   items: 1,
  //   dots: true
  // };

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    })
  }
  createRegisterForm() {
    this.registerForm = this.formBuilder.group({
      userName: [''],
      password: [''],
      confirmPassword: [''],
    })
  }

  onLogin() {
    if (this.loginForm?.valid) {
      const form = this.loginForm?.value;
      const formValue = {
        username: form?.email,
        password: form?.password
      }

      this.productService.createLogin(formValue).subscribe((data: any) => {
        if (data.keyword === 'success') {
          Swal.fire({
            title: 'Login',
            text: `${data.message}`,
            icon: 'success',
            confirmButtonText: 'OK',
          });
          this.router.navigate(['/dashboard/default']);
        } else {
          Swal.fire({
            title: 'Failure',
            text: `${data.message}`,
            icon: 'warning',
            confirmButtonText: 'OK',
          });
        }
      });
      // next: (data) => {

      //   console.log("Data ", data);
      //   if (data.success === true) {
      //     const token = data.token;
      //     const decodeToken: any = jwtDecode(token);
      //     console.log("decodeToken ", decodeToken);
      //     if (decodeToken.user_type === 1) {
      //       localStorage.removeItem("Token Ecommerce Not 1");
      //       localStorage.setItem("Token Ecommerce", token);
      //     } else {
      //       localStorage.setItem("Token Ecommerce Not 1", token);
      //       localStorage.removeItem("Token Ecommerce");
      //     }
      //     Swal.fire({
      //       title: 'Login',
      //       text: `${data.message}`,
      //       icon: 'success',
      //       confirmButtonText: 'OK',
      //     });

      //   } else {
      //     Swal.fire({
      //       title: 'Failure',
      //       text: `${data.message}`,
      //       icon: 'warning',
      //       confirmButtonText: 'OK',
      //     });
      //   }
      // },
      // error: (error) => {
      //   const errorMessage = error.error?.message;
      //   Swal.fire({
      //     title: 'Error!',
      //     text: errorMessage,
      //     icon: 'error',
      //     confirmButtonText: 'OK',
      //   })
      // }
      // });
    } else {
      this.loginForm?.markAllAsTouched();
      this.loginForm.updateValueAndValidity();
    }
  }
  get controls() {
    return this.loginForm.controls;
  }

  ngOnInit() {
  }

  onSubmit() {

  }

}
