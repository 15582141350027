import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ActivitylogComponent } from "./activitylog.component";

const routes: Routes = [
    {
        path: '',
        component: ActivitylogComponent,
        data: {
          title: "Activity",
          breadcrumb: "Activity"
        }
      },
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class ActivtyRoutingModule { }