<!-- Container-fluid starts-->




<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5>List Purchase</h5>
          </div>
          <div class="card-body">
            <div class="custom-datatable">
              <!-- <form> -->
                <!-- (input)="orderSearch()" -->
                <!-- (click)="clearSearch()" -->
              <div class="mb-3">
                <input
                  type="text"
                  class="filter-ngx form-control"
                
                  placeholder="Search..."
                  autocomplete="off"
                  autocomplete="off"
                />
                <div class="input-group-append1">
                  <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    
                  ></button>
                </div>
              </div>
              <div style="display: flex; justify-content: end">
  
                <button type="button" class="btn btn-primary mb-5" data-toggle="orderModal" data-original-title="test"
                data-target="#orderModal">Add Purchase</button>
              </div>
              <!-- </form> -->
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" style="text-align: center;">Order Id</th>
                    <th scope="col" style="text-align: center;">Invoice No</th>
                    <th scope="col" style="text-align: center;">Product Name</th>
                    <th scope="col" style="text-align: center;">Quantity</th>
                    <th scope="col" style="text-align: center;">Total</th>
                    <th scope="col" colspan="2" style="text-align: center;">Actions</th>
                    <th scope="col" style="text-align: center;">Approved</th>
                    <th scope="col" style="text-align: center;"></th>
                  </tr>
                </thead>
                <tbody>
                </tbody>
              </table>
              <!-- <div style="text-align: center" *ngIf="orderDetails?.length === 0">
                <b>No Data Found!....</b>
              </div> -->
              <div class="d-flex justify-content-between p-2">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  