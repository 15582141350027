import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/shared/service/product.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-email',
  templateUrl: './forgot-email.component.html',
  styleUrl: './forgot-email.component.scss'
})
export class ForgotEmailComponent implements OnInit {
  forgotEmail: FormGroup;

  constructor(private fb: FormBuilder, private router: Router, private productService: ProductService){}

  ngOnInit(): void {
    this.form();
  }

  form() {
    this.forgotEmail = this.fb.group({
      email: new FormControl("", [Validators.required, Validators.email])
    });
  }

  navigateToForgot() {
    if(this.forgotEmail?.valid) {
      const form = this.forgotEmail?.value;
      const formValue = {
        'email': form?.email
      }
      this.productService.forgot(formValue).subscribe((data) => {
        console.log("Dta ", data);
        if (data.success) {
          Swal.fire({
            title: 'Success!',
            text: `${data.message}`,
            icon: 'success',
            confirmButtonText: 'OK',
          });
          this.router.navigate(['/forgot']);
        } else {
          Swal.fire({
            title: 'Failure',
            text: `${data.message}`,
            icon: 'warning',
            confirmButtonText: 'OK',
          });
        }
      }); 
    } else {
      this.forgotEmail?.markAllAsTouched();
      this.forgotEmail?.updateValueAndValidity();
    }
  }

  get controls() {
    return this.forgotEmail.controls;
  }

}
