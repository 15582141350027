import { DecimalPipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import { InvoiceDB, INVOICEDB } from '../../shared/tables/invoice';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { QuototionPopupComponent } from './quototionPopup/quototionPopup.component';
import { ProductService } from 'src/app/shared/service/product.service';
import { EditQuototionPopupComponent } from './editQuototionPopup/editQuototionPopup.component';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
  providers: [TableService, DecimalPipe]
})
export class InvoiceComponent implements OnInit {
  modalRef: NgbModalRef;
  listData: any;
  products: any;
  pdfData: any;
constructor(private modelService: NgbModal, private productSevice: ProductService) {}



  ngOnInit() {
    this.getQuototionData();
  }

  getQuototionData() {
    this.productSevice.getQuototionData().subscribe((data) => {
      this.listData = data.quotations;
      // this.products = data.quotations.products;
    });
  }

  onPdf(id: any) {
    for(let data of this.listData) {
      if(data.quotation_id === id) {
        console.log("listData ", data);
        this.pdfData = data;
        this.generatePdfInvoice();
      }
    }
  }

  // generatePdfInvoice() {
  //   const invoiceElement = document.getElementById('quototion');
  //   if (!invoiceElement) {
  //     console.error('Invoice element not found!');
  //     return;
  //   }
  //   setTimeout(() => {
  //     invoiceElement.style.display = 'none';
  //   }, 1000)

  //   setTimeout(() => {
  //     invoiceElement.style.display = 'block';
  //     html2canvas(invoiceElement, { logging: true, useCORS: true, scale: 2 })
  //       .then(canvas => {
  //         const pdf = new jsPDF({
  //           orientation: 'portrait',
  //           unit: 'mm',
  //           format: 'a4'
  //         });
  //         const imgData = canvas.toDataURL('image/jpeg');
  //         const pdfWidth = pdf.internal.pageSize.getWidth();
  //         const imgProps = pdf.getImageProperties(imgData);
  //         const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //         console.log('Image Properties:', imgProps);
  //         console.log('PDF Width:', pdfWidth);
  //         console.log('Calculated PDF Height:', pdfHeight);

  //         pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
  //         pdf.save('QUOTOTION.pdf');
  //         invoiceElement.style.display = 'none';
  //       })
  //       .catch(error => {
  //         console.error('Error generating PDF:', error);

  //         // Ensure the display style is reverted back to none in case of error
  //         invoiceElement.style.display = 'none';
  //       });
  //   }, 500); // Adjust the delay as necessary
  // }
  generatePdfInvoice() {
    const invoiceElement = document.getElementById('quototion');
    const backgroundPath = '../../../../assets/images/invoice/final-petals-logo-2-01.jpg'; // Path to your background image

    if (!invoiceElement) {
        console.error('Invoice element not found!');
        return;
    }

    // Hide the element after some delay
    setTimeout(() => {
        invoiceElement.style.display = 'none';
    }, 1000);

    // Ensure the element is displayed before capturing it
    setTimeout(() => {
        invoiceElement.style.display = 'block';

        // Load the background image
        const backgroundImg = new Image();
        backgroundImg.src = backgroundPath;

        // When background image is loaded
        backgroundImg.onload = () => {
            html2canvas(invoiceElement, { logging: true, useCORS: true, scale: 2 })
                .then(canvas => {
                    const pdf = new jsPDF({
                        orientation: 'portrait',
                        unit: 'mm',
                        format: 'a4'
                    });

                    const imgData = canvas.toDataURL('image/jpeg');
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const pdfHeight = pdf.internal.pageSize.getHeight();

                    // Add the background image with reduced opacity by drawing it with a lower alpha
                    pdf.addImage(backgroundImg, 'JPEG', 0, 0, pdfWidth, pdfHeight, '', 'FAST', 0.3);  // Opacity is 0.3 for transparency

                    // Overlay the invoice content
                    const imgProps = pdf.getImageProperties(imgData);
                    const contentHeight = (imgProps.height * pdfWidth) / imgProps.width;

                    pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, contentHeight);

                    // Save the PDF
                    pdf.save('QUOTOTION.pdf');

                    // Hide the element again after generating PDF
                    invoiceElement.style.display = 'none';
                })
                .catch(error => {
                    console.error('Error generating PDF:', error);
                    invoiceElement.style.display = 'none';
                });
        };

        // Error handling for background image loading
        backgroundImg.onerror = (err) => {
            console.error("Error loading background image:", err);
        };

    }, 500); // Adjust the delay as necessary
}





  onEdit(id: any) {
    const modalRef = this.modelService.open(EditQuototionPopupComponent, {  windowClass: 'right-modal'});
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.quotationEdited.subscribe(() => {
      this.getQuototionData();
    });
  }

  onPopupOpen() {
    const modelRef = this.modelService.open(QuototionPopupComponent, {  windowClass: 'right-modal'});
    modelRef.componentInstance.invoiceComponent = this;
  }

}
