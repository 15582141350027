import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'src/app/shared/service/product.service';
import { OrderModelPopupComponent } from '../orderModelPopup/orderModelPopup.component';
import { CreateOrderModelPopupComponent } from '../createOrderModelPopup/createOrderModelPopup.component';
import { EditOrderModelPopupComponent } from '../editOrderModelPopup/editOrderModelPopup.component';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { toWords } from 'number-to-words';
import { InvoiceOrderComponent } from '../invoiceOrder/invoiceOrder.component';
import { jwtDecode } from 'jwt-decode';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class OrdersComponent implements OnInit, AfterViewInit {
  orderDetails: any;
  limit: number = 10;
  offset: number = 0;
  totalCount: number = 0;
  searchText: any;
  public closeResult: string;
  fullDetails: any;
  orderId: any;
  pdfDetails: boolean = true;
  // @ViewChild('screen') screen: ElementRef;
  storeBase64Img: string;
  invoiceData: any;
  numberToWord: any;
  shippingAmount: number = 1000;
  orderApprove: any;
  approveData: any;
  decodeToken: any;
  @ViewChild('invoiceOrderComponent1') invoiceOrderComponent1: InvoiceOrderComponent;

  constructor(public orderService: ProductService, private modelService: NgbModal, private cdr: ChangeDetectorRef
  ) {
    // this.tableItem$ = service.tableItem$;
    // this.total$ = service.total$;
    // this.service.setUserData(ORDERDB)
  }        
  ngOnInit() {
    // this.getOrderData();
    // setTimeout(() => {
    this.orderList();
    this.imageShowing();
    const token = localStorage.getItem("Token Ecommerce") || localStorage.getItem("Token Ecommerce Not 1");
    console.log("Token ", token);
    if(token) {
      this.decodeToken = jwtDecode(token);
      console.log("this.decodeToken ", this.decodeToken);
      // if(this.decodeToken.user_type !== 1) {
        
      // }
    }
    // }, 2000);
  }

  ngAfterViewInit() {
    if (this.invoiceOrderComponent1) {
      console.log('InvoiceOrderComponent1:', this.invoiceOrderComponent1);
    } else {
      console.error('InvoiceOrderComponent1 is undefined!');
    }
  }

  imageShowing() {
    this.orderService.convertImageToBase64('assets/images/invoice/final-petals-logo-2-01.jpg', (base64Image) => {
      this.storeBase64Img = base64Image;
    });
  }
  orderList() {
    this.orderService.getOrder().subscribe((data) => {
      this.orderDetails = data.data;
      console.log("this.orderDetails1 ", this.orderDetails);
      // for(let data of this.orderDetails) {
      //   this.approveData = data.approve;
      // }
      // this.orderDetails = data.data.map((order: any) => {
      //   order.orderApprove = order.approve === 1 ? true : false;
      //   console.log("order.orderApprove ", order.orderApprove);
      //   return order;
      // })
    //   for(let approve of data.data) {
    //     console.log("approve ", approve);
    //     if(approve.approve === 0) {
    //       this.orderApprove = false;
    //     } else {
    //       this.orderApprove = true;
    //     }
    //   }
    //   this.orderDetails = data.data;
    });
  }

  getFormattedProductNames(productName: string) {
    // console.log("Product ", productName.length);
    return productName;
  }

  getFormattedTotalAmount(price: any): string {
    return price.toFixed(2);
  }

  openInvoice(orderId: any) {
    localStorage.setItem('orderId', orderId);
    if (this.invoiceOrderComponent1) {
      this.invoiceOrderComponent1.orderId = orderId; // Ensure orderId is set in invoiceOrderComponent1
      this.invoiceOrderComponent1.invoiceDownload(); // Trigger the download method
    }
  }

  onApprovel(order: any) {
    for(let data of this.orderDetails) {
      console.log("DTa ", data);
      if(data.OrderId === order) {
        console.log("data.approve ", data.approve);
        data.approve = 1;
        this.approveData = data.approve;
        this.orderService.getOrderApprove(data.approve, order).subscribe((data) => {
          this.orderList();
        }); 
      }
    }
    // this.orderApprove = true;
    // this.orderApprove === true ? 1 : 1;
  }
  


  generatePdfInvoice() {
    const invoiceElement = document.getElementById('invoice');
    if (!invoiceElement) {
      console.error('Invoice element not found!');
      return;
    }
    invoiceElement.style.display = 'block';

    setTimeout(() => {
      html2canvas(invoiceElement, { logging: true, useCORS: true, scale: 2 })
        .then(canvas => {
          const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4'
          });
          const imgData = canvas.toDataURL('image/jpeg');
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const imgProps = pdf.getImageProperties(imgData);
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
          pdf.save('INVOICE.pdf');
          invoiceElement.style.display = 'none';
        })
        .catch(error => {
          console.error('Error generating PDF:', error);
          // Ensure the display style is reverted back to none in case of error
          invoiceElement.style.display = 'none';
        });
    }, 500); // Adjust the delay as necessary
  }



  // orderSearch() {
  //   if (this.searchText.trim() !== '') {
  //     this.orderDetails = this.orderDetails.filter((data) => {
  //       return data.productName.toLowerCase().includes(this.searchText.toLowerCase());
  //     });
  //   } else {
  //     this.getOrderData(); 
  //   }
  // }


  // getOrderData() {
  //   let offset = (this.currentPage - 1) * this.limit;
  //   this.orderService.getOrderDetails(this.limit, offset).subscribe((data) => {
  //     this.orderDetails = data.data;
  //     this.totalCount = data.count;
  //     console.log(this.totalCount);
  //   })
  // }
  // currentPage: number = 1;

  // clearSearch() {
  //   this.searchText = '';
  //   this.getOrderData();
  // }
  open(orderid: any) {
    const modalRef = this.modelService.open(OrderModelPopupComponent, { size: 'xl' });
    modalRef.componentInstance.orderid = orderid;
  }

  openEdit(orderid: any) {
    const modalRef = this.modelService.open(EditOrderModelPopupComponent, { size: 'xl' });
    modalRef.componentInstance.orderid = orderid;
    modalRef.componentInstance.orderEdited.subscribe(() => {
      this.orderList();
    });
  }
  orderOpen() {
    const modelRef = this.modelService.open(CreateOrderModelPopupComponent, { size: 'xl' });
    modelRef.componentInstance.ordersComponent = this;
  }
}
