import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable, OnInit } from "@angular/core";
import { fork } from "child_process";
import { BehaviorSubject, catchError, Observable, Subject, throwError } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class ProductService implements OnInit {
    private baseURL = environment.baseUrl;
    constructor(private http: HttpClient) {

    }
    ngOnInit(): void {

    }

    // addProduct(productData: any): Observable<any> {
    //     return this.http.post(`${this.baseURL}product/create`, productData);
    // }

    // getOrderDetails(limit: number, offset: number): Observable<any> {
    //     let params = new HttpParams();
    //     params = params.append('limit', limit.toString());
    //     params = params.append('offset', offset.toString());
    //     return this.http.get(`${this.baseURL}order/list`, { params: params });
    // }
    // getProductList(limit: number, offset: number): Observable<any> {
    //     let params = new HttpParams();
    //     params = params.append('limit', limit.toString());
    //     params = params.append('offset', offset.toString());
    //     return this.http.get(`${this.baseURL}product/list`, { params: params });
    // }
    // getOrderFullDetails(orderId: any): Observable<any> {
    //     let params = new HttpParams();
    //     params = params.append('orderId', orderId.toString());
    //     return this.http.get(`${this.baseURL}order/view`, { params: params});
    // }

    uploadEmail(formData: FormData): Observable<any> {
        return this.http.post(`${this.baseURL}send-Invoice`, formData);
    }
    convertImageToBase64(url: string, callback: (base64Image: string) => void) {
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // To handle cross-origin issues if applicable
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          if (!ctx) return;
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL('image/jpeg'); // Use the appropriate image format
          callback(dataURL);
        };
        img.src = url;
    }

    getProductName(cutomerType: any): Observable<any> {
        let params = new HttpParams();
        params = params.append('customer_type', cutomerType.toString())
        return this.http.get(`${this.baseURL}list`, { params: params});
    }

    getQuotoionProductName(): Observable<any> {
        return this.http.get(`${this.baseURL}list`);
    }

    createOrder(body: any): Observable<any> {
        return this.http.post(`${this.baseURL}create/order`, body);
    }

    editOrder(body: any): Observable<any> {
        return this.http.put(`${this.baseURL}order/update`, body);
    }

    getOrder(): Observable<any> {
        return this.http.get(`${this.baseURL}orders/list`);
    }

    getOrderApprove(approve: any, orderId: any): Observable<any> {
        let params = new HttpParams();
        params = params.append('approve', approve.toString()),
        params = params.append('id', orderId.toString())
        return this.http.get(`${this.baseURL}orders/list`, { params: params });
    }

    viewOrder(orderId: any): Observable<any> {
        console.log("orderId ", orderId);
        
        if (orderId == null) {
            throw new Error("Order ID is required");
        }
        let params = new HttpParams();
        params = params.append('order_id', orderId.toString())
        return this.http.get(`${this.baseURL}orders/list`, { params: params});
    }

    createQuototionData(body): Observable<any> {
        return this.http.post(`${this.baseURL}quotation_data`, body);
    }

    getQuototionData(): Observable<any> {
        return this.http.get(`${this.baseURL}get_quotation_list`);
    }

    viewQuototionData(quotationId: any): Observable<any> {
        // let params = new HttpParams();
        // params = params.append('quotation_id', quotationId.toString())
        return this.http.get(`${this.baseURL}view_quotation/${quotationId}`);
    }

    editQuototionData(body: any): Observable<any> {
        return this.http.put(`${this.baseURL}edit_quotation`, body);
    }
    // private orderServiceSubject = new BehaviorSubject<any>(null);
    // orderService$ = this.orderServiceSubject.asObservable();
  
    // setOrderService(service: any) {
    //   this.orderServiceSubject.next(service);
    // }
    createLogin(body): Observable<any> {
        return this.http.post(`${this.baseURL}login`, body) .pipe(
            catchError((error: HttpErrorResponse) => {
                console.log("Error ", error);
                return throwError(error);
            })
        )
    }
    private orderEmitter = new EventEmitter<any>();

  orderEvent$ = this.orderEmitter.asObservable();

  emitOrderData(data: any) {
    this.orderEmitter.emit(data);
  }

  userType(): Observable<any> {
    return this.http.get(` ${this.baseURL}users/user_type`);
  }
  
  addUserType(body: any): Observable<any> {
    return this.http.post(`${this.baseURL}users/register`, body);
  }
  forgot(body: any): Observable<any> {
    return this.http.post(`${this.baseURL}users/password/email`, body);
  }
  reset(body: any, token: any): Observable<any> {
    return this.http.post(`${this.baseURL}users/password/reset/${token}`, body)
    .pipe(
        catchError((error: HttpErrorResponse) => {
            console.log("Error ", error);
            return throwError(error);
        })
    )
  }
  moduleType(): Observable<any> {
    return this.http.get(`${this.baseURL}user_activity/get_log_moule`);
  }

  activityList(body: any): Observable<any> {
    return this.http.post(`${this.baseURL}user_activity/report`, body)
    .pipe(
        catchError((error: HttpErrorResponse) => {
            return throwError(error);
        })
    )
  }
}