<div class="input-group-append">
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModel()"
  ></button>
</div>
<form style="padding: 15px" [formGroup]="createCustomerFormGroup">
  <h4 style="text-align: center"><b>View Order</b></h4>
  <br /><br />
  <hr />
  <h4 style="text-align: center">Product Details</h4>
  <div class="col-6" style="margin-top: 25px; margin-left: 26%;"> 
    <p>Please Select the DEALER / SCHOOL / AMAZON / CUSTOMER</p>
    <input type="radio" id="dealer" name="customer_type" value="DEALER" formControlName="customer_type">&nbsp;&nbsp;
    <label for="dealer">DEALER</label>&nbsp;&nbsp;
    <input type="radio" id="school" name="customer_type" value="SCHOOL" formControlName="customer_type">&nbsp;&nbsp;
    <label for="school">SCHOOL</label>&nbsp;&nbsp;
    <input type="radio" id="amazon" name="customer_type" value="AMAZON" formControlName="customer_type">&nbsp;&nbsp;
    <label for="amazon">AMAZON</label>&nbsp;&nbsp;
    <input type="radio" id="customer" name="customer_type" value="CUSTOMER" formControlName="customer_type">&nbsp;&nbsp;
    <label for="customer">CUSTOMER</label>
  </div>
  <div class="col-6" style="margin-top: 25px; margin-left: 26%;"> 
    <p>Please Select the GST Type</p>
    <input type="radio" id="gst" name="gstType" value="GST" formControlName="gstType">&nbsp;&nbsp;
    <label for="gst">GST</label>&nbsp;&nbsp;
    <input type="radio" id="igst" name="gstType" value="IGST" formControlName="gstType">&nbsp;&nbsp;
    <label for="igst">IGST</label>&nbsp;&nbsp;
  </div>
  <div class="col-6" style="margin-top: 25px; margin-left: 26%;">
    <p for="discount">Discount Amount</p>
    <input type="checkbox" name="discount" value="discount" [(ngModel)]="checkedDiscount" disabled [ngModelOptions]="{standalone: true}">
  </div>
  <div class="col-6 mb-2" style="margin-top: 25px; margin-left: 26%;" *ngIf="checkedDiscount">
    <input type="text" name="discountField" class="form-control" formControlName="discountRate" placeholder="Please Enter the Discount Percentage">
  </div>
  <div class="col-6 mb-2" style="margin-top: 25px; margin-left: 26%;">
    <p for="shippingAmount">Shipping Amount</p>
    <input type="text" name="shippingAmount" formControlName="shippingAmount" class="form-control" placeholder="Please Enter the Shipping Amount">
  </div>
  <div class="col-6" style="margin-left: 26%">
    <label for="productName">Product Name</label>
    <ng-multiselect-dropdown
      [ngClass]="'multiSelect'"
      [placeholder]="'Product Name'"
      [settings]="dropdownSettings"
      [data]="dropdownList"
      [disabled]= productDis
      [(ngModel)]="selectedItems"
      id="productName"
      name="productName"
      required
      formControlName="product"
    >
    </ng-multiselect-dropdown>
  </div>
  <table class="table mt-2">
    <thead>
      <tr>
        <th scope="col" style="text-align: center;">#</th>
        <th scope="col" style="text-align: center;">Product Name</th>
        <th scope="col" style="text-align: center;">Quantity</th>
        <th scope="col" style="text-align: center;">Price</th>
        <th scope="col" style="text-align: center;">Total</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of allProducts; let i = index">
        <th scope="row">{{ i + 1 }}</th>
        <td>{{ data.productName }}</td>
        <td style="text-align: center;">
          <button class="material-icons" style="height: 50px; border: none" [disabled]="data.product_quantity <= 1">
            chevron_left
          </button>
          <button class="btn btn-primary" style="height: 50px; width: 80px; margin-top: -17px; font-size: 18px; border: none;"><b style=" margin-left: -10px;">{{ data.product_quantity }}</b></button>
          <button class="material-icons" style="height: 50px; border: none;">
            chevron_right
          </button>
        </td>
        
        <td style="text-align: center;"><b>{{ data.productPrice }}</b></td>
        <td style="text-align: center;"><b>{{ data.total_amount | number:'1.2-2'  }}</b></td>
      </tr>
      <!-- <h3  class="mt-5 " *ngIf="tableArray.length == 0" style="text-align: center; margin-left: 42%; width: 150%; margin-bottom: 5%;">
        <b> No Data Found!.</b>
       </h3> -->
       <tr *ngIf="discount != '0.00'">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>Discount({{ discountRate }}%)</b></th>
        <td style="text-align: center; color: black;"><b>{{ discount }}</b></td>
      </tr>
       <tr>
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>Sub Total</b></th>
        <td style="text-align: center; color: black;"><b>{{ subTotalAmount }}</b></td>
      </tr>
      <tr *ngIf="cgstValue != '0.00'">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>CGST 9%</b></th>
        <td style="text-align: center; color: black;"><b>{{ cgstValue }}</b></td>
      </tr>
      <tr *ngIf="sgstValue != '0.00'">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>SGST 9%</b></th>
        <td style="text-align: center; color: black;"><b>{{ sgstValue }}</b></td>
      </tr>
      <tr *ngIf="igstValue != '0.00'">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>IGST 18%</b></th>
        <td style="text-align: center; color: black;"><b>{{ igstValue }}</b></td>
      </tr>
      <tr *ngIf="shippingAmount != '0.00'">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>SHIPPING CHARGE</b></th>
        <td style="text-align: center; color: black;"><b>{{ shippingAmount | number: '1.2-2' }}</b></td>
      </tr>
      <tr *ngIf="igstValue != '0.00'">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>Total</b></th>
        <td style="text-align: center; color: black;"><b>{{ overallAmount  | number: '1.2-2'}}</b></td>
      </tr>
      <tr *ngIf="sgstValue != '0.00'">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>Total</b></th>
        <td style="text-align: center; color: black;"><b>{{ overallAmount | number: '1.2-2' }}</b></td>
      </tr>
      <tr *ngIf="sgstValue == '0.00' && igstValue == '0.00'">
        <td colspan="3"></td>
        <th style="text-align: center; padding: 1%; color: black;"><b>Total</b></th>
        <td style="text-align: center; color: black;"><b>{{ overallAmount | number: '1.2-2' }}</b></td>
      </tr>
    </tbody>
  </table>
  <hr />
  <h4 style="text-align: center"><b>Billing Details</b></h4>
  <div style="display: flex; justify-content: center; margin-left: 15px">
    <div class="col-lg-6 col-md-6 col-sm-6 d-flex" style="margin-top: 25px">
      <div
        class="form-group col-lg-6 col-md-6 col-sm-6 mb-2"
        style="margin-right: 20px"
      >
        <label for="date">Date</label>
        <input
          type="date"
          class="form-control"
          id="date"
          name="date"
          placeholder="Enter the Date"
          formControlName="order_date"
        />
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
        <label for="invoiceNo">Invoice No</label>
        <input
          type="text"
          class="form-control"
          id="invoiceNo"
          name="invoiceNo"
          placeholder="Enter the Invoice No"
          formControlName="invoice_no"
        />
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: center; margin-left: 15px">
    <div class="col-lg-6 col-md-6 col-sm-6 d-flex" style="margin-top: 25px">
      <div
        class="form-group col-lg-6 col-md-6 col-sm-6 mb-2"
        style="margin-right: 20px"
      >
        <label for="customerFirstName">Billing FirstName</label>
        <input
          type="text"
          class="form-control"
          id="customerFirstName"
          name="customerFirstName"
          placeholder="Enter the Billing FirstName"
          formControlName="customer_firstName"
        />
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
        <label for="customerLastName">Billing LastName</label>
        <input
          type="text"
          class="form-control"
          id="customerLastName"
          name="customerLastName"
          placeholder="Enter the Billing LastName"
          formControlName="customer_lastName"
        />
      </div>
    </div>
  </div>
  <div class="" style="display: flex; justify-content: center">
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div class="form-group mb-2">
        <label for="customerMobile" style="margin-left: 10px; margin-top: 8px"
          >Billing Phone Number</label
        >
        <input
          type="text"
          class="form-control"
          placeholder="Enter the Billing Phone Number"
          id="customerMobile"
          name="customerMobile"
          style="margin-left: 9px; width: 103%"
          formControlName="customer_mobile"
        />
      </div>
    </div>
  </div>
  <div class="" style="display: flex; justify-content: center">
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div class="form-group mb-2">
        <label for="customerEmail" style="margin-left: 10px; margin-top: 8px"
          >Billing Email Address</label
        >
        <input
          type="text"
          class="form-control"
          placeholder="Enter the Email Address"
          id="customerEmail"
          name="customerEmail"
          style="margin-left: 9px; width: 103%"
          formControlName="customer_email"
        />
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: center; margin-left: 15px">
    <div class="col-lg-6 col-md-6 col-sm-6 d-flex">
      <div
        class="form-group col-lg-6 col-md-6 col-sm-6 mb-2"
        style="margin-right: 20px"
      >
        <label for="customerCity">Billing Door No</label>
        <input
          type="text"
          class="form-control"
          id="doorNo"
          name="doorNo"
          placeholder="Enter the Door No"
          formControlName="door_no"
        />
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
        <label for="area">Billing Area</label>
        <input
          type="text"
          class="form-control"
          id="area"
          placeholder="Enter the Area"
          formControlName="billing_area"
        />
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: center; margin-left: 15px">
    <div class="col-lg-6 col-md-6 col-sm-6 d-flex">
      <div
        class="form-group col-lg-6 col-md-6 col-sm-6 mb-2"
        style="margin-right: 20px"
      >
        <label for="street">Billing Street</label>
        <input
          type="text"
          class="form-control"
          id="street"
          name="street"
          placeholder="Enter the Street"
          formControlName="billing_street"
        />
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
        <label for="landMark">Billing Land Mark</label>
        <input
          type="text"
          class="form-control"
          id="landMark"
          placeholder="Enter the Land Mark"
          formControlName="billing_landMark"
        />
      </div>
    </div>
  </div>
  <!-- <div class="" style="display: flex; justify-content: center">
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div class="form-group mb-2">
        <label for="customerAddress" style="margin-left: 10px; margin-top: 8px"
          >Billing Address</label
        >
        <input
          type="text"
          class="form-control"
          placeholder="Enter the Billing Address"
          id="customerAddress"
          name="customerAddress"
          style="margin-left: 9px; width: 103%"
          formControlName="customer_address"
        />
      </div>
    </div>
  </div> -->
  <div class="" style="display: flex; justify-content: center">
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div class="form-group mb-2">
        <label for="companyName" style="margin-left: 10px; margin-top: 8px"
          >Company Name</label
        >
        <input
          type="text"
          class="form-control"
          placeholder="Enter the Billing Address"
          id="companyName"
          name="companyName"
          style="margin-left: 9px; width: 103%"
          formControlName="customer_companyName"
        />
      </div>
    </div>
  </div>
  <div class="" style="display: flex; justify-content: center">
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div class="form-group mb-2">
        <label for="customerCountryID" style="margin-left: 10px; margin-top: 8px"
          >Billing Country</label
        >
        <select
          class="form-control"
          id="customerCountryID"
          name="customerCountryID"
          formControlName="customer_countryID"
          style="margin-left: 9px; width: 103%"
          placeholder="Please Select the Country"
        >
          <option value="" disabled selected>Select the Country</option>
          <option *ngFor="let data of countries" [value]="data.id">{{ data.country}}</option>
        </select>
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: center; margin-left: 15px">
    <div class="col-lg-6 col-md-6 col-sm-6 d-flex">
      <div
        class="form-group col-lg-6 col-md-6 col-sm-6 mb-2"
        style="margin-right: 20px"
      >
        <label for="customerCity">Billing City</label>
        <input
          type="text"
          class="form-control"
          id="customerCity"
          name="customerCity"
          placeholder="Enter the Billing City"
          formControlName="customer_city"
        />
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
        <label for="customerState">Billing State</label>
        <input
          type="text"
          class="form-control"
          id="customerState"
          placeholder="Enter the Billing State"
          formControlName="customer_state"
        />
      </div>
    </div>
  </div>
  <div class="" style="display: flex; justify-content: center">
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div class="form-group mb-2">
        <label for="customerZipCode" style="margin-left: 10px; margin-top: 8px"
          >Billing Post Code</label
        >
        <input
          type="text"
          class="form-control"
          placeholder="Enter the Billing Post Code"
          id="customerZipCode"
          name="customerZipCode"
          maxlength="6"
          style="margin-left: 9px; width: 103%"
          formControlName="customer_zipCode"
        />
      </div>
    </div>
  </div>
  <div class="col-6" style="margin-left: 26%; display: flex; align-items: center;">
    <input type="checkbox" name="sameAddress" [(ngModel)]="differentShipping" formControlName="use_different_shipping">  
    <p style="margin-top: 19px; margin-left: 12px;"><b style="font-size: 14px;">Ship to a different address ?</b></p>
  </div>
  <hr />
  <div *ngIf="differentShipping">
  <h4 style="text-align: center"><b>Shipping Details</b></h4>
    <div style="display: flex; justify-content: center; margin-left: 15px">
      <div class="col-lg-6 col-md-6 col-sm-6 d-flex" style="margin-top: 25px">
        <div
          class="form-group col-lg-6 col-md-6 col-sm-6 mb-2"
          style="margin-right: 20px"
        >
          <label for="shippingFirstName">Shipping FirstName</label>
          <input
            type="text"
            class="form-control"
            id="shippingFirstName"
            placeholder="Enter the Shipping FirstName"
            formControlName="recipent_first_name"
          />
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
          <label for="shippingLastName">Shipping LastName</label>
          <input
            type="text"
            class="form-control"
            id="shippingLastName"
            placeholder="Enter the Shipping LastName"
            formControlName="recipent_last_name"
          />
        </div>
      </div>
    </div>
    <div class="" style="display: flex; justify-content: center">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group mb-2">
          <label for="shippingPhone" style="margin-left: 10px; margin-top: 8px"
            >Shipping Phone Number</label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Enter the Shipping Phone Number"
            id="shippingPhone"
            style="margin-left: 9px; width: 103%"
            formControlName="recipent_mobile"
          />
        </div>
      </div>
    </div>
    <div class="" style="display: flex; justify-content: center">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group mb-2">
          <label for="shippingEmail" style="margin-left: 10px; margin-top: 8px"
            >Shipping Email Address</label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Enter the Shipping Email Address"
            id="shippingEmail"
            style="margin-left: 9px; width: 103%"
            formControlName="recipent_email"
          />
        </div>
      </div>
    </div>
    <!-- <div class="" style="display: flex; justify-content: center">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group mb-2">
          <label for="shippingAddress" style="margin-left: 10px; margin-top: 8px"
            >Shipping Address</label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Enter the Shipping Address"
            id="shippingAddress"
            style="margin-left: 9px; width: 103%"
            formControlName="recipent_address"
          />
        </div>
      </div>
    </div> -->
    <div class="" style="display: flex; justify-content: center">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group mb-2">
          <label for="shippingCompanyName" style="margin-left: 10px; margin-top: 8px"
            >Shipping Company Name</label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Enter the Shipping Company Name"
            id="shippingCompanyName"
            name="shippingCompanyName"
            style="margin-left: 9px; width: 103%"
            formControlName="recipent_companyName"
          />
        </div>
      </div>
    </div>
    <div class="" style="display: flex; justify-content: center">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group mb-2">
          <label for="shippingCountry" style="margin-left: 10px; margin-top: 8px"
            >Shipping Country</label
          >
          <select
            class="form-control"
            id="shippingCountry"
            formControlName="recipent_countryID"
            style="margin-left: 9px; width: 103%"
            placeholder="Please Select the Shipping Country"
          >
          <option value="" disabled selected>Select the Country</option>
          <option *ngFor="let data of countries" [value]="data.id">{{ data.country}}</option>
          </select>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center; margin-left: 15px">
      <div class="col-lg-6 col-md-6 col-sm-6 d-flex">
        <div
          class="form-group col-lg-6 col-md-6 col-sm-6 mb-2"
          style="margin-right: 20px"
        >
          <label for="shippingCity">Shipping City</label>
          <input
            type="text"
            class="form-control"
            id="shippingCity"
            placeholder="Enter the Shipping City"
            formControlName="recipent_city"
          />
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
          <label for="shippingState">Shipping State</label>
          <input
            type="text"
            class="form-control"
            id="shippingState"
            placeholder="Enter the Shipping State"
            formControlName="recipent_state"
          />
        </div>
      </div>
    </div>
    <div class="" style="display: flex; justify-content: center">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group mb-2">
          <label for="code" style="margin-left: 10px; margin-top: 8px"
            >Shipping Post Code</label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Enter the Shipping Post Code"
            id="code"
            style="margin-left: 9px; width: 103%"
            formControlName="recipent_zipcode"
          />
        </div>
      </div>
    </div>
  </div>
</form>
