<!-- Container-fluid starts-->

<!-- <button (click)="generatePdfInvoice()">Quototion</button> -->
<div id="quototion" class="quoto" style="display: none; background: white; padding: 20px">
  <img
    src="../../../../assets/images/invoice/final-petals-logo-2-01.jpg"
    style="width: 18%; height: 18%; margin-left: 80%"
    alt=""
  />
  <div class="mt-5">
    <div style="display: flex; justify-content: space-around">
        <div>
            <h3 style="font-style: bold;"><b>To</b></h3><br>
            <h3><b>{{ pdfData?.to_address }}</b></h3>
            <!-- <h3><b>AUTOBONICS PVT.LTD</b></h3><br>
            <h3><b>2nd floor, 46-Venus Plaza,</b></h3><br>
            <h3><b>2381-A, Chakkaraparambu,</b></h3><br>
            <h3><b>Vennala, Errnakulam, Kakkanad,</b></h3><br>
            <h3><b>Kerala-682 028.</b></h3> -->
        </div>
        <div>
            <!-- <h3><b>QUOTATION: #PP42027072</b></h3><br>
            <h3><b>ISSUE DATE: 27.07.2024</b></h3> -->
            <h3><b>QUOTATION: {{ pdfData?.quotation }}</b></h3><br>
            <h3><b>ISSUE DATE: {{ pdfData?.issue_date }}</b></h3>
          </div>
    </div><br><br>
    <div style="margin-left: 12.5%; margin-top: 5%; width: 80%;">
        <h3><b>Dear Sir,</b></h3><br>
        <p style="font-size: 28px;">Greetings From Petals Automation!</p><br>
        <p style="font-size: 28px;">Thank you for your interest in our product. We are pleased to quote as follows. We request
            you to let us know if any clarification regarding this.</p>
        <div>
            <table style="width: 95%">
              <thead>
                <tr>
                  <th style="text-align: center; padding: 1%;"><h3><b>S.no</b></h3></th>
                  <th style="text-align: center; padding: 1%;"><h3><b>Product Description</b></h3></th> 
                  <th style="text-align: center; padding: 1%;"><h3><b>Quantity</b></h3></th>
                  <th style="text-align: center; padding: 1%;"><h3><b>Price</b></h3></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let product of pdfData?.product; let i = index">
                  <td style="text-align: center; padding: 1%;"><h3>{{ i + 1 }}</h3></td>
                  <td style="text-align: center; padding: 1%;"><h3>{{ product?.productName }}</h3></td>
                  <td style="text-align: center; padding: 1%;"><h3>{{ product?.product_quantity }}</h3></td>
                  <td style="text-align: center; padding: 1%;"><h3>&#8377; {{ product?.total_amount }} +GST</h3></td>
                </tr>
                <!-- <tr>
                    <td style="text-align: center; padding: 1%;"><h3>2.</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>Petal IoT Educational Kit</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>1</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>&#8377; 6,499 +GST</h3></td>
                  </tr>
                  <tr>
                    <td style="text-align: center; padding: 1%;"><h3>3.</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>Petal Smart Home Educational Robotic Kit</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>1</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>&#8377; 6,999 +GST</h3></td>
                  </tr>
                  <tr>
                    <td style="text-align: center; padding: 1%;"><h3>4.</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>Petal 3 in 1 Bot Kit</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>1</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>&#8377; 7,999 +GST</h3></td>
                  </tr>
                  <tr>
                    <td style="text-align: center; padding: 1%;"><h3>5.</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>Petal Smart Home Educational Robotic Pro Kit</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>1</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>&#8377; 9,999 +GST</h3></td>
                  </tr>
                  <tr>
                    <td style="text-align: center; padding: 1%;"><h3>6.</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>Petal AI & ML Educational Robotic Kit</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>1</h3></td>
                    <td style="text-align: center; padding: 1%;"><h3>&#8377; 13,999 +GST</h3></td>
                  </tr> -->
              </tbody>
              </table>
        </div><br><br>
        <div>
            <h3><b>**** Included Petal Software and Petal Curriculum, Manual [ Soft Copy].</b></h3><br>
            <h3><b>Terms and Conditions</b></h3><br>
            <ul>
                <h3><li> This quotation is valid for 10 days from the date of issue, after which prices andavailability may change.</li></h3>
                <h3><li> Payment for the robotic kit is required in 100% full and in advance.</li></h3>
                <h3><li> Estimated delivery time: 20-25 working days from order confirmation and initial
                    payment receipt. Notification will be provided for any delays.
                </li></h3>
                <h3><li> Shipping costs are not included in the quotation and will be added to the final invoice.</li></h3>
                <h3><li> Technical support available via email and phone during business hours (Monday to Saturday, 9:30 AM - 4:30PM).</li></h3>
                <h3><li> All intellectual property, including but not limited to the curriculum, training
                    materials and robotic kits, remains the exclusive property of Petals Automation.</li></h3>
                <h3><li> All clerical errors, whether in mathematical computation or otherwise, in quotations, acknowledgement and invoices shall be subjected to correction as soon as the error isnoted.</li></h3>
                <h3><li> By placing an order, the client acknowledges and agrees to these terms and conditions.</li></h3>
            </ul>  
        </div><br><br>
        <div>
            <h3 style="text-align: center;"> Petals Automation, LIG Colony 155, K.K. Nagar, Madurai - 625020,</h3>
        </div>
    </div>
  </div>
</div>
<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="card">
      <div class="card-header">
          <h5>Quototion Details</h5>
      </div>
      <div class="card-body">
          <div class="btn-popup pull-right">
              <a class="btn btn-primary" (click)="onPopupOpen()">Create Quototion</a>
          </div>
          <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
              <div class="table-responsive">
                  <table class="table table-striped">
                      <thead>
                          <tr>
                              <th scope="col">S.No</th>
                              <th scope="col" >Quototion</th>
                              <th scope="col">Date</th>
                              <th scope="col">Address</th>
                              <th scope="col">Product Name</th>
                              <th scope="col">Product Quantity</th>
                              <th scope="col">Product Price</th>
                              <th scope="col"></th>
                              <th scope="col"></th>
                          </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let data of listData">
                          <td style="text-align: start;">{{ data.quotation_id }}</td>
                          <td style="text-align: start;">{{ data.quotation }}</td>
                          <td style="text-align: start;">{{ data.issue_date }}</td>
                          <td style="text-align: start;">{{ data.to_address }}</td>
                          <td style="text-align: start;">
                            <span  *ngFor="let product of data.product">
                              {{ product.productName.split(',') }}<br>
                            </span>
                          </td>
                          <td style="text-align: start;">
                            <span *ngFor="let product of data.product">
                              {{ product.product_quantity.split(',') }}<br>
                            </span>
                          </td>
                          <td style="text-align: start;">
                            <span *ngFor="let product of data.product">
                              {{ product.productPrice.split(',') }}<br>
                            </span>
                          </td>
                          <td>
                            <button class="btn btn-primary" (click)="onEdit(data.quotation_id)" >Edit</button>
                          </td>
                          <td>
                            <button class="btn btn-primary" (click)="onPdf(data.quotation_id)">Quototion</button>
                          </td>
                        </tr>
                      </tbody>
                      
                  </table> 
                  <!-- <div class="d-flex justify-content-center p-2">
                      <ngb-pagination
                        [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                      </ngb-pagination>
                  </div> -->
              </div>
          </div>
      </div>
  </div>
</div>
<!-- Container-fluid Ends-->
<!-- Container-fluid Ends-->
