import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { PurchaseRoutingModule } from "./purchase-routing.module";
import { CreatePurchaseComponent } from "./create-purchase/create-purchase.component";
import { ListPurchaseComponent } from "./list-purchase/list-purchase.component";

@NgModule({

    declarations: [
        CreatePurchaseComponent,
        ListPurchaseComponent
    ],
    imports: [
      CommonModule,
      PurchaseRoutingModule
    ],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ],
  })
  export class PurchaseModule { }