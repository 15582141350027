import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProductService } from 'src/app/shared/service/product.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  public accountForm: UntypedFormGroup;
  public permissionForm: UntypedFormGroup;
  public active = 1;
  types: any;

  constructor(private formBuilder: UntypedFormBuilder, private productService: ProductService, private cdr: ChangeDetectorRef) {
    this.createAccountForm();
    this.createPermissionForm();
  }

  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required],
      user: ['', Validators.required]
    }, { validators: this.passMathValidator() });
  }
  createPermissionForm() {
    this.permissionForm = this.formBuilder.group({
    })
  }

  ngOnInit() {
    this.userType();
  }

  passMathValidator() {
    return (formGroup: FormGroup) => {
      const password = formGroup.get('password');
      const confirmPassword = formGroup.get('confirmPassword');
      if (password && confirmPassword && confirmPassword.errors === null) {
        if (password.value !== confirmPassword.value) {
          confirmPassword.setErrors({ passwordMismatch: true });
        } else {
          confirmPassword.setErrors(null);
        }
      }
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  userType() {
    this.productService.userType().subscribe((data) => {
      this.types = data.data;
    })
  }

  get controls() {
    return this.accountForm.controls;
  }

  onSave() {
    if (this.accountForm?.valid) {
      const form = this.accountForm?.value;
      const formValue = {
        name: form?.name,
        email: form?.email,
        password: form?.password,
        confirmPassword: form?.confirmPassword,
        user_type: form?.user
      }
      this.productService.addUserType(formValue).subscribe((data) => {
        console.log('Data ', data);
        Swal.fire({
          title: 'Invoice Bill!',
          text: `${data.message}`,
          icon: 'success',
          confirmButtonText: 'OK',
          preConfirm: () => {
            this.accountForm?.reset();
          }
        });
      })
    }
  }

}
