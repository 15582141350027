import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { ProductService } from "src/app/shared/service/product.service";
import Swal from "sweetalert2";
import { InvoiceComponent } from "../invoice.component";

@Component({
    selector: 'app-editQuototionPopup',
    templateUrl: './editQuototionPopup.component.html',
    styleUrls: ['./editQuototionPopup.component.scss']
})
export class EditQuototionPopupComponent implements OnInit {
    emptyArray: any;
    dropdownSettings: IDropdownSettings = {};
    @Input() id;
    @Output() quotationEdited = new EventEmitter<void>();
    quotationFormGroup: FormGroup;
    tableArray = [];
    dropdownList = [];
    formattedDate: any;
    dateFormate: any;
    lashFormattedDate: any;
    formValue: any;
    selectedDate: any;
    productDatas: any;
    constructor(private fb: FormBuilder, private productService: ProductService, public activeModal: NgbActiveModal) {}
    ngOnInit(): void {
        this.form();
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'productID',
            textField: 'productName',
            numberField: 'item_quantity',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 2,
            allowSearchFilter: true
        };
        console.log("this.queotoionid ", this.id);
        this.viewData();
    }

    form() {
        this.quotationFormGroup = this.fb.group({
            issue_date: new FormControl("", Validators.required),
            quotation: new FormControl(""),
            product: new FormControl("", Validators.required),
            to_address: new FormControl("", Validators.required),
            quotation_id: new FormControl("")
        })
    }

    get controls() {
        return this.quotationFormGroup.controls;
    }

    viewData() {
        this.productService.viewQuototionData(this.id).subscribe((data) => {
            console.log("View ", data.quotation);
            this.patchValue(data.quotation);
        })
    }

    patchValue(data: any) {
        console.log("Data ", data);
        
        this.quotationFormGroup.patchValue({
            issue_date: data.issue_date,
            quotation: data.quotation,
            to_address: data.to_address,
            product: data.products
        });
        this.productDatas = [...data.products];
        console.log("this.productDatas ", this.productDatas);
        
    }
    onItemSelect(item: any) {
        console.log("Item ", item);
        
        if (!this.productDatas.some(data => data.productID === item.productID)) {
            const selectItem = this.dropdownList.find(data => data.productID === item.productID);
            console.log("selectItem ", selectItem);
            if (selectItem) {
                console.log("selectItem ", selectItem);
                this.productDatas.push(selectItem);
                this.emptyArray = {
                    product: [...this.productDatas]
                };
                console.log("this.emptyArray ", this.emptyArray);
                this.quotationFormGroup.get('product')?.patchValue(this.emptyArray.product); 
            }
        }
    }

    onItemDeSelect(item: any) {
        this.productDatas = this.productDatas.filter(i => i.productID !== item.productID);
        this.emptyArray = {
            product: [...this.productDatas],
        }
        this.productDatas = this.emptyArray.product;
        this.quotationFormGroup.controls.product.patchValue(this.emptyArray.product);
    }
    
    onSelectAll(items: any) {
        // Clear the productDatas array first
        this.productDatas = [];
    
        // Iterate through all selected items
        items.forEach((item: any) => {
            // Check if the item is already in productDatas, if not, add it
            if (!this.productDatas.some(data => data.productID === item.productID)) {
                const selectItem = this.dropdownList.find(data => data.productID === item.productID);
                if (selectItem) {
                    this.productDatas.push(selectItem);
                }
            }
        });
    
        // Update the form control value with the new array
        this.quotationFormGroup.get('product')?.patchValue(this.productDatas);
        console.log("this.productDatas after select all", this.productDatas);
    }
    
    onDeSelectAll(items: any) {
        this.productDatas = [];
        this.quotationFormGroup.get('product')?.patchValue('');
    }

      ngAfterViewInit() {
        if ($.fn.datepicker) {
            const today = new Date();
            ($('#quotationDate') as any).datepicker({
                format: 'yyyy/mm/dd',
                autoclose: true,
                todayHighlight: true,
            });
            ($('#quotationDate') as any).datepicker('setDate', today);
            ($('#quotationDate') as any).val('');
            this.dateFormate = new Intl.DateTimeFormat('en-US').format(today);
            console.log("dateFormate ", this.dateFormate);
            
            // this.quotationFormGroup.get('issue_date').patchValue( this.dateFormate);
            ($('#quotationDate') as any).on('changeDate', (event: any) => {
                this.selectedDate = event.date;
                console.log("this.selectedDate ", this.selectedDate);
                
                const year = this.selectedDate.getFullYear();
                const month = (this.selectedDate.getMonth() + 1).toString().padStart(2, '0');
                const date = (this.selectedDate.getDate()).toString().padStart(2, '0');
                this.lashFormattedDate = `${year}/${month}/${date}`;
                this.quotationFormGroup.get("issue_date").patchValue(this.lashFormattedDate);
                this.formattedDate = `${year}${month}${date}`;
                this.quotationFormGroup.get("quotation").patchValue("#PP" + this.formattedDate);
                // this.quotationFormGroup.get("quotation").disable();
            });
        } else {
            console.error('Bootstrap Datepicker is not available.');
        }
    }
    onProductName() {
        this.productService.getQuotoionProductName().subscribe((data) => {
            console.log("Dta ", data.data);
            this.dropdownList = data.data;
        });
    }

    updateQuotoion() {
       this.quotationFormGroup.get("quotation_id").patchValue(this.id);
    //     this.quotationFormGroup.get("quotation").patchValue("#PP" + this.formattedDate);
    //     this.quotationFormGroup.get('issue_date').patchValue(this.lashFormattedDate);
        this.quotationFormGroup.get('product').patchValue(this.productDatas);
        const body = this.quotationFormGroup.value;
        console.log("Body ", body);
        
       this.productService.editQuototionData(body).subscribe((data) => {
        console.log("Data");
        if (data) {
            Swal.fire({
                title: 'Invoice Bill!',
                text: `${data.message}`,
                icon: 'success',
                confirmButtonText: 'OK',
                preConfirm: () => {
                    // this.quotationFormGroup?.reset();
                    this.quotationEdited.emit();
                    this.activeModal?.close();
                }
            });
            // this.quotationEdited.emit();
            // this.generatePdfInvoice();
        }
       },
       (error) => {
         console.error("Error", error); // Handle error response
       }) 
    }
    
}