import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotComponent } from './forgot/forgot.component';
import { ForgotEmailComponent } from './forgot-email/forgot-email.component';


const routes: Routes = [
  {
    path:'login',
    component:LoginComponent,
  },
  {
    path: 'forgot',
    component: ForgotComponent
  },
  {
    path: 'forgot/email',
    component: ForgotEmailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
