import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/shared/service/product.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrl: './forgot.component.scss'
})
export class ForgotComponent implements OnInit {
  forgotForm!: FormGroup;
  token: any;
  constructor(private fb: FormBuilder, private productService: ProductService, private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form();
    this.route.queryParams.subscribe((data) => {
      console.log("Data ", data);
      this.token = data['token'];
    })
  }
  form() {
    this.forgotForm = this.fb.group({
      password: new FormControl("", [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl("", Validators.required),
    },{ validators: this.passMathValidators() })
  }
  passMathValidators() {
    return((form: FormGroup) => {
      const password = form.get('password');
      const confirmPassword = form.get('confirmPassword');
      if(password && confirmPassword && confirmPassword.errors === null) {
        if(password.value !== confirmPassword.value) {
          confirmPassword.setErrors({ passwordMismatch: true});
        } else {
          confirmPassword.setErrors(null);
        }
      }
    });
  }
  get controls() {
    return this.forgotForm.controls;
  }
  onSubmit() {
    if(this.forgotForm?.valid) {
      const form = this.forgotForm?.value;
      const formValue = {
        password: form?.password
      }
      this.productService.reset(formValue, this.token).subscribe({
        next: (data) => {
        console.log("Dta ",data)
        if(data.success) {
          Swal.fire({
            title: 'Success!',
            text: `${data.message}`,
            icon: 'success',
            confirmButtonText: 'OK',
          });
          this.router.navigate(['/login']);
        } else {
          const errorMessage = data.message || 'An error occurred. Please try again.';
          Swal.fire({
            title: 'Failure',
            text: errorMessage,
            icon: 'warning',
            confirmButtonText: 'OK',
          });
        }
      },
      error: (error) => {
        const errorMessage = error.error?.message || 'An error occurred. Please try again.';
        Swal.fire({
          title: 'Error!',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'OK',
        })
      }
    })
    } else {
      this.forgotForm?.markAllAsTouched();
      this.forgotForm?.updateValueAndValidity();
    }
  }
}
