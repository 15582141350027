import { ChangeDetectorRef, Component, EventEmitter, Input, LOCALE_ID, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ProductService } from "src/app/shared/service/product.service";
import { OrdersComponent } from "../orders/orders.component";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Router } from "@angular/router";
import { DecimalPipe } from "@angular/common";
import Swal from "sweetalert2";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { toWords } from 'number-to-words';
@Component({
    selector: 'app-editorderModelPopup',
    templateUrl: './editorderModelPopup.component.html',
    styleUrls: ['./editorderModelPopup.component.scss'],
    providers: [DecimalPipe,
        { provide: LOCALE_ID, useValue: 'en-US' },
    ],
    encapsulation: ViewEncapsulation.None
})

export class EditOrderModelPopupComponent implements OnInit {
    countries = [
        { id: '1', country: 'India' },
        { id: '2', country: 'USA' },
        { id: '3', country: 'Canada' },
    ];
    @Input() ordersComponent: OrdersComponent;
    @Output() orderEdited = new EventEmitter<void>();
    @Input() orderid;
    discountZeroValue: boolean = false;
    checkGstType: any;
    invoiceNumber: any;
    createCustomerFormGroup: FormGroup;
    checkedDiscount: boolean;
    overallAmountIGST: any;
    emptyArray: any = [];
    differentShipping: any;
    allProducts: any = [];
    cgstValue: any;
    sameAddress: string = 'no';
    discountChecked: boolean = false;
    shippingAmountBoolean = false;
    discount: any;
    cgstRate: number = 9;
    sgstRate: number = 9;
    igstRate: number = 18;
    discountRate: number = 0;
    shipping: any;
    numberToWord: any;
    discount_value: any;
    shipping_Value: any;
    totalAmtGst = 0;
    disCal: any;
    produtsData: any = [];
    gstCal: any;
    gstRadioBtn: boolean = false;
    igstRadioBtn: boolean = false;
    igstCal: any;
    types: any;
    sgstValue: any;
    igstValue: any;
    dropdownList = [];
    invoiceData: any;
    totalSum = 0;
    shippingChargeDeselect = true;
    selectedItems = [];
    dropdownSettings: IDropdownSettings = {};
    tableArray: any = [];
    // productDis: boolean = false;
    totalAmount: any;
    subTotalAmounts: any;
    shippingAmount: any;
    shippingAmountZero = 0;
    gstType: any;
    submitted: boolean = false;
    selectItems: any;
    overallAmount: any;
    overallEmptyArray: any = [];
    shippingValue: any;
    constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private cdn: ChangeDetectorRef,
        private productService: ProductService, private decimalPipe: DecimalPipe, private router: Router) { }
    ngOnInit(): void {
        this.form();
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'productID',
            textField: 'productName',
            numberField: 'item_quantity',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 2,
            allowSearchFilter: true
        };
        console.log("this.orderid ", this.orderid);
        this.viewData();

    }

    gstCalculation(amount: any) {
        this.gstCal = (Math.round(this.totalAmount) * this.cgstRate) / 100;
        this.cgstValue = Math.round(this.gstCal);
        this.sgstValue = Math.round(this.gstCal);
        this.createCustomerFormGroup.get('cgst').patchValue(this.cgstValue);
        this.createCustomerFormGroup.get('sgst').patchValue(this.sgstValue);
        this.createCustomerFormGroup.get('igst').patchValue('0.00');
        this.totalSumAndGst(Math.round(amount));
        return Math.round(this.gstCal);
    }

    igstCalculation(amount: any) {
        console.log("IGST Calucation Function Entered!!!");
        this.igstCal = (Math.round(this.totalAmount) * this.igstRate) / 100;
        this.igstValue = Math.round(this.igstCal);
        this.createCustomerFormGroup.get('cgst').patchValue('0.00');
        this.createCustomerFormGroup.get('sgst').patchValue('0.00');
        this.createCustomerFormGroup.get('igst').patchValue(this.igstValue);
        console.log(" this.igstValue  ",  this.igstValue );
        this.totalSumAndIGst(Math.round(amount));
        return Math.round(this.igstCal);
    }

    ngstCalculation(amount: any) {
        console.log("Amount NGST", amount)
        this.totalSumAndNGst(Math.round(amount));
        return Math.round(this.igstCal);        
    }

    discountCalculation(amount: any) {
        console.log("Amt ", amount);
        this.disCal = (amount * this.discountRate) / 100;
        this.discount = Math.round(this.disCal);
        console.log("this.discount ", this.discount);
        this.subTotalAmount(amount);
        return Math.round(this.disCal);
    }

    totalSumAndGst(totalAmt: any) {
        this.shippingValue = this.shipping_Value !== undefined && this.shipping_Value !== 'undefined' ? parseFloat(this.shipping_Value) : 0;
        console.log("shippingValue ", this.shippingValue);
        this.totalAmtGst = Math.round(this.shippingValue) + totalAmt + this.cgstValue + this.sgstValue;
        this.overallAmount = Math.round(this.totalAmtGst);
        this.numberToWord = toWords(Math.round(this.overallAmount));
        // this.numberToWord = toWords(Math.round(this.overallAmount));
        return Math.round(this.totalAmtGst);
    }
    totalSumAndIGst(totalAmt: any) {
        this.shippingValue = this.shipping_Value !== undefined && this.shipping_Value !== 'undefined' ? parseFloat(this.shipping_Value) : 0;
        this.totalAmtGst = Math.round(this.shippingValue) + totalAmt + this.igstCal;
        this.overallAmount = Math.round(this.totalAmtGst);
        this.overallAmountIGST = Math.round(this.totalAmtGst);
        this.numberToWord = toWords(Math.round(this.overallAmount));
        return Math.round(this.totalAmtGst);
    }

    totalSumAndNGst(totalAmt: any) {
        console.log("totalAmt ", totalAmt);
        this.shippingValue = this.shipping_Value !== undefined && this.shipping_Value !== 'undefined' ? parseFloat(this.shipping_Value) : 0;
        this.totalAmtGst = Math.round(this.shippingValue) + totalAmt;
        console.log(" this.totalAmtGst ",  this.totalAmtGst);
        this.overallAmount = Math.round(this.totalAmtGst);
        this.numberToWord = toWords(Math.round(this.overallAmount));
        console.log("this.numberToWordNGST ", this.numberToWord);
        return Math.round(this.totalAmtGst);
    }
    subTotalAmount(amount: any) {
        this.totalAmount = amount - this.disCal;
        this.subTotalAmounts = Math.round(this.totalAmount);
        console.log("this.checkGstType ", this.checkGstType);
        if (this.checkGstType === 1) {
            this.gstCalculation(this.subTotalAmounts);
        } else if(this.checkGstType === 2){
            this.igstCalculation(this.subTotalAmounts);
        } else {
            this.ngstCalculation(this.subTotalAmounts);
            this.checkGstType = 0;
        }
        return Math.round(this.subTotalAmounts);
    }

    viewData() {
        this.productService.viewOrder(this.orderid).subscribe((data) => {
            if (data.keyword) {
                console.log("DTa ", data.data);
                this.patchValue(data.data);
            }
        })
    }

    patchValue(datas: any) {
        this.createCustomerFormGroup.get('customer_firstName').patchValue(datas.customer_firstName);
        this.createCustomerFormGroup.get('customer_lastName').patchValue(datas.customer_lastName);
        this.createCustomerFormGroup.get('customer_mobile').patchValue(datas.customer_mobile);
        this.createCustomerFormGroup.get('customer_email').patchValue(datas.customer_email);
        // this.createCustomerFormGroup.get('customer_address').patchValue(datas.customer_address);
        // if(datas.customer_countryID == 1) {
        this.createCustomerFormGroup.get('customer_countryID').patchValue(datas.customer_countryID);
        // }
        this.createCustomerFormGroup.get('customer_city').patchValue(datas.customer_city);
        this.createCustomerFormGroup.get('customer_state').patchValue(datas.customer_state);
        this.createCustomerFormGroup.get('customer_zipCode').patchValue(datas.customer_zipCode);
        this.createCustomerFormGroup.get('recipent_first_name').patchValue(datas.recipent_first_name);
        this.createCustomerFormGroup.get('recipent_last_name').patchValue(datas.recipent_last_name);
        this.createCustomerFormGroup.get('recipent_mobile').patchValue(datas.recipent_mobile);
        this.createCustomerFormGroup.get('recipent_email').patchValue(datas.recipent_email);
        // this.createCustomerFormGroup.get('recipent_address').patchValue(datas.recipent_address);
        this.createCustomerFormGroup.get('recipent_city').patchValue(datas.recipent_city);
        this.createCustomerFormGroup.get('recipent_countryID').patchValue(datas.recipent_countryID);
        this.createCustomerFormGroup.get('recipent_state').patchValue(datas.recipent_state);
        this.createCustomerFormGroup.get('recipent_zipcode').patchValue(datas.recipent_zipcode);
        if (datas.customer_type === 1) {
            this.getProduts(datas.customer_type);
            this.createCustomerFormGroup.get('customer_type').patchValue('DEALER');
        } else if (datas.customer_type === 2) {
            this.getProduts(datas.customer_type);
            this.createCustomerFormGroup.get('customer_type').patchValue('SCHOOL');
        } else if (datas.customer_type === 3) {
            this.getProduts(datas.customer_type);
            this.createCustomerFormGroup.get('customer_type').patchValue('AMAZON');
        } else {
            this.getProduts(datas.customer_type);   
            this.createCustomerFormGroup.get('customer_type').patchValue('CUSTOMER');
        }
        console.log("datas.gstType ", datas.gstType);
        this.checkGstType = datas.gstType;
        if (this.checkGstType === 1) {
            this.createCustomerFormGroup.get('gstType').patchValue('GST');
            this.gstRadioBtn = true;
            this.igstRadioBtn = false;
        } else if(this.checkGstType === 2) {
            this.createCustomerFormGroup.get('gstType').patchValue('IGST');
            this.igstRadioBtn = true;
            this.gstRadioBtn = false;
        } else {
            this.createCustomerFormGroup.get('gstType').patchValue('NGST');
            this.igstRadioBtn = false;
            this.gstRadioBtn = false;
            this.checkGstType = 0;
        }
        if (this.gstRadioBtn && datas.cgst && datas.sgst) {
            this.gstRadioBtn = true;
            this.igstRadioBtn = false;
            this.cgstValue = datas.cgst;
            console.log("this.cgstValue ", this.cgstValue);
            this.sgstValue = datas.sgst;
        } else if (this.igstRadioBtn && datas.igst) {
            this.igstRadioBtn = true;
            this.gstRadioBtn = false;
            this.igstValue = datas.igst;
            console.log("this.igstValue ", this.igstValue);
        } 
        if (datas.discount === undefined && datas.discount === 'undefined' && datas.discount === 0.00) {
            this.checkedDiscount = false;
        } else if(datas.discount != undefined && datas.discount != 'undefined' && datas.discount != 0.00) {
            this.discount = datas.discount;
            console.log("this.discount ", this.discount);
            this.checkedDiscount = true;
        }
        // console.log("datas.discountRate ", datas.discountRate);

        if (datas.discountRate === undefined && datas.discountRate === 'undefined' && datas.discountRate === 0.00) {
            this.checkedDiscount = false;
            this.discountRate = datas.discountRate;
            console.log(" this.discountRate ",  this.discountRate);
        } else if(datas.discountRate != undefined && datas.discountRate != 'undefined' && datas.discountRate != 0.00) {
            this.checkedDiscount = true;
            this.discountRate = datas.discountRate;
        }
        this.differentShipping = datas.use_different_shipping;
        console.log("datas.use_different_shipping ", datas.use_different_shipping);
        console.log("FormControl ", this.createCustomerFormGroup);
        if (datas.use_different_shipping === 'yes') {
            this.sameAddress = 'yes';
            // this.createCustomerFormGroup.get('use_different_shipping')?.patchValue('yes');
        } else if(datas.use_different_shipping === 'no') {
            // datas.use_different_shipping = null;
            this.sameAddress = 'no';
            // this.createCustomerFormGroup.get('use_different_shipping')?.patchValue('no');
            // this.createCustomerFormGroup.get('use_different_shipping')?.patchValue('no');
        }
        // this.createCustomerFormGroup.get('cgst').patchValue(datas.cgst);
        // this.createCustomerFormGroup.get('sgst').patchValue(datas.sgst);
        // this.createCustomerFormGroup.get('igst').patchValue(datas.igst);
        this.createCustomerFormGroup.get('order_date').patchValue(datas.order_date);
        this.createCustomerFormGroup.get('invoice_no').patchValue(datas.invoice_no);
        this.createCustomerFormGroup.get('billing_area').patchValue(datas.billing_area);
        this.createCustomerFormGroup.get('door_no').patchValue(datas.door_no);
        this.createCustomerFormGroup.get('billing_street').patchValue(datas.billing_street);
        this.createCustomerFormGroup.get('billing_landMark').patchValue(datas.billing_landMark);
        this.createCustomerFormGroup.get('customer_companyName').patchValue(datas.company_name);
        this.createCustomerFormGroup.get('recipent_companyName').patchValue(datas.recipent_companyName);
        this.createCustomerFormGroup.get('shippingAmount').patchValue(datas.shippingAmount);
        this.createCustomerFormGroup.get('discount').patchValue(datas.discount);
        this.createCustomerFormGroup.get('discountRate').patchValue(datas.discountRate);
        this.createCustomerFormGroup.get('recipent_billing_area').patchValue(datas.recipent_zone);
        this.createCustomerFormGroup.get('recipent_door_no').patchValue(datas.recipent_door_no);
        this.createCustomerFormGroup.get('recipent_billing_street').patchValue(datas.recipent_Avenue);
        this.createCustomerFormGroup.get('recipent_billing_landMark').patchValue(datas.recipent_land_mark);
        this.createCustomerFormGroup.get('use_different_shipping').patchValue(datas.use_different_shipping == 'no' ? false : datas.use_different_shipping);
        if (datas.shippingAmount) {
            this.shipping_Value = datas.shippingAmount;
        }
        if (datas.product.products) {
            this.allProducts = datas.product.products;
        }
        this.invoiceNumber = datas.invoice_no;
        this.overallAmount = datas.product.over_all_amount;
        console.log("this.overallAmount ", this.overallAmount);
        this.numberToWord = toWords(Math.round(this.overallAmount));
        console.log(" this.numberToWord ",  this.numberToWord);
        this.subTotalAmounts = datas.product.sub_total_amount;
        console.log("this.subTotalAmounts ", this.subTotalAmounts)
        // const shippingAmount = parseFloat(this.shippingAmount as any);
        // const overallAmount = parseFloat(this.overallAmount as any);

        // this.totalAmount = shippingAmount + overallAmount;
        this.produtsData = datas.product.products;
        this.createCustomerFormGroup.get('product').patchValue(datas.product.products);
    }

    onCheckbox(event: any) {
        this.checkedDiscount = event.target.checked;
        const discountControl = this.createCustomerFormGroup.get('discountRate');
        // this.createCustomerFormGroup.get('discountRate')?.setValidators(null);
        if (this.checkedDiscount) {
            discountControl?.setValidators([Validators.required]);
        } else {
            discountControl?.clearValidators();
            discountControl?.reset();
            discountControl.patchValue(0);
            this.discountRate = 0;
            console.log("discountControl ", discountControl);
            this.discountCalculation(this.discountCalculationTotalAmount());
            // discountControl?.setValidators(null);
            // this.checkedDiscount = false;
        }
        this.createCustomerFormGroup.get('discountRate').updateValueAndValidity();
    }

    onDiscountAmount(event: any) {
        this.checkedDiscount = true;
        this.discountRate = parseFloat(event.target.value) || 0;
        this.discountCalculation(this.discountCalculationTotalAmount());
    }

    discountCalculationTotalAmount() {
        return this.produtsData.reduce((previousValue, currentValue) => previousValue + parseFloat(currentValue.total_amount), 0);
    }

    onShippingAmount(event: any) {
        // this.shipping = parseFloat(event.target.value) || 0;
        // if (this.shipping === 0) {
        //     this.shippingAmountBoolean = false;
        //     this.shippingAmount = Math.round(this.shipping);
        //     this.totalSumAndGst(parseFloat(this.subTotalAmounts));
        //     console.log(" this.shippingAmount ", this.shippingAmount);
        // } else {
        //     this.shippingAmountBoolean = true;
        //     this.shippingAmount = Math.round(this.shipping);
        // }
        this.shipping = parseFloat(event.target.value) || 0;
        this.shipping_Value  = Math.round(this.shipping);
        this.shippingAmountBoolean = this.shipping !== 0;
        this.discountCalculation(this.discountCalculationTotalAmount());
    }

    onSameAddress(event: any) {
        console.log("Event ", event.target.checked);
        if (event.target.checked === true) {
            console.log("If ", this.createCustomerFormGroup.value);
            this.sameAddress = 'yes';
            this.differentShipping = 'yes';
            this.createCustomerFormGroup.get('recipent_city').reset();
            this.createCustomerFormGroup.get('recipent_countryID').reset();
            this.createCustomerFormGroup.get('recipent_state').reset();
            this.createCustomerFormGroup.get('recipent_zipcode').reset();
            this.createCustomerFormGroup.get('recipent_first_name').reset();
            this.createCustomerFormGroup.get('recipent_last_name').reset();
            this.createCustomerFormGroup.get('recipent_mobile').reset();
            this.createCustomerFormGroup.get('recipent_email').reset();
            // this.createCustomerFormGroup.get('use_different_shipping').patchValue('yes');
        } else {
            console.log("Else ", this.createCustomerFormGroup.value);
            this.sameAddress = 'no';
            this.differentShipping = 'no';
            // this.createCustomerFormGroup.get('use_different_shipping').disable();
            console.log(" this.sameAddress ",  this.sameAddress);
            // this.createCustomerFormGroup.get('use_different_shipping').patchValue('no');
        }
    }

    onCountry(id: any) {
        console.log("ID ", id);

    }

    ngAfterViewInit(): void {
    }
    form() {
        this.createCustomerFormGroup = this.fb.group({
            customer_firstName: new FormControl(""),
            customer_lastName: new FormControl(""),
            customer_mobile: new FormControl(""),
            // Validators.required,
            // Validators.pattern(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
            // customer_email: new FormControl("", [
            //     Validators.required,
            //     Validators.email
            // ]),
            customer_email: new FormControl(""),
            // customer_address: new FormControl(""),
            customer_countryID: new FormControl("", Validators.required),
            customer_city: new FormControl("", Validators.required),
            customer_state: new FormControl("", Validators.required),
            // Validators.required,
            // Validators.pattern(/^\D?(\d{3})\D?\D?(\d{3})/),
            // Validators.maxLength(6) zip code
            customer_zipCode: new FormControl(""),
            recipent_first_name: new FormControl(""),
            recipent_last_name: new FormControl(""),
            recipent_mobile: new FormControl(""),
            recipent_email: new FormControl(""),
            // recipent_address: new FormControl(""),
            recipent_countryID: new FormControl(""),
            recipent_city: new FormControl(""),
            recipent_state: new FormControl(""),
            recipent_zipcode: new FormControl(""),
            customer_type: new FormControl("", Validators.required),
            product: new FormControl("", Validators.required),
            gstType: new FormControl("", Validators.required),
            cgst: new FormControl(""),
            sgst: new FormControl(""),
            igst: new FormControl(""),
            order_date: new FormControl(""),
            invoice_no: new FormControl(""),
            door_no: new FormControl(""),
            billing_area: new FormControl(""),
            billing_street: new FormControl(""),
            billing_landMark: new FormControl(""),
            customer_companyName: new FormControl(""),
            recipent_companyName: new FormControl(""),
            order_id: new FormControl(""),
            discount: new FormControl(""),
            shippingAmount: new FormControl(""),
            use_different_shipping: new FormControl(""),
            // discountRate: new FormControl("", Validators.pattern(/^\D?(\d{3})\D?\D?(\d{3})/))
            discountRate: new FormControl(""),
            recipent_door_no: new FormControl(""),
            recipent_billing_area: new FormControl(""),
            recipent_billing_street: new FormControl(""),
            recipent_billing_landMark: new FormControl(""),
        });
    }
    get controls() {
        return this.createCustomerFormGroup.controls;
    }
    calculateTotalSum() {
        this.totalSum = this.tableArray.reduce((sum, data) => sum + data.total_amount, 0);
        // const totalDis = this.totalSum - this.disCal;
        // console.log("this.totalDis ", totalDis);

        // this.numberToWord = toWords(this.totalSum);
    }
    onIncreaement(item: any) {
        item.product_quantity += 1;
        item.total_amount = item.product_quantity * item.productPrice;
        this.calculateTotalSum();
        let overAllAmount = {
            over_all_amount: Math.round(this.totalAmtGst),
            sub_total_amount: Math.round(this.totalAmount)
        };
        const totalAmountSum = this.allProducts.reduce((sum, data) => {
            const amount = parseFloat(data.total_amount) || 0;
            return sum + amount;
        }, 0);
        this.discountCalculation(totalAmountSum.toFixed(2));
        const updatedProducts = this.allProducts.map(product => {
            if (product.productID === item.productID) {
                return { ...product, product_quantity: item.product_quantity, total_amount: item.total_amount };
            }
            return product;
        });
    
        this.produtsData = updatedProducts;
        this.emptyArray = {
            products: [...this.produtsData],
            ...overAllAmount
        };
        this.createCustomerFormGroup.controls.product.setValue(this.emptyArray.products);
    }
    
    onDecreament(item: any) {
        item.product_quantity -= 1;
        item.total_amount = item.product_quantity * item.productPrice;
        this.calculateTotalSum();
        const totalAmountSum = this.allProducts.reduce((sum, data) => {
            const amount = parseFloat(data.total_amount) || 0;
            return sum + amount;
        }, 0);
        console.log("totalAmountSum ", totalAmountSum);

        this.discountCalculation(totalAmountSum.toFixed(2));
    }
    onItemSelect(item: any) {
        if (!this.produtsData.some(data => data.productID === item.productID)) {
            const selectItem = this.dropdownList.find(data => data.productID === item.productID);
            if (selectItem) {
                this.produtsData.push(selectItem);
            }
        }
        this.shippingChargeDeselect = false;
        this.calculateTotalSum();
        let overAllAmount;
        if (this.totalAmtGst) {
            overAllAmount = {
                over_all_amount: Math.round(this.totalAmtGst),
                sub_total_amount: Math.round(this.totalAmount)
            };
        }
        this.emptyArray = {
            products: [...this.produtsData],
            ...overAllAmount
        };
        this.allProducts = this.emptyArray.products;
        const totalAmountSum = this.allProducts.reduce((sum, data) => {
            const amount = parseFloat(data.total_amount) || 0;
            return sum + amount;
        }, 0);
        this.discountCalculation(totalAmountSum.toFixed(2));
        this.createCustomerFormGroup.controls.product.setValue(this.emptyArray.products);
    }

    onSelectAll(items: any) {
        this.totalSum = 0;
        this.produtsData = items.map((item) => {
            return this.dropdownList.find(data => data.productID === item.productID) || null;
        }).filter(item => item !== null);
        // const totalDataAmount = this.produtsData.reduce((previousValue, currentValue) => previousValue + currentValue.total_amount, 0);
        // this.discountCalculation(totalDataAmount);
        // this.totalSumAndIGst(totalDataAmount);
        // this.totalSumAndGst(totalDataAmount);
        this.calculateTotalSum();
        this.shippingChargeDeselect = false;
        let overAllAmount;
        if (this.totalAmtGst) {
            overAllAmount = {
                over_all_amount: Math.round(this.totalAmtGst),
                sub_total_amount: Math.round(this.totalAmount)
            };
        }
        this.emptyArray = {
            products: [...this.produtsData],
            ...overAllAmount
        };
        console.log(" this.emptyArray ", this.emptyArray);
        this.allProducts = this.emptyArray.products;
        const totalAmountSum = this.allProducts.reduce((sum, data) => {
            const amount = parseFloat(data.total_amount) || 0;
            return sum + amount;
        }, 0);
        this.discountCalculation(totalAmountSum.toFixed(2));
        this.createCustomerFormGroup.controls.product.setValue(this.emptyArray.products);
    }

    onItemDeSelect(item: any) {
        this.produtsData = this.produtsData.filter(i => i.productID !== item.productID);
        this.calculateTotalSum();
        let overAllAmount;
        this.shippingChargeDeselect = false;
        if (this.totalAmtGst) {
            overAllAmount = {
                over_all_amount: Math.round(this.totalAmtGst),
                sub_total_amount: Math.round(this.totalAmount)
            };
        }
        this.emptyArray = {
            products: [...this.produtsData],
            ...overAllAmount
        }
        this.allProducts = this.emptyArray.products;
        const totalAmountSum = this.allProducts.reduce((sum, data) => {
            const amount = parseFloat(data.total_amount) || 0;
            return sum + amount;
        }, 0);
        this.discountCalculation(totalAmountSum.toFixed(2));
        this.createCustomerFormGroup.controls.product.setValue(this.emptyArray.products);
    }

    onDeSelectAll(items: any) {
        this.produtsData = [];
        this.allProducts = [];
        this.emptyArray = [];
        this.discount = 0.00;
        this.subTotalAmounts = 0.00;
        this.overallAmount = 0.00;
        this.overallAmountIGST = 0.00;
        this.createCustomerFormGroup.get('product').patchValue(this.emptyArray);
        console.log(" this.createCustomerFormGroup ",  this.createCustomerFormGroup);
    }
    getFormattedTotalSum(total: any): string {
        return total.toFixed(2);
    }

    getFormattedTotalAmount(price: any): string {
        return price.toFixed(2);
    }
    generatePdfInvoice() {
        const invoiceElement = document.getElementById('invoice');
        if (!invoiceElement) {
            console.error('Invoice element not found!');
            return;
        }
        invoiceElement.style.display = 'block';

        setTimeout(() => {
            html2canvas(invoiceElement, { logging: true, useCORS: true, scale: 2 })
                .then(canvas => {
                    const pdf = new jsPDF({
                        orientation: 'portrait',
                        unit: 'mm',
                        format: 'a4'
                    });
                    const imgData = canvas.toDataURL('image/jpeg');
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const imgProps = pdf.getImageProperties(imgData);
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                    console.log('Image Properties:', imgProps);
                    console.log('PDF Width:', pdfWidth);
                    console.log('Calculated PDF Height:', pdfHeight);

                    pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
                    pdf.save(`PROFORMA INVOICE - ${ this.invoiceNumber }.pdf`);
                    invoiceElement.style.display = 'none';
                })
                .catch(error => {
                    console.error('Error generating PDF:', error);

                    // Ensure the display style is reverted back to none in case of error
                    invoiceElement.style.display = 'none';
                });
        }, 500); // Adjust the delay as necessary
    }
    // orderList() {
    //     if (this.ordersComponent) {
    //         console.log("this.ordersComponent ", this.ordersComponent);

    //         this.ordersComponent.orderList();
    //     }
    // }
    getProduts(type: any) {
        if (type === 1 || type === 2 || type === 3 || type === 4) {
            this.types = type;
            console.log("this.types ", this.types);
            this.productService.getProductName(type).subscribe((data) => {
                if (data.status == 200) {
                    this.dropdownList = data.data;
                } else {
                    console.error("Data is not showing!");
                }
            });
        }
    }
    onRadio(value: any) {
        // this.radioValidation = false;
        // this.productDis = false;
        // this.createCustomerFormGroup.controls.product.patchValue('');
        this.allProducts = [];
        this.produtsData = [];
        this.createCustomerFormGroup.get('product').patchValue("");
        // this.discountZeroValue = true;
        this.discount = 0.00;
        this.subTotalAmounts = 0.00;
        this.cgstValue = 0.00;
        this.sgstValue = 0.00;
        this.igstValue = 0.00;
        this.shippingAmount = 0.00;
        this.overallAmount = 0.00;
        this.overallAmountIGST = 0.00;
        this.tableArray.length = 0;
        if (value === 'DEALER') {
            // this.createCustomerFormGroup.get('customer_type').patchValue(1);
            this.getProduts(1);
        } else if (value === 'SCHOOL') {
            // this.createCustomerFormGroup.get('customer_type').patchValue(2);
            this.getProduts(2);
        } else if (value === 'AMAZON') {
            // this.createCustomerFormGroup.get('customer_type').patchValue(3);
            this.getProduts(3);
        } else {
            // this.createCustomerFormGroup.get('customer_type').patchValue(4);
            this.getProduts(4);
        }
        // this.onDisable();
    }

    onGstRadio(value: any) {
        if (value === "GST") {
            const totalAmountSum = this.allProducts.reduce((sum, data) => {
                const amount = parseFloat(data.total_amount) || 0;
                return sum + amount;
            }, 0);
            this.createCustomerFormGroup.get('gstType').patchValue('GST');
            this.checkGstType = 1;
            if(this.checkGstType = 1) {
                this.discountCalculation(totalAmountSum.toFixed(2));
            }
            this.gstRadioBtn = true;
            this.igstRadioBtn = false;
            
        } else if((value === "IGST")) {
            const totalAmountSum = this.allProducts.reduce((sum, data) => {
                const amount = parseFloat(data.total_amount) || 0;
                return sum + amount;
            }, 0);
            console.log("totalAmountSum ", totalAmountSum);
            this.createCustomerFormGroup.get('gstType').patchValue('IGST');
            this.checkGstType = 2;
            if(this.checkGstType === 2) {
                this.discountCalculation(totalAmountSum.toFixed(2));
            }
            this.igstRadioBtn = true;
            this.gstRadioBtn = false;
        } else {
            const totalAmountSum = this.allProducts.reduce((sum, data) => {
                const amount = parseFloat(data.total_amount) || 0;
                return sum + amount;
            }, 0);
            // if(this.checkGstType === 3) {
                this.discountCalculation(totalAmountSum.toFixed(2));
            // }
            this.checkGstType = 0;
            this.gstRadioBtn = false;
            this.igstRadioBtn = false;
        }
    }
    updateData() {
        let overAllAmount;
        overAllAmount = {
            over_all_amount: this.overallAmount,
            sub_total_amount: this.subTotalAmounts
        };
        this.overallEmptyArray = {
            products: [...this.allProducts],
            ...overAllAmount
        };
        console.log("this.overallEmptyArray ", this.overallEmptyArray);
        this.createCustomerFormGroup.get('order_id').patchValue(this.orderid);
        this.createCustomerFormGroup.get('customer_type').patchValue(this.types);
        this.createCustomerFormGroup.get('gstType').patchValue(this.checkGstType);
        if(this.overallEmptyArray.products.length === 0) {
            this.createCustomerFormGroup.get('product').setValidators(Validators.required);
            this.createCustomerFormGroup.get('product').updateValueAndValidity();
        } else {
            this.createCustomerFormGroup.get('product').patchValue(this.overallEmptyArray);
        }
        this.createCustomerFormGroup.get('discount').patchValue(this.discount);
        console.log("this.sameAddress  ", this.sameAddress );
        // if(this.sameAddress === 'yes') {
        //     this.createCustomerFormGroup.get('use_different_shipping').patchValue('yes');
        // } else {
        //     this.createCustomerFormGroup.get('use_different_shipping').patchValue('no');
        // }
        if(this.sameAddress === 'no') {
            console.log(this.createCustomerFormGroup.value.use_different_shipping);
            if(this.createCustomerFormGroup.value.use_different_shipping === false) {
                this.createCustomerFormGroup.value.use_different_shipping = 'no';
            } else {
                this.createCustomerFormGroup.value.use_different_shipping = 'yes';
            }
            console.log("this.createCustomerFormGroup.value.use_different_shipping ", this.createCustomerFormGroup.value.use_different_shipping);
        } else {
            console.log(this.createCustomerFormGroup.value.use_different_shipping);
            if(this.createCustomerFormGroup.value.use_different_shipping === false) {
                this.createCustomerFormGroup.value.use_different_shipping = 'no';
            } else {
                this.createCustomerFormGroup.value.use_different_shipping = 'yes';
            }
            console.log("this.createCustomerFormGroup.value.use_different_shipping ", this.createCustomerFormGroup.value.use_different_shipping);
        }
        if (this.createCustomerFormGroup.valid) {
            var form = this.createCustomerFormGroup?.value;
            if (this.differentShipping === 'yes') {
                this.invoiceData = {
                    'product': this.overallEmptyArray,
                    'gstType': form?.gstType,
                    'cgst': this.cgstValue,
                    'igst': this.igstValue,
                    'customer_type': form?.customer_type,
                    'order_id': form?.order_id,
                    'use_different_shipping': 'yes',
                    'customer_firstName': form?.customer_firstName,
                    'customer_lastName': form?.customer_lastName,
                    'customer_mobile': form?.customer_mobile,
                    'customer_email': form?.customer_email,
                    // 'customer_address': form?.customer_address,
                    'customer_countryID': form?.customer_countryID,
                    'customer_city': form?.customer_city,
                    'customer_state': form?.customer_state,
                    'customer_zipCode': form?.customer_zipCode,
                    'customer_companyName': form?.customer_companyName,
                    'recipent_companyName': form?.recipent_companyName,
                    'recipent_first_name': form?.recipent_first_name,
                    'recipent_last_name': form?.recipent_last_name,
                    'recipent_mobile': form?.recipent_mobile,
                    'recipent_email': form?.recipent_email,
                    // 'recipent_address': form?.recipent_address,
                    'recipent_countryID': form?.recipent_countryID,
                    'recipent_city': form?.recipent_city,
                    'recipent_state': form?.recipent_state,
                    'recipent_zipcode': form?.recipent_zipcode,
                    'discountRate': this.discountRate,
                    // 'discount': Math.round(this.disCal),
                    'discount':  Math.round(this.discount),
                    'shippingAmount': Math.round(this.shippingAmount),
                    'invoice_no': form?.invoice_no,
                    'order_date': form?.order_date,
                    'door_no': form?.door_no,
                    'billing_area': form?.billing_area,
                    'billing_street': form?.billing_street,
                    'billing_landMark': form?.billing_landMark,
                    'recipent_door_no': form?.recipent_zone,
                    'recipent_billing_area': form?.recipent_billing_area,
                    'recipent_billing_street': form?.recipent_Avenue,
                    'recipent_billing_landMark': form?.recipent_billing_landMark,
                }
            } else {
                this.invoiceData = {
                    'product': this.overallEmptyArray,
                    'gstType': this.checkGstType,
                    'cgst': this.cgstValue,
                    'igst': this.igstValue,
                    'customer_type': this.types,
                    'order_id': this.orderid,
                    'use_different_shipping': 'no',
                    'customer_firstName': form?.customer_firstName,
                    'customer_lastName': form?.customer_lastName,
                    'customer_mobile': form?.customer_mobile,
                    'customer_email': form?.customer_email,
                    // 'customer_address': form?.customer_address,
                    'customer_countryID': form?.customer_countryID,
                    'customer_city': form?.customer_city,
                    'customer_state': form?.customer_state,
                    'customer_zipCode': form?.customer_zipCode,
                    'customer_companyName': form?.customer_companyName,
                    'recipent_companyName': form?.recipent_companyName,
                    'recipent_first_name': form?.recipent_first_name,
                    'recipent_last_name': form?.recipent_last_name,
                    'recipent_mobile': form?.recipent_mobile,
                    'recipent_email': form?.recipent_email,
                    // 'recipent_address': form?.recipent_address,
                    'recipent_countryID': form?.recipent_countryID,
                    'recipent_city': form?.recipent_city,
                    'recipent_state': form?.recipent_state,
                    'recipent_zipcode': form?.recipent_zipcode,
                    'discountRate': this.discountRate,
                    // 'discount': Math.round(this.disCal),
                    'discount':  Math.round(this.discount),
                    'shippingAmount': Math.round(this.shippingAmount),
                    'invoice_no': form?.invoice_no,
                    'order_date': form?.order_date,
                    'door_no': form?.door_no,
                    'billing_area': form?.billing_area,
                    'billing_street': form?.billing_street,
                    'billing_landMark': form?.billing_landMark,
                    'recipent_door_no': form?.recipent_zone,
                    'recipent_billing_area': form?.recipent_billing_area,
                    'recipent_billing_street': form?.recipent_Avenue,
                    'recipent_billing_landMark': form?.recipent_billing_landMark,
                }
            }
            // this.createCustomerFormGroup.get('product').patchValue(this.overallEmptyArray);
            // if (this.gstType == 1) {
            //     this.createCustomerFormGroup.get('gstType').setValue(this.gstType);
            // } else {
            //     this.createCustomerFormGroup.get('gstType').setValue(this.gstType);
            // }
            // this.createCustomerFormGroup.get("cgst").patchValue(Math.round(this.cgstValue));
            // this.createCustomerFormGroup.get("sgst").patchValue(Math.round(this.sgstValue));
            // this.createCustomerFormGroup.get('igst').patchValue(Math.round(this.igstValue));
            // this.createCustomerFormGroup.get('discount_amount').patchValue(Math.round(this.discount));
            // this.createCustomerFormGroup.get('customer_type').patchValue(this.types);
            // this.createCustomerFormGroup.get('order_id').patchValue(this.orderid);
            this.invoiceData = form;
            console.log("this.invoiceData ", this.invoiceData);
            this.calculateTotalSum();
            this.productService.editOrder(this.invoiceData).subscribe((data) => {
                if (data?.keyword === "success") {
                    Swal.fire({
                        title: 'Invoice Bill!',
                        text: `${data.message}`,
                        icon: 'success',
                        confirmButtonText: 'OK',
                        preConfirm: () => {
                            this.createCustomerFormGroup?.reset();
                            // if (this.ordersComponent) {
                            //     console.log("this.ordersComponent ", this.ordersComponent);

                            //     this.ordersComponent.orderList();
                            // } else {
                            //     console.error('OrdersComponent instance is not passed!');
                            // }
                            // this.activeModal?.close();
                            this.orderEdited.emit();
                            this.activeModal?.close();

                        }
                    });
                    this.generatePdfInvoice();
                    // this.generateSampleInvoice();
                    // this.orderList();

                    // this.ordersComponent.orderService.getOrder();
                } else if (data?.keyword === "failed") {
                    Swal.fire({
                        title: 'Error!',
                        text: `${data.message || 'An unexpected error occurred. Please try again later.'}`,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    });
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Received an unexpected response from the server.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    });
                }
            });

        } else {
            console.log("Else Condition Entered!!");
            Swal.fire({
                title: 'Required Field!',
                text: 'Please Check the Mandatory Fields',
                icon: 'warning',
                confirmButtonText: 'OK'
            });
        }
        this.createCustomerFormGroup.markAllAsTouched();
    }
    closeModel() {
        this.activeModal.close();
    }
}