<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-7 p-0 card-right" style="margin-left: 15%;">
                    <div class="card">
                        <div class="card-body">
                            <img src="../../../../assets/images/invoice/final-petals-logo-2-01.jpg" style="width: 50%; height: 50%; display: block; margin-left: auto; margin-right: auto;" alt="">
                            <h3 style="text-align: center;" class="mt-2 mb-3"><b>Forgot Your Password</b></h3>
                            <p><b>Please enter your email address so we can send you an email to reset your password.</b></p>
                            <form [formGroup]="forgotEmail" class="form-horizontal auth-form mt-2">
                                <div class="form-group">
                                    <div class="form-group">
                                        <input name="email" type="text" formControlName="email"
                                            class="form-control" placeholder="Email"
                                            [class.is-invalid]="controls.email.invalid && (controls.email.dirty || controls.email.touched)">
                                            <div *ngIf="controls.email.invalid && (controls.email.dirty || controls.email.touched)" class="invalid-feedback">
                                                <div *ngIf="controls.email.errors?.['required']">
                                                    Please email is Required.
                                                </div>
                                                <div *ngIf="controls.email.errors?.['email']">
                                                    Email must be a valid email address
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div class="form-button">
                                    <a> <button class="btn btn-primary" type="submit" (click)="navigateToForgot()">Submit</button></a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>