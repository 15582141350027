<div class="container-fluid">
  <div class="card">
    <div class="card-header">
      <h5 class="mb-2">Activity Log</h5>
      <form [formGroup]="activityForm">
        <div style="display: flex">
          <div class="form-group col-xl-2 mx-2">
            <label for="activity_date"
              >From Date <span class="redColor">*</span></label
            >
            <input
              type="text"
              id="activity_date"
              name="activity_date"
              formControlName="from_date"
              class="form-control"
              placeholder="Select a Date"
              [class.is-invalid]="controls.from_date.invalid && (controls.from_date.dirty || controls.from_date.touched)"
              autocomplete="off"
            />
            <div *ngIf="controls.from_date.invalid && (controls.from_date.dirty || controls.from_date.touched)" class="invalid-feedback">
              <div *ngIf="controls.from_date.errors?.['required']">
                From Date is required
              </div>
            </div>
          </div>
          <div class="form-group col-xl-2 mx-2">
            <label for="to_date">To Date <span class="redColor">*</span></label>
            <input
              type="text"
              id="to_date"
              name="to_date"
              formControlName="to_date"
              class="form-control"
              placeholder="Select a To Date"
              [class.is-invalid]="controls.to_date.invalid && (controls.to_date.dirty || controls.to_date.touched)"
              autocomplete="off"
            />
            <div *ngIf="controls.to_date.invalid && (controls.to_date.dirty || controls.to_date.touched)" class="invalid-feedback">
              <div *ngIf="controls.to_date.errors?.['required']">
                To Date is required
              </div>
              <div *ngIf="controls.to_date.errors?.['dateRangeInvalid']">
                To Date cannot be earlier than From Date.
              </div>
            </div>
          </div>
          <div class="form-group col-xl-2 mx-3">
            <label for="to_date"
              >Module Type <span class="redColor">*</span></label
            >
            <select
              id="moduleName"
              formControlName="module_name"
              class="form-control"
              [class.is-invalid]="controls.module_name.invalid && (controls.module_name.dirty || controls.module_name.touched)"
            >
              <option value="" disabled selected>Select the Module Type</option>
              <option [value]="data.module_name" *ngFor="let data of modules">
                {{ data.module_name }}
              </option>
            </select>
            <div *ngIf="controls.module_name.invalid && (controls.module_name.dirty || controls.module_name.touched)" class="invalid-feedback">
              <div *ngIf="controls.module_name.errors?.['required']">
                Module Name is required
              </div>
            </div>
          </div>
          <div style="display: flex; align-items: center">
            <div class="form-group">
              <button class="btn btn-primary" (click)="onSearch()">
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="card-body">
      <div
        id="batchDelete"
        class="category-table custom-datatable transcation-datatable user-image"
      >
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Module Name</th>
                <th scope="col">Description</th>
                <th scope="col">Create On Date</th>
                <th scope="col">Activity Type</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of listData; let i = index">
                <td style="text-align: center">{{ i + 1 }}</td>
                <td style="text-align: center">{{ data?.name }}</td>
                <td style="text-align: center">{{ data?.type }}</td>
                <td style="text-align: center">{{ data?.module_name }}</td>
                <td style="text-align: center">{{ data?.description }}</td>
                <td style="text-align: center">{{ data?.created_at | date: 'yyyy-MM-dd, hh:mm a' }}</td>
                <td style="text-align: center;">{{ data?.activity_type }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
