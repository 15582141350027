<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-7 p-0 card-right" style="margin-left: 15%;">
                    <div class="card">
                        <div class="card-body">
                            <img src="../../../../assets/images/invoice/final-petals-logo-2-01.jpg" style="width: 50%; height: 50%; display: block; margin-left: auto; margin-right: auto;" alt="">
                            <h3 style="text-align: center;" class="mt-2 mb-3"><b>Forgot Password</b></h3>
                            <form [formGroup]="forgotForm" class="form-horizontal auth-form mt-2">
                                <div class="form-group">
                                    <div class="form-group">
                                        <input name="password" type="password" formControlName="password"
                                            class="form-control" placeholder="Password"
                                            [class.is-invalid]="controls.password.invalid && (controls.password.dirty || controls.password.touched)">
                                            <div *ngIf="controls.password.invalid && (controls.password.dirty || controls.password.touched)" class="invalid-feedback">
                                                <div *ngIf="controls.password.errors?.['required']">
                                                    Password is Required
                                                </div>
                                                <div *ngIf="controls.password.errors?.['minlength']">
                                                    Password must be at least 8 characters long
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input name="password" type="password" formControlName="confirmPassword"
                                        class="form-control" placeholder="ConfirmPassword"
                                        [class.is-invalid]="controls.confirmPassword.invalid && (controls.confirmPassword.dirty || controls.confirmPassword.touched)">
                                        <div *ngIf="controls.confirmPassword.invalid && (controls.confirmPassword.dirty || controls.confirmPassword.touched)" class="invalid-feedback">
                                            <div *ngIf="controls.confirmPassword.errors?.['required']">
                                                ConfirmPassword is Required
                                            </div>
                                            <div *ngIf="controls.confirmPassword.errors?.['passwordMismatch']">
                                                Password must match
                                            </div>
                                        </div>
                                </div>
                                <div class="form-button">
                                    <a> <button class="btn btn-primary" type="submit" (click)="onSubmit()">Submit</button></a>
                                </div>
                            </form>
                            <!-- <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                                <li [ngbNavItem]="1">
                                  <a ngbNavLink>Login</a>
                                  <ng-template ngbNavContent>
                                    <div class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <form [formGroup]="loginForm" 
                                        class="form-horizontal auth-form" novalidate="">
                                        <div class="form-group">
                                            <input  name="email" type="email" formControlName="email"
                                                class="form-control" placeholder="Username"
                                                id="email">
                                        </div>
                                        <div class="form-group">
                                            <input name="password" type="password" formControlName="password"
                                                class="form-control" placeholder="Password">
                                        </div>
                                        <div class="form-terms">
                                            <div class="custom-control custom-checkbox d-flex align-items-center flex-wrap">
                                                <a href="javascript:void(0)"
                                                    class="btn btn-default forgot-pass ms-auto">Forgot password</a>
                                            </div>
                                        </div>
                                        <div class="form-button">
                                            <a> <button class="btn btn-primary" type="submit" (click)="onLogin()"> Login</button></a>
                                        </div>
                                        <div class="form-footer">
                                            <span>Or Login up with social platforms</span>
                                            <ul class="social">
                                                <li><a class="icon-facebook" href="javascript:void(0)"></a></li>
                                                <li><a class="icon-twitter" href="javascript:void(0)"></a></li>
                                                <li><a class="icon-instagram" href="javascript:void(0)"></a>
                                                </li>
                                                <li><a class="icon-pinterest" href="javascript:void(0)"></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </form>
                                </div>
                                  </ng-template>
                                </li>
                              </ul>
                              <div [ngbNavOutlet]="nav" class="mt-2"></div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>