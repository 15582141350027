import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/shared/service/product.service';
import { productDB } from 'src/app/shared/tables/product-list';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  public product_list = []

  constructor(private proudctListService: ProductService) {
    this.product_list = productDB.product;
  }

  ngOnInit() {
    this.getProductList();
  }

  getProductList() {
    let limt = 10;
    let offset = 10;
    // this.proudctListService.getProductList(limt,offset).subscribe((data) => {
    //   console.log("data", data);
      
    // })
  }


}
