import { AfterViewInit, ChangeDetectorRef, Component, Input, LOCALE_ID, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import { toWords } from 'number-to-words';
import { loaderService } from "src/app/shared/service/loader.service";
import { ProductService } from "src/app/shared/service/product.service";
import { DecimalPipe } from "@angular/common";
import { Router } from "@angular/router";
import { OrdersComponent } from "../orders/orders.component";

// pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
    selector: 'app-createOrderModelPopup',
    templateUrl: './createOrderModelPopup.component.html',
    styleUrls: ['./createOrderModelPopup.component.scss'],
    providers: [DecimalPipe,
        { provide: LOCALE_ID, useValue: 'en-US' },
    ],
    encapsulation: ViewEncapsulation.None
})


export class CreateOrderModelPopupComponent implements OnInit, AfterViewInit {
    @Input() ordersComponent: OrdersComponent;
    countries = [
        { id: '1', country: 'India' },
        { id: '2', country: 'USA' },
        { id: '3', country: 'Canada' },
    ];
    createCustomerFormGroup: FormGroup;
    cgstRate: number = 9;
    sgstRate: number = 9;
    igstRate: number = 18;
    selectItem: any;
    totalAmountAll: any;
    discountRate: number = 0;
    shippingAmountBoolean = false;
    shippingAmount: any;
    sameAddress: boolean = false;
    shipping: any;
    // shippingAmountZero = 0;
    checkedDiscount: boolean = false;
    disCal: any;
    gstCal: any;
    igstCal: any;
    itemTotal: any;
    totalAmount: any;
    dropdownList = [];
    selectedItems = [];
    dropdownSettings: IDropdownSettings = {};
    tableArray: any = [];
    productDis: boolean = true;
    radioValidation: boolean = true;
    gstRadioBtn: boolean = false;
    igstRadioBtn: boolean = false;
    shippingChargeDeselect = false;
    gstType: any;
    submitted: boolean = false;
    selectItems: any;
    invoiceData: any;
    totalSum = 0;
    totalAmtGst = 0;
    numberToWord: any;
    emptyArray: any = [];
    storeBase64Img: string;
    types: any;
    tableReduceData: any;
    constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private cdn: ChangeDetectorRef,
        private productService: ProductService, private decimalPipe: DecimalPipe, private router: Router) { }
    ngOnInit(): void {
        this.form();
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'productID',
            textField: 'productName',
            numberField: 'item_quantity',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 2,
            allowSearchFilter: true
        };
        this.imageShowing();
        // if (this.ordersComponent) {
        //     this.orderList();
        // } else {
        //     console.error('OrdersComponent instance is not passed!');
        // }
    }

    gstCalculation(amount: any) {
        if(this.gstType === 1) {
            this.igstCal = 0;
            if (this.gstRadioBtn) {
                this.gstCal = (Math.round(this.totalAmount) * this.cgstRate) / 100;
                console.log("if ", this.gstCal);
                return Math.round(this.gstCal);
            } else {
                console.log("else ", this.gstCal);
                this.gstCal = 0;
            }
        } else {
            this.gstCal = 0;
        }
    }

    igstCalculation(amount: any) {
        if(this.gstType === 2) {
            this.gstCal = 0;
            if (this.igstRadioBtn) {
                this.igstCal = (Math.round(this.totalAmount) * this.igstRate) / 100;
                console.log("if I", this.igstCal);
                return Math.round(this.igstCal);
            } else {
                console.log("if I2", this.igstCal);
                this.igstCal = 0;
            }
        } else {
            this.igstCal = 0;   
        }
    }

    discountCalculation(amount: any) {
        this.disCal = (amount * this.discountRate) / 100;
        if (this.checkedDiscount === false) {
            this.subTotalAmount(amount);
        } else {
            this.subTotalAmount(amount);
        }
        return Math.round(this.disCal);
    }

    // totalSumAndGst(totalAmt: any) {
    //     if(this.checkedDiscount === false) {
    //         this.totalAmtGst = Math.round(this.totalAmount) +  ((Math.round(this.totalAmount) * this.cgstRate) / 100 + (Math.round(this.totalAmount) * this.cgstRate) / 100);
    //         this.numberToWord = toWords(Math.round(this.totalAmtGst));
    //         return Math.round(this.totalAmtGst);
    //     }
    //     console.log("this.shipping ",this.shipping );

    //     if(this.checkedDiscount && this.shipping === 0) {
    //         console.log("checkedDiscount Entered1!");
    //         this.totalAmtGst = Math.round(this.totalAmount) + ((Math.round(this.totalAmount) * this.cgstRate) / 100 + (Math.round(this.totalAmount) * this.cgstRate) / 100);
    //         console.log("this.totalAmtGst0 ", this.totalAmtGst);
    //         this.totalAmtGst =  this.totalAmtGst - Math.round(this.shippingAmount);
    //         console.log("this.totalAmtGst1 ", this.totalAmtGst);
    //         this.numberToWord = toWords(Math.round(this.totalAmtGst));
    //         return Math.round(this.totalAmtGst); 
    //     } else {
    //         console.log("checkedDiscount Entered2!");
    //         this.totalAmtGst = Math.round(this.totalAmount) + Math.round(this.shippingAmount) + ((Math.round(this.totalAmount) * this.cgstRate) / 100 + (Math.round(this.totalAmount) * this.cgstRate) / 100);
    //         console.log("this.totalAmtGst1 ", this.totalAmtGst)
    //         this.numberToWord = toWords(Math.round(this.totalAmtGst));
    //         return Math.round(this.totalAmtGst); 
    //     }
    // }

    totalSumAndGst(totalAmt: any) {
        let shippingAmount = this.shippingAmount || 0;
        let discountAmount = 0;
        if (this.checkedDiscount && this.createCustomerFormGroup.get('discount')?.valid) {
            discountAmount = Math.round(this.createCustomerFormGroup.get('discount')?.value || 0);
        }
        let totalAmountAfterDiscount = Math.round(this.totalAmount);
        if(shippingAmount === 0) {
            this.totalAmtGst = Math.round(totalAmountAfterDiscount) - Math.round(shippingAmount) + Math.round((totalAmountAfterDiscount * this.igstRate) / 100);
            this.numberToWord = toWords(Math.round(this.totalAmtGst));
            return Math.round(this.totalAmtGst);
        } else if(this.gstRadioBtn === true && this.gstType === 1) {
            this.totalAmtGst =  Math.round(totalAmountAfterDiscount)  + Math.round(shippingAmount) + Math.round((totalAmountAfterDiscount * this.igstRate) / 100);
            console.log("this.totalAmtGst1 ", Math.round(this.totalAmtGst));
            this.numberToWord = toWords(Math.round(this.totalAmtGst));
            return Math.round(this.totalAmtGst);
        } else if(this.gstRadioBtn === false && this.gstType === 1) {
            this.totalAmtGst = Math.round(totalAmountAfterDiscount) + Math.round(shippingAmount);
            console.log("this.totalAmtGst2 ", Math.round(this.totalAmtGst));
            this.numberToWord = toWords(Math.round(this.totalAmtGst));
            return Math.round(this.totalAmtGst);
        } else {
            this.totalAmtGst;
            return Math.round(this.totalAmtGst);
        }

    }

    totalSumandNgst(totalAmt: any) {
        let shippingAmount = this.shippingAmount || 0;
        let discountAmount = 0;
        if (this.checkedDiscount && this.createCustomerFormGroup.get('discount')?.valid) {
            discountAmount = Math.round(this.createCustomerFormGroup.get('discount')?.value || 0);
        }
        let totalAmountAfterDiscount = Math.round(this.totalAmount);
        if(shippingAmount === 0) {
            this.totalAmtGst = Math.round(totalAmountAfterDiscount) - Math.round(shippingAmount);
            this.numberToWord = toWords(Math.round(this.totalAmtGst));
            return Math.round(this.totalAmtGst);
        } else if(this.gstRadioBtn === false && this.igstRadioBtn === false) {
            this.totalAmtGst = Math.round(totalAmountAfterDiscount) + Math.round(shippingAmount);
            this.numberToWord = toWords(Math.round(this.totalAmtGst));
            return Math.round(this.totalAmtGst);
        } else {
            this.totalAmtGst;
            return Math.round(this.totalAmtGst);
        }
    }


    totalSumAndIGst(totalAmt: any) {
        let shippingAmount = this.shippingAmount || 0;
        let discountAmount = 0;
        if (this.checkedDiscount && this.createCustomerFormGroup.get('discount')?.valid) {
            discountAmount = Math.round(this.createCustomerFormGroup.get('discount')?.value || 0);
        }
        let totalAmountAfterDiscount = Math.round(this.totalAmount);
        if(shippingAmount === 0) {
            this.totalAmtGst = Math.round(totalAmountAfterDiscount) - Math.round(shippingAmount) + Math.round((totalAmountAfterDiscount * this.igstRate) / 100);
            this.numberToWord = toWords(Math.round(this.totalAmtGst));
            return Math.round(this.totalAmtGst);
        } else if(this.igstRadioBtn === true && this.gstType === 2) {
            this.totalAmtGst =  Math.round(totalAmountAfterDiscount)  + Math.round(shippingAmount) + Math.round((totalAmountAfterDiscount * this.igstRate) / 100);
            console.log("this.totalAmtIGST1 ", Math.round(this.totalAmtGst));
            this.numberToWord = toWords(Math.round(this.totalAmtGst));
            return Math.round(this.totalAmtGst);
        } else if(this.igstRadioBtn === false && this.gstType === 2) {
            this.totalAmtGst = Math.round(totalAmountAfterDiscount) + Math.round(shippingAmount);
            console.log("this.totalAmtIGST1 ", Math.round(this.totalAmtGst));
            this.numberToWord = toWords(Math.round(this.totalAmtGst));
            return Math.round(this.totalAmtGst);
        } else {
            this.totalAmtGst;
            return Math.round(this.totalAmtGst);
        }
    }

    subTotalAmount(amount: any) {
        if (this.checkedDiscount === false) {
            this.totalAmount = amount;
            return Math.round(this.totalAmount);
        } else {
            this.totalAmount = amount - this.disCal;
            return Math.round(this.totalAmount);
        }
    }

    orderList() {
        if (this.ordersComponent) {
            this.ordersComponent.orderList(); // Ensure getOrderList is defined and callable
        }
    }

    ngAfterViewInit(): void {
    }

    getProduts(type: any) {
        if (type === 1 || type === 2 || type === 3 || type === 4) {
            this.types = type;
            this.productService.getProductName(type).subscribe((data) => {
                if (data.status == 200) {
                    this.dropdownList = data.data;
                } else {
                    console.error("Data is not showing!");
                }
            });
        }
    }

    // totalAmountAll(totalAmount: any) {

    // }
    
    onItemSelect(item: any) {
        if (!this.tableArray.some(data => data.productID === item.productID)) {
            this.selectItem = this.dropdownList.find(data => data.productID === item.productID);
            if (this.selectItem) {
                this.tableArray.push(this.selectItem);
            }
            const totalAmount = this.tableArray.reduce((previousValue, currentValue) => previousValue + currentValue.total_amount, 0);
            this.discountCalculation(totalAmount);
            if(this.gstType === 1) {
                this.totalSumAndGst(totalAmount);
            } else if( this.gstType === 2) {
                this.totalSumAndIGst(totalAmount);
            } else {
                this.totalSumandNgst(totalAmount);
            }
        }
        this.shippingChargeDeselect = false;
        this.calculateTotalSum();
        // totalSumAndGst(totalAmt: any)
        let overAllAmount;

        // if (this.totalAmtGst !== 0) {
        //     overAllAmount = {
        //         over_all_amount: Math.round(this.totalAmtGst),
        //         sub_total_amount: Math.round(this.totalAmount)
        //     };
        // } else {
        //     overAllAmount = {
        //         over_all_amount: Math.round(this.totalAmtGst),
        //         sub_total_amount: Math.round(this.totalAmount)
        //     };
        // }
        if (this.totalAmtGst) {
            overAllAmount = {
                over_all_amount: Math.round(this.totalAmtGst),
                sub_total_amount: Math.round(this.totalAmount)
            };
        }
        this.emptyArray = {
            products: [...this.tableArray],
            ...overAllAmount
        }
        this.createCustomerFormGroup.controls.product.setValue(this.emptyArray.products);
    }
    onSelectAll(items: any) {
        this.totalSum = 0;
        this.tableArray = items.map((item) => {
            return this.dropdownList.find(data => data.productID === item.productID) || null;
        }).filter(item => item !== null);
        const totalDataAmount = this.dropdownList.reduce((previousValue, currentValue) => previousValue + currentValue.total_amount, 0);
        this.discountCalculation(totalDataAmount);
        if(this.gstType === 1) {
            this.totalSumAndGst(totalDataAmount);
        } else if(this.gstType === 2) {
            this.totalSumAndIGst(totalDataAmount);
        } else {
            this.totalSumandNgst(totalDataAmount);
        }
        this.calculateTotalSum();
        this.shippingChargeDeselect = false;
        let overAllAmount;
        if (this.totalAmtGst) {
            overAllAmount = {
                over_all_amount: Math.round(this.totalAmtGst),
                sub_total_amount: Math.round(this.totalAmount)
            };
        }
        this.emptyArray = {
            products: [...this.tableArray],
            ...overAllAmount
        };
        this.createCustomerFormGroup.controls.product.setValue(this.emptyArray.products);
    }

    onItemDeSelect(item: any) {
        this.tableArray = this.tableArray.filter(i => i.productID !== item.productID);
        this.calculateTotalSum();
        let overAllAmount;
        this.shippingChargeDeselect = false;
        if (this.totalAmtGst) {
            overAllAmount = {
                over_all_amount: Math.round(this.totalAmtGst),
                sub_total_amount: Math.round(this.totalAmount)
            };
        }
        this.emptyArray = {
            products: [...this.tableArray],
            ...overAllAmount
        }
    }

    onDeSelectAll(items: any) {
        this.tableArray = [];
        this.emptyArray = [];

    }

    onSameAddress(event: any) {
        if (event.target.checked === true) {
            this.sameAddress = event.target.checked;
        } else {
            this.sameAddress = event.target.checked;
        }
    }
    getFormattedTotalSum(total: any): string {
        return total.toFixed(2);
    }

    getFormattedTotalAmount(price: any): string {
        return price.toFixed(2);
    }
    onIncreaement(item: any) {
        item.product_quantity += 1;
        item.total_amount = item.product_quantity * item.productPrice;
        this.calculateTotalSum();
        let overAllAmount = {
            over_all_amount: this.totalSum
        };

        this.emptyArray = {
            products: [...this.tableArray],
            ...overAllAmount
        };
        this.createCustomerFormGroup.controls.product.setValue(this.emptyArray.products);
    }
    onDecreament(item: any) {
        item.product_quantity -= 1;
        item.total_amount = item.product_quantity * item.productPrice;
        this.calculateTotalSum();
    }
    calculateTotalSum() {
        this.totalSum = this.tableArray.reduce((sum, data) => sum + data.total_amount, 0);
        // const totalDis = this.totalSum - this.disCal;
        // console.log("this.totalDis ", totalDis);

        this.numberToWord = toWords(this.totalSum);
    }
    onDisable() {
        if (this.productDis && this.radioValidation) {
            Swal.fire({
                title: 'Required Field!',
                text: 'Please Select the DEALER / SCHOOL / AMAZON / CUSTOMER',
                icon: 'warning',
                confirmButtonText: 'OK'
            });
        }
    }
    imageShowing() {
        this.productService.convertImageToBase64('assets/images/invoice/final-petals-logo-2-01.jpg', (base64Image) => {
            this.storeBase64Img = base64Image;
        });
    }

    toTitleCase(str) {
        return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    generateInvoice() {
        const documentDefinition = {
            styles: {
                defaultStyle: {
                    font: 'Times-Roman'
                }
            },
            content: [
                {
                    text: 'INVOICE',
                    fontSize: 18,
                    bold: true,
                    alignment: 'center',
                    marginBottom: 5
                },
                {
                    table: {
                        widths: ['40%', '*'],
                        body: [
                            [
                                {
                                    stack: [
                                        { text: 'PETALS AUTOMATIONS', bold: true, fillColor: '#EEEEEE', fontSize: 12, color: 'black' },
                                        { text: 'No: 155, LIG Colony,', fontSize: 12, color: 'black' },
                                        { text: 'K K Nagar, Madurai,', fontSize: 12, color: 'black' },
                                        { text: 'Tamil Nadu - 625020.', fontSize: 12, color: 'black' },
                                        { text: 'Contact No: +91 9360132690', fontSize: 12, color: 'black' },
                                        { text: 'GST No: 33BLHPC2236H1ZL', fontSize: 12, color: 'black' }
                                    ],
                                    margin: [0, 5],
                                    width: '*'
                                },
                                {
                                    image: this.storeBase64Img, // Ensure this contains a valid base64 image
                                    width: 150,
                                    alignment: 'center'
                                }
                            ],
                            [
                                {
                                    stack: [
                                        { text: `BILL TO:\n`, bold: true, color: 'black', fontSize: 15 },
                                        { text: `${this.invoiceData?.customer_firstName} ${this.invoiceData?.customer_lastName}\n`, color: 'black' },
                                        { text: `${this.invoiceData?.customer_address?.slice(0, 55)},\n`, color: 'black' },
                                        { text: `${this.invoiceData?.customer_city}, \n`, color: 'black' },
                                        { text: `${this.invoiceData?.customer_state} - ${this.invoiceData?.customer_zipCode}.\n`, color: 'black' },
                                        { text: `Contact No: ${this.invoiceData?.customer_mobile}\n`, color: 'black' }
                                    ],
                                    margin: [0, 5],
                                    width: '*'
                                },
                                {
                                    stack: [
                                        { text: 'DATE: 08/3/2024', alignment: 'right', fontSize: 12, color: 'black' },
                                        { text: 'Invoice No: TPA040', alignment: 'right', fontSize: 12, color: 'black' }
                                    ],
                                    margin: [0, 5],
                                    width: '*'
                                },
                            ]
                        ]
                    },
                    width: '100%'
                },
                {
                    table: {
                        widths: ['7%', '34%', '15%', '14%', '15%', '15%'],
                        body: [
                            [
                                { text: 'SNO.', fillColor: 'mistyrose', bold: true, alignment: 'center', color: 'black' },
                                { text: 'DESCRIPTION', fillColor: 'mistyrose', bold: true, alignment: 'center', color: 'black' },
                                { text: 'HSN/SAC', fillColor: 'mistyrose', bold: true, alignment: 'center', color: 'black' },
                                { text: 'QUANTITY', fillColor: 'mistyrose', bold: true, alignment: 'center', color: 'black' },
                                { text: 'UNIT PRICE', fillColor: 'mistyrose', bold: true, alignment: 'center', color: 'black' },
                                { text: 'TOTAL', fillColor: 'mistyrose', bold: true, alignment: 'center', color: 'black' }
                            ],
                            // Populate rows from invoiceData.productName
                            ...this.invoiceData.product.products.map((data, index) => [
                                { text: (index + 1).toString(), alignment: 'center', color: 'black' },
                                { text: data.productName, color: 'black' },
                                { text: '95030090', alignment: 'center', color: 'black' },
                                { text: data.product_quantity, alignment: 'center', color: 'black' },
                                { text: data.productPrice.toString(), alignment: 'center', color: 'black' },
                                { text: this.getFormattedTotalAmount(data.total_amount), alignment: 'center', color: 'black' }
                            ]),
                            [
                                { text: '', colSpan: 4 },
                                {},
                                {},
                                {},
                                { text: 'TOTAL', fillColor: 'mistyrose', bold: true, alignment: 'center', color: 'black' },
                                { text: this.getFormattedTotalSum(this.totalSum), alignment: 'center', color: 'black' }
                            ]
                        ]
                    },
                    width: '100%'
                },
                {
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                {
                                    table: {
                                        widths: ['35%', '*'],
                                        body: [
                                            [
                                                {
                                                    text: 'AMOUNT IN WORDS',
                                                    bold: true,
                                                    fillColor: 'mistyrose',
                                                    color: 'black',
                                                    border: [false, false, false, false],
                                                    margin: [5, 5]
                                                },
                                                {
                                                    text: this.toTitleCase(this.numberToWord) + ' Only.',
                                                    color: 'black',
                                                    border: [false, false, false, false],
                                                    margin: [5, 5]
                                                }
                                            ]
                                        ]
                                    },
                                }
                            ],
                            [
                                {
                                    text: '',
                                    border: [true, true, true, true],
                                    layout: {
                                        hLineWidth: () => 0,
                                        vLineWidth: () => 0
                                    },
                                    margin: [0, 0, 0, 150],
                                }
                            ],
                            [
                                {
                                    text: 'THANK YOU FOR YOUR BUSINESS',
                                    fontSize: 15,
                                    bold: true,
                                    alignment: 'center',
                                    color: 'black',
                                    margin: [0, 10, 0, 0],
                                    border: [false, false, false, false]
                                }
                            ]
                        ]
                    },
                }

            ],
            pageMargins: [40, 60, 40, 60],
            compress: true
        };
        pdfMake.createPdf(documentDefinition).getBlob((pdfBlob) => {
            if (pdfBlob.size === 0) {
                console.error('PDF Blob is empty.');
                return;
            }
            const formData = new FormData();
            formData.append('invoice', pdfBlob, 'invoice.pdf');
            formData.append('to_email', this.createCustomerFormGroup.controls.customer_email.value);
            const formDataEntries = (formData as any).entries();
            for (let [key, value] of formDataEntries) {
                console.log(`${key}:`, value);
            }
            fetch('http://192.168.1.75:8000/api/V1/GP/send-Invoice', {
                method: 'POST',
                body: formData,
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.text();
                })
                .then(data => console.log('Success:', data))
                .catch(error => console.error('Error:', error));
        });
    }


    onRadio(value: any) {
        this.radioValidation = false;
        this.productDis = false;
        this.createCustomerFormGroup.controls.product.patchValue('');
        this.tableArray.length = 0;
        if (value === 'DEALER') {
            // this.createCustomerFormGroup.get('customer_type').patchValue(1);
            this.getProduts(1);
        } else if (value === 'SCHOOL') {
            // this.createCustomerFormGroup.get('customer_type').patchValue(2);
            this.getProduts(2);
        } else if (value === 'AMAZON') {
            // this.createCustomerFormGroup.get('customer_type').patchValue(3);
            this.getProduts(3);
        } else {
            // this.createCustomerFormGroup.get('customer_type').patchValue(4);
            this.getProduts(4);
        }
        this.onDisable();
    }

    onGstRadio(value: any) {
        if (value === "GST") {
            this.gstRadioBtn = true;
            this.igstRadioBtn = false;
            this.gstType = 1;
        } else if(value === "IGST") {
            this.igstRadioBtn = true;
            this.gstRadioBtn = false;
            this.gstType = 2;
        } else {
            this.igstRadioBtn = false;
            this.gstRadioBtn = false;
            this.gstType = 0;
        }
    }
    form() {
        // Validators.pattern(/^\D?(\d{3})\D?\D?(\d{3})/)
        // Validators.pattern(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/) -- Mobile
        this.createCustomerFormGroup = this.fb.group({
            customer_firstName: new FormControl(""),
            customer_lastName: new FormControl(""),
            customer_mobile: new FormControl(""),
            // customer_email: new FormControl("", [
            //     Validators.required,
            //     Validators.email
            // ]),
            customer_email: new FormControl(""),
            // customer_address: new FormControl(""),
            customer_countryID: new FormControl("", Validators.required),
            customer_city: new FormControl("", Validators.required),
            customer_state: new FormControl("", Validators.required),
            customer_zipCode: new FormControl("", [
                Validators.required,
                // Validators.pattern(/^\D?(\d{3})\D?\D?(\d{3})/),
                // Validators.maxLength(6)
            ]),
            recipent_first_name: new FormControl(""),
            recipent_last_name: new FormControl(""),
            recipent_mobile: new FormControl("", [
                // Validators.required,
                // Validators.pattern(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
            ]),
            recipent_email: new FormControl(""),
            // recipent_address: new FormControl(""),
            recipent_countryID: new FormControl(""),
            recipent_city: new FormControl(""),
            recipent_state: new FormControl(""),
            recipent_zipcode: new FormControl("", [
                // Validators.required,
                // Validators.pattern(/^\D?(\d{3})\D?\D?(\d{3})/),
                // Validators.maxLength(6)
            ]),
            customer_type: new FormControl("", Validators.required),
            product: new FormControl("", Validators.required),
            gstType: new FormControl(""),
            cgst: new FormControl(""),
            sgst: new FormControl(""),
            igst: new FormControl(""),
            order_date: new FormControl("", Validators.required),
            invoice_no: new FormControl("", Validators.required),
            door_no: new FormControl(""),
            billing_area: new FormControl(""),
            billing_street: new FormControl(""),
            billing_landMark: new FormControl(""),
            recipent_door_no: new FormControl(""),
            recipent_billing_area: new FormControl(""),
            recipent_billing_street: new FormControl(""),
            recipent_billing_landMark: new FormControl(""),
            customer_companyName: new FormControl(""),
            recipent_companyName: new FormControl(""),
            discount_amount: new FormControl(""),
            discount: new FormControl(""),
            shippingAmount: new FormControl(""),
            use_different_shipping: new FormControl(""),
            discountRate: new FormControl("")
        });
    }
    onCheckbox(event: any) {
        this.checkedDiscount = event.target.checked;
        console.log(" this.checkedDiscount ",  this.checkedDiscount);
        if (this.checkedDiscount) {
            this.createCustomerFormGroup.get('discount')?.setValidators([Validators.required]);
        } else {
            this.createCustomerFormGroup.get('discount')?.clearValidators();
            this.createCustomerFormGroup.get('discount')?.reset();
            this.createCustomerFormGroup.get('discountRate')?.reset();
            this.createCustomerFormGroup.get('discount').patchValue(0);
            this.createCustomerFormGroup.get('discountRate').patchValue(0);
            this.discountRate = 0;
            this.disCal = 0;
            console.log("this.createCustomerFormGroup" , this.createCustomerFormGroup);
        }
        this.createCustomerFormGroup.get('discount').updateValueAndValidity();
    }

    onShippingAmount(event: any) {
        this.shipping = parseFloat(event.target.value) || 0;
        if (this.shipping === 0) {
            this.shippingAmountBoolean = false;
            this.shippingAmount = Math.round(this.shipping);
            console.log(" this.shippingAmount ", this.shippingAmount);

        } else {
            this.shippingAmountBoolean = true;
            this.shippingAmount = Math.round(this.shipping);
        }
    }

    onDiscountAmount(event: any) {
        if(event.target.value) {
            this.discountRate = event.target.value;
        } else {
            this.discountRate = 0;
        }
    }

    get controls() {
        return this.createCustomerFormGroup.controls;
    }

    closeModel() {
        this.activeModal.close();
    }

    onCountry(id: any) {
        console.log("ID ", id);

    }

    generatePdfInvoice() {
        const invoiceElement = document.getElementById('invoice');
        if (!invoiceElement) {
            console.error('Invoice element not found!');
            return;
        }
        invoiceElement.style.display = 'block';

        setTimeout(() => {
            html2canvas(invoiceElement, { logging: true, useCORS: true, scale: 2 })
                .then(canvas => {
                    const pdf = new jsPDF({
                        orientation: 'portrait',
                        unit: 'mm',
                        format: 'a4'
                    });
                    const imgData = canvas.toDataURL('image/jpeg');
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const imgProps = pdf.getImageProperties(imgData);
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                    console.log('Image Properties:', imgProps);
                    console.log('PDF Width:', pdfWidth);
                    console.log('Calculated PDF Height:', pdfHeight);

                    pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
                    pdf.save(`PROFORMA INVOICE - ${ this.invoiceData?.invoice_no }.pdf`);
                    invoiceElement.style.display = 'none';
                })
                .catch(error => {
                    console.error('Error generating PDF:', error);

                    // Ensure the display style is reverted back to none in case of error
                    invoiceElement.style.display = 'none';
                });
        }, 500); // Adjust the delay as necessary
    }

    generateSampleInvoice() {
        const invoiceElement = document.getElementById('sampleInvoice');
        if (!invoiceElement) {
            console.error('Invoice element not found!');
            return;
        }
        invoiceElement.style.display = 'block';

        setTimeout(() => {
            html2canvas(invoiceElement, { logging: true, useCORS: true, scale: 2 })
                .then(canvas => {
                    const pdf = new jsPDF({
                        orientation: 'portrait',
                        unit: 'mm',
                        format: 'a4'
                    });
                    const imgData = canvas.toDataURL('image/jpeg');
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const imgProps = pdf.getImageProperties(imgData);
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                    console.log('Image Properties:', imgProps);
                    console.log('PDF Width:', pdfWidth);
                    console.log('Calculated PDF Height:', pdfHeight);

                    pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
                    pdf.save('INVOICE.pdf');
                    invoiceElement.style.display = 'none';
                })
                .catch(error => {
                    console.error('Error generating PDF:', error);

                    // Ensure the display style is reverted back to none in case of error
                    invoiceElement.style.display = 'none';
                });
        }, 500);
    }

    saveData() {
        console.log("this.createCustomerFormGroup ", this.createCustomerFormGroup);
        this.submitted = true;
        if (this.createCustomerFormGroup.valid) {
            var form = this.createCustomerFormGroup?.value;
            if (this.sameAddress === false) {
                this.invoiceData = {
                    use_different_shipping: 'no',
                    customer_firstName: form?.customer_firstName,
                    customer_lastName: form?.customer_lastName,
                    customer_mobile: form?.customer_mobile,
                    customer_email: form?.customer_email,
                    // customer_address: form?.customer_address,
                    customer_countryID: form?.customer_countryID,
                    customer_city: form?.customer_city,
                    customer_state: form?.customer_state,
                    customer_zipCode: form?.customer_zipCode,
                    recipent_first_name: form?.customer_firstName,
                    recipent_last_name: form?.customer_lastName,
                    recipent_mobile: form?.customer_mobile,
                    recipent_email: form?.customer_email,
                    // recipent_address: form?.customer_address,
                    recipent_countryID: form?.customer_countryID,
                    recipent_city: form?.customer_city,
                    recipent_state: form?.customer_state,
                    recipent_zipcode: form?.customer_zipCode,
                    cgst: Math.round(this.gstCal),
                    sgst: Math.round(this.gstCal),
                    igst: Math.round(this.igstCal),
                    discount: Math.round(this.disCal),
                    shippingAmount: Math.round(this.shippingAmount),
                    customer_type: this.types,
                    product: this.emptyArray,
                    gstType: this.gstType,
                    invoice_no: form?.invoice_no,
                    order_date: form?.order_date,
                    door_no: form?.door_no,
                    billing_area: form?.billing_area,
                    billing_street: form?.billing_street,
                    billing_landMark: form?.billing_landMark,
                    customer_companyName: form?.customer_companyName,
                    recipent_companyName: form?.recipent_companyName,
                    recipent_door_no: form?.recipent_door_no,
                    recipent_billing_area: form?.recipent_billing_area,
                    recipent_billing_street: form?.recipent_billing_street,
                    recipent_billing_landMark: form?.recipent_billing_landMark,
                    discountRate: this.discountRate
                }
            } else {
                this.invoiceData = {
                    use_different_shipping: 'yes',
                    customer_firstName: form?.customer_firstName,
                    customer_lastName: form?.customer_lastName,
                    customer_mobile: form?.customer_mobile,
                    customer_email: form?.customer_email,
                    // customer_address: form?.customer_address,
                    customer_countryID: form?.customer_countryID,
                    customer_city: form?.customer_city,
                    customer_state: form?.customer_state,
                    customer_zipCode: form?.customer_zipCode,
                    recipent_first_name: form?.recipent_first_name,
                    recipent_last_name: form?.recipent_last_name,
                    recipent_mobile: form?.recipent_mobile,
                    recipent_email: form?.recipent_email,
                    // recipent_address: form?.recipent_address,
                    recipent_countryID: form?.recipent_countryID,
                    recipent_city: form?.recipent_city,
                    recipent_state: form?.recipent_state,
                    recipent_zipcode: form?.recipent_zipcode,
                    cgst: Math.round(this.gstCal),
                    sgst: Math.round(this.gstCal),
                    igst: Math.round(this.igstCal),
                    discount: Math.round(this.disCal),
                    shippingAmount: Math.round(this.shippingAmount),
                    customer_type: this.types,
                    product: this.emptyArray,
                    gstType: this.gstType,
                    invoice_no: form?.invoice_no,
                    order_date: form?.order_date,
                    door_no: form?.door_no,
                    billing_area: form?.billing_area,
                    billing_street: form?.billing_street,
                    billing_landMark: form?.billing_landMark,
                    customer_companyName: form?.customer_companyName,
                    recipent_companyName: form?.recipent_companyName,
                    recipent_door_no: form?.recipent_door_no,
                    recipent_billing_area: form?.recipent_billing_area,
                    recipent_billing_street: form?.recipent_billing_street,
                    recipent_billing_landMark: form?.recipent_billing_landMark,
                    discountRate: this.discountRate
                }
            }
            console.log("this.invoiceData ", this.invoiceData);
            this.productService.createOrder(this.invoiceData).subscribe((data) => {
                if (data?.keyword === "success") {
                    Swal.fire({
                        title: 'Invoice Bill!',
                        text: `${data.message}`,
                        icon: 'success',
                        confirmButtonText: 'OK',
                        preConfirm: () => {
                            this.createCustomerFormGroup?.reset();
                            this.activeModal?.close();
                        }
                    });
                    this.generatePdfInvoice();

                    // this.generateSampleInvoice();
                    this.orderList();
                    // this.ordersComponent.orderService.getOrder();
                } else if (data?.keyword === "failed") {
                    Swal.fire({
                        title: 'Error!',
                        text: `${data.message || 'An unexpected error occurred. Please try again later.'}`,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    });
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Received an unexpected response from the server.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    });
                }
            });

        } else {
            Swal.fire({
                title: 'Required Field!',
                text: 'Please Check the Mandatory Fields',
                icon: 'warning',
                confirmButtonText: 'OK'
            });
        }
        this.createCustomerFormGroup.markAllAsTouched();
    }
}