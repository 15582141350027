import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import 'bootstrap-datepicker';
import { ProductService } from 'src/app/shared/service/product.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-activitylog',
  templateUrl: './activitylog.component.html',
  styleUrl: './activitylog.component.scss'
})
export class ActivitylogComponent implements OnInit, AfterViewInit {
  activityForm: FormGroup;
  dateFormate: any;
  selectedDate: any;
  lashFormattedDate: any;
  formattedDate: any;
  modules: any;
  lashFormattedToDate: any;
  listData: [];
  constructor(private fb: FormBuilder, private productService: ProductService) { }
  ngOnInit(): void {
    this.form();
    this.modulesName();
  }
  form() {
    this.activityForm = this.fb.group({
      from_date: new FormControl("", Validators.required),
      to_date: new FormControl("", Validators.required),
      module_name: new FormControl("", Validators.required)
    });

    this.activityForm.get('from_date')?.valueChanges.subscribe(() => {
      this.dateValidation();
    });

    this.activityForm.get('to_date')?.valueChanges.subscribe(() => {
      this.dateValidation();
    })
  }
  modulesName() {
    this.productService.moduleType().subscribe((data) => {
      this.modules = data.data;
    });
  }
  onSearch() {
    if (this.activityForm?.valid) {
      const form = this.activityForm?.value;
      const formValue = {
        'from_date': this.lashFormattedDate,
        'to_date': this.lashFormattedToDate,
        'module_name': form?.module_name
      }
      this.productService.activityList(formValue).subscribe({
        next: (data) => {
          if (data?.success) {
            this.listData = data.data;
            Swal.fire({
              title: 'Activity Log!',
              text: `${data.message}`,
              icon: 'success',
              confirmButtonText: 'OK',
            });
          }
        }, error: (error) => {
          const errorMessage = error.error?.message;
          Swal.fire({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'OK',
          })
        }
      }
      )
    } else {
      this.activityForm?.markAllAsTouched();
      this.activityForm?.updateValueAndValidity();
    }
  }
  dateValidation() {
    const formDate = this.activityForm.get('from_date')?.value;
    const toDate = this.activityForm.get('to_date').value;
    if (formDate && toDate) {
      const fromDateObj = new Date(formDate);
      const toDateObj = new Date(toDate);
      if (fromDateObj > toDateObj) {
        if (!this.activityForm.get('to_date')?.hasError('dateRangeInvalid')) {
          this.activityForm.get('to_date')?.setValidators([Validators.required, this.dateRangeValidation(fromDateObj)]);
          this.activityForm.get('to_date')?.updateValueAndValidity({ emitEvent: false });
        } 
      } else {
        this.activityForm.get('to_date')?.clearValidators();
        this.activityForm.get('to_date')?.setValidators([Validators.required]);
        this.activityForm.get('to_date')?.updateValueAndValidity({ emitEvent: false });
      }
    }
  }
  dateRangeValidation(fromDate: any) {
    return (control: any) => {
      const toDate = new Date(control.value);
      return fromDate && toDate && fromDate > toDate ? { 'dateRangeInvalid': true } : null;
    }
  }
  get controls() {
    return this.activityForm.controls;
  }
  ngAfterViewInit(): void {
    if ($.fn.datepicker) {
      const today = new Date();
      ($('#activity_date') as any).datepicker({
        format: 'yyyy/mm/dd',
        autoclose: true,
        todayHighlight: true,
      });
      ($('#activity_date') as any).datepicker('setDate', today);
      ($('#activity_date') as any).val('');
      this.dateFormate = new Intl.DateTimeFormat('en-US').format(today);
      ($('#activity_date') as any).on('changeDate', (event: any) => {
        this.selectedDate = event.date;
        const year = this.selectedDate.getFullYear();
        const month = (this.selectedDate.getMonth() + 1).toString().padStart(2, '0');
        const date = (this.selectedDate.getDate()).toString().padStart(2, '0');
        this.lashFormattedDate = `${year}/${month}/${date}`;
        this.activityForm.get('from_date').patchValue(this.lashFormattedDate);
        console.log("this.formattedDate ", this.lashFormattedDate);
      });
    } else {
      console.error('Bootstrap Datepicker is not available.');
    }

    if ($.fn.datepicker) {
      const today = new Date();
      ($('#to_date') as any).datepicker({
        format: 'yyyy/mm/dd',
        autoclose: true,
        todayHighlight: true
      });
      ($('#to_date') as any).datepicker('setDate', today);
      ($('#to_date') as any).val('');
      this.dateFormate = new Intl.DateTimeFormat('en-US').format(today);
      console.log("dateFormate ", this.dateFormate);
      ($('#to_date') as any).on('changeDate', (event: any) => {
        this.selectedDate = event.date;
        console.log("this.selectedDate ", this.selectedDate);

        const year = this.selectedDate.getFullYear();
        const month = (this.selectedDate.getMonth() + 1).toString().padStart(2, '0');
        const date = (this.selectedDate.getDate()).toString().padStart(2, '0');
        this.lashFormattedToDate = `${year}/${month}/${date}`;
        this.activityForm.get('to_date').patchValue(this.lashFormattedToDate);
      });
    } else {
      console.error('Bootstrap Datepicker is not available.');
    }
  }
}
